import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { wsApi } from "../services/WSservice";
import { store } from "..";
import { ChatMessages, ChatProfile, NewMessage } from "../../@types/chat";

type ChatState = {
  offset: number;
  profiles: ChatProfile[] | null;
  messages: ChatMessages[];
  isCanLoadOldMessages: boolean;
  isWaitingOldMesssages: boolean;
  isUnreadMessages: boolean;
};

const initialState: ChatState = {
  offset: 0,
  profiles: null,
  messages: [],
  isCanLoadOldMessages: true,
  isWaitingOldMesssages: false,
  isUnreadMessages: false,
};

const slice = createSlice({
  name: "chat",
  initialState: initialState,
  reducers: {
    updateProfiles: (state, { payload }: PayloadAction<ChatProfile[]>) => {
      state.profiles = payload;
    },
    loadChatMessages: (state, { payload }: PayloadAction<ChatMessages[]>) => {
      state.messages = payload;
      state.offset = state.messages.length;

      state.isCanLoadOldMessages = true;
    },
    loadNewMessage: (state, { payload }: PayloadAction<NewMessage>) => {
      state.messages?.push({
        ...payload.message,
        gameProfile: payload.profile,
      });
      state.offset = state.messages.length;
    },
    loadOldMessages: (state, { payload }: PayloadAction<ChatMessages[]>) => {
      state.messages = [...payload, ...state.messages];
      state.offset = state.messages.length;

      if (payload?.length === 0) {
        state.isCanLoadOldMessages = false;
      }
    },
    updateIsUnreadMessages: (state, { payload }: PayloadAction<boolean>) => {
      state.isUnreadMessages = payload;
    },
  },
});

export const {
  updateProfiles,
  loadChatMessages,
  loadNewMessage,
  loadOldMessages,
  updateIsUnreadMessages,
} = slice.actions;

export default slice.reducer;
