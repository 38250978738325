import React, { useEffect, useRef } from "react";

import "./Modal.css";
import Button from "../../components/Button";
import closeModal from "../../assets/images/svg/closeModal.svg";

type Props = {
  modalStyle?: any;
  children: any;
  showModal: any;
  onClose: any;
  backdropStyle?: any;
};

const Modal = ({
  modalStyle,
  children,
  showModal,
  onClose,
  backdropStyle,
}: Props) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (showModal) {
      modalRef?.current?.classList.add("visible");
    } else {
      modalRef?.current?.classList.remove("visible");
    }
  }, [showModal]);

  return (
    <>
      <div
        ref={modalRef}
        style={backdropStyle}
        className="modal-wrap"
        onClick={(e) => e.currentTarget === e.target && onClose()}
      >
        <div style={modalStyle} className="modal">
          {children}
          <Button
            text=""
            onClick={onClose}
            style={{
              width: 22,
              height: 22,
              position: "absolute",
              top: 0,
              right: 0,
              margin: 30,
              background: "transparent",
            }}
            className="close-btn"
          >
            <img src={closeModal} alt="close" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Modal;
