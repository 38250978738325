import React from "react";
import { useAppSelector } from "../../store";

import "./ModalNotification.css";

type Props = {};

const ModalNotification = (props: Props) => {
  const notification = useAppSelector((state) => state.modal.notification);

  return <div className="notification">{notification}</div>;
};

export default ModalNotification;
