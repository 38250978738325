import React, { useCallback, useEffect, useState } from "react";
import Modal from "../Modal";
import waterCellImage from "../../assets/images/waterCell.png";
import earthCellImage from "../../assets/images/earthcCell.png";
import starpom from "../../assets/images/job-1.png";
import starpomLarge from "../../assets/images/jobLarge-1.png";
import kvartimeister from "../../assets/images/job-2.png";
import vrach from "../../assets/images/job-3.png";
import bocman from "../../assets/images/job-4.png";
import plotnik from "../../assets/images/job-5.png";
import masterParusov from "../../assets/images/job-6.png";
import kok from "../../assets/images/job-7.png";
import klerk from "../../assets/images/job-8.png";
import { ModalStatIsland, ModalStatSea } from "../ModalStatContent";
import ModalCardContent from "../ModalCardContent";
import ModalFragmentsContent from "../ModalFragmentsContent";
import ModalTeamContent from "../ModalTeamContent";
import ModalInterviewContent from "../ModalInterviewContent";
import ModalRatingsContent from "../ModalRatingsContent";
import ModalChat from "../ModalChat";
import { useAppDispatch, useAppSelector } from "../../store";
import { closeInitialModal } from "../../store/slices/modal/modalSlice";
import ModalEvent from "../ModalEvent";
import ModalProfile from "../ModalProfile";
import ModalNotification from "../ModalNotification";
import Congratulations from "../Congratulations";
import ModalArtefacts from "../ModalArtefacts";

type Props = {};

const ModalContent = ({}: Props) => {
  const dispatch = useAppDispatch();
  const { showModal, initialModal } = useAppSelector((state) => state.modal);
  const [showContent, setShowContent] = useState(false);

  const closeModal = () => {
    dispatch(closeInitialModal());
  };

  useEffect(() => {
    if (showModal) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  }, [showModal]);

  const renderModalData = useCallback((type: string, data?: any) => {
    switch (type) {
      case "moves":
        return <ModalStatSea />;
      case "positions":
        return <ModalCardContent />;
      case "islands":
        return (
          <ModalStatIsland />
          // <ModalStatContent
          //   title="Статистика открытым островам"
          //   data={statisticsIsland}
          // />
        );
      case "fragments":
        return <ModalFragmentsContent title="Найдено фрагментов:" />;
      case "congratulations":
        return <Congratulations />;
      case "rating":
        return <ModalRatingsContent title="Рейтинги" defaultTab={"tab2"} />;
      case "team":
        return <ModalTeamContent />;
      case "interview":
        return <ModalInterviewContent />;
      case "ratings":
        return <ModalRatingsContent title="Рейтинги" />;
      case "chat":
        return <ModalChat title="КОМАНДНЫЙ ЧАТ" />;
      case "event":
        return <ModalEvent />;
      case "profile":
        return <ModalProfile title="Профиль игрока" />;
      case "artefacts":
        return <ModalArtefacts />;
      default:
        return null;
    }
  }, []);

  return (
    <Modal showModal={showModal} onClose={closeModal}>
      {showContent && renderModalData(initialModal)}
    </Modal>
  );
};

export default ModalContent;
