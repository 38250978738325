import Button from "../../components/Button";
import "./Start.css";
import ship from "../../assets/images/ship.jpeg";
import logo from "../../assets/images/svg/logo.svg";
import headerLogo from "../../assets/images/UBestCity.png";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {selectCurrentUser, signOut} from "../../store/slices/modal/authSlice";
import ModalContent from "../../components/ModalContent";
import ModalSelect from "../../components/ModalSelect";
import {setInitialModal} from "../../store/slices/modal/modalSlice";
import {useAppSelector} from "../../store";
import {
  useCollectedFragmentQuery,
  useGameProfileQuery,
  useHeaderStatisticQuery,
} from "../../store/services/teams";
import {useEffect} from "react";
import {useLogoutMutation} from "../../store/services/auth";

type Props = {};

const Start = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectCurrentUser);
  const [logout] = useLogoutMutation();
  const {data, isFetching, isError, error, refetch} = useHeaderStatisticQuery(
    {
      iGameId: Number(user?.iGameId),
      iTeamId: Number(user?.iTeamId),
    }
  );

  // const { isLoading, isError, error, refetch } = useGameProfileQuery({
  //   iGameId: Number(user?.iGameId),
  // });

  const handleGameIn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate("/game/pirate");
  };

  const handleLogout = async () => {
     dispatch(signOut());
     await logout("");
    window.location.replace("/login");
    console.log(123);
  };

  const handleOpenModal = (value: string) => {
    dispatch(setInitialModal(value));
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className="container-start">
        <main className="wrapper-start">
          <div className="start">
            {/* меню с заголовком */}
            <div className="start-menu">
              {/*<img src={headerLogo} alt="logo" className="start-menu__logo"/>*/}
              <ul className="start-menu__list-container">
                <li className="start-menu__list">
                  <Button
                    onClick={handleGameIn}
                    text="Войти в игру"
                    className="button_red-medium"
                  />
                </li>
                <li className="start-menu__list">
                  <Button
                    text="профиль"
                    onClick={() => handleOpenModal("profile")}
                  />
                </li>
                <li className="start-menu__list">
                  <Button
                    text="рейтинги"
                    onClick={() => handleOpenModal("ratings")}
                  />
                </li>
                 <li className="start-menu__list">
                  <Button text="инструкция" />
                </li>
                <li className="start-menu__list">
                  <Button onClick={handleLogout} text="выйти из игры"/>
                </li>
              </ul>
            </div>
            {/* информация о текущей игре */}
            <div className="game-info">
              <div className="game-info__container">
                <img
                  src={user?.team.sImage || ship}
                  alt="корабль команды"
                  className="game-info__img "
                />
                {/* <div className="game-info__steps">
                  <p className="game-info__steps-title">5</p>
                  <p className="game-info__steps-text">новых шагов доступно</p>
                </div> */}
                <h2 className="game-info__title">
                  Команда "{user?.team.sName || ""}"
                </h2>
                <p className="game-info__locations">
                  Открыто{" "}
                  <span className="game-info__locations_opened">
                  {data?.iIslandCount || 0}
                </span>{" "}
                  из {data?.iTotalIslandCount || 0} островов
                </p>
                <div className="game-info__button_container">
                  <button
                    onClick={() => handleOpenModal("team")}
                    className="game-info__team"
                  >
                    Просмотреть список команды
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer className="footer">
          <img src={logo} alt="логотип" className="footer__logo"/>
        </footer>
      </div>
      <ModalContent/>
      <ModalSelect/>
    </>
  );
};

export default Start;
