import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MapRow, MapRowValue, MapRowWithFragment } from "../../@types/map";
import AdminColumn from "../../components/AdminColumn";
import AdminDraggableMap from "../../components/AdminDraggableMap";
import AdminError from "../../components/AdminError";
import AdminRow from "../../components/AdminRow";
import Loader from "../../components/Loader";
import MapCell from "../../components/MapCell";
import MapEvent from "../../components/MapEvent";
import {
  adminApi,
  useGetEventsQuery,
  useGetIslandCellQuery,
} from "../../store/services/admin";
import seaOpen from "../../assets/images/seaOpen.png";
import { useAppDispatch, useAppSelector } from "../../store";
// import AdminMapMenu from "../AdminMap/AdminMapMenu";
import { updateActiveCellData } from "../../store/slices/adminSlice";
import AdminMapMenu from "../../components/AdminMapMenu";
import Button from "../../components/Button";

const Column = ({ columnElement }: { columnElement: MapRowWithFragment }) => {
  const { id, islandId } = useParams();
  const dispatch = useAppDispatch();
  const activeCell = useAppSelector((state) => state.admin.activeCellData);
  // const isChangeCellObject = useAppSelector(
  //   (state) => state.admin.isChangeCellObject
  // );
  const backgroundImage = adminApi.endpoints.getIslandCell.useQueryState({
    iGameId: Number(id),
    iIslandId: Number(islandId),
  }).data?.data?.model?.sImageMap?.sImage;

  // const [changeCellEvent, { isLoading, error }] = useChangeCellMutation();
  // const [
  //   changeIslandCellEvent,
  //   { isLoading: isChangeIslandLoading, error: changeIslandError },
  // ] = useChangeIslandCellMutation();

  const handleCell = async () => {
    const { id: cellId, iIslandId, iCellType, iEventId } = columnElement;
    //   if (isChangeCellObject && activeCell) {
    //     if (iCellType === "3") return;
    //     try {
    //       // Записываем в старую ячейку информацию из новой ячейки
    //       // if (Number(iIslandId) && iCellType === "3") {
    //       //   await changeIslandCellEvent({
    //       //     iGameId: Number(id),
    //       //     iIslandId: Number(iIslandId),
    //       //     iCellType: Number(iCellType),
    //       //     iCellId: activeCell.iCellId,
    //       //   });
    //       // } else {
    //       //   await changeCellEvent({
    //       //     iGameId: Number(id),
    //       //     iIslandId: Number(iIslandId),
    //       //     iCellType: Number(iCellType),
    //       //     iCellId: activeCell.iCellId,
    //       //     iEffectId: Number(iEventId),
    //       //   });
    //       // }
    //       // Записываем в новую ячейку информацию из старой ячейки
    //       if (activeCell.iIslandId && activeCell.iCellType === 3) {
    //         await changeIslandCellEvent({
    //           iGameId: Number(id),
    //           iIslandId: activeCell?.iIslandId,
    //           iCellType: activeCell?.iCellType,
    //           iCellId: Number(cellId),
    //         });
    //       } else {
    //         await changeCellEvent({
    //           iGameId: Number(id),
    //           iIslandId: activeCell?.iIslandId,
    //           iCellType: activeCell?.iCellType,
    //           iCellId: Number(cellId),
    //           iEffectId: Number(activeCell.iEventId),
    //         });
    //       }
    //       dispatch(
    //         updateActiveCellData({
    //           iIslandId: activeCell?.iIslandId,
    //           iCellId: Number(cellId),
    //           iCellType: activeCell?.iCellType,
    //           iEventId: Number(activeCell.iEventId),
    //         })
    //       );
    //       dispatch(updateCellChangeStatus(false));
    //     } catch (error) {
    //       console.log("error when updating cell event: ", error);
    //     }
    //     return;
    //   }
    if (activeCell?.iCellId === Number(cellId)) {
      dispatch(updateActiveCellData(null));
      return;
    }
    dispatch(
      updateActiveCellData({
        iIslandId: Number(iIslandId),
        iCellId: Number(cellId),
        iCellType: Number(iCellType),
        iEventId: typeof iEventId === "string" ? Number(iEventId) : iEventId,
      })
    );
  };

  return (
    <AdminColumn sTitle={columnElement.sTitle}>
      <MapEvent element={columnElement} />
      <MapCell
        isActive={activeCell?.iCellId === Number(columnElement.id)}
        image={backgroundImage || seaOpen}
        onClick={handleCell}
      />
      {/* {(isLoading || isChangeIslandLoading) && (
        <Loader isAbsolute width={"50%"} height={"50%"} />
      )} */}
    </AdminColumn>
  );
};

const Row = ({
  mapRowElements,
  rowIndex,
}: {
  mapRowElements: MapRow<MapRowWithFragment>;
  rowIndex: number;
}) => {
  return (
    <AdminRow rowIndex={rowIndex}>
      {Object.values(mapRowElements).map((columnElement, index) => {
        return <Column key={columnElement.id} columnElement={columnElement} />;
      })}
    </AdminRow>
  );
};

type Props = {};

const AdminMapIsland = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, islandId } = useParams();

  const { data, isLoading, error, isError } = useGetIslandCellQuery({
    iGameId: Number(id),
    iIslandId: Number(islandId),
  });

  useGetEventsQuery({
    iGameId: Number(id),
  });

  useEffect(() => {
    return () => {
      dispatch(updateActiveCellData(null));
    };
  }, []);

  if (isLoading) {
    return (
      <div className="admin-games-loader">
        <Loader width="20%" height="20%" />
      </div>
    );
  }

  if (error) {
    return <AdminError error={error} isError={isError} />;
  }

  return (
    <>
      <AdminMapMenu type="island" />
      <AdminDraggableMap>
        {!!data?.data?.model?.aCells &&
          Object.values(data?.data?.model?.aCells).map(
            (mapRowElements, index) => {
              return (
                <Row
                  key={index}
                  mapRowElements={mapRowElements}
                  rowIndex={index}
                />
              );
            }
          )}
      </AdminDraggableMap>
      <div style={{ position: "absolute", top: 100, right: 20 }}>
        <Button
          onClick={() => navigate(`/map/${id}`)}
          style={{
            width: 100,
            height: 40,
            fontSize: 14,
            fontFamily: "inherit",
          }}
          text="вернуться"
        />
      </div>
    </>
  );
};

export default AdminMapIsland;
