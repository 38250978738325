import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useTeamPositionsQuery } from "../../store/services/teams";
import { useRemoveFromPositionMutation } from "../../store/services/teams";
import { closeSelectModal } from "../../store/slices/modal/modalSlice";
import Button from "../Button";
import Loader from "../Loader";
import Error from "../Error";
import { TeamPositionProfile } from "../../@types/teams";
import { selectCurrentUser } from "../../store/slices/modal/authSlice";
import { DropdownUser } from "../Dropdown";

type Props = {
  type?: "appoint" | "takeOff" | "delete";
};

const RemoveFromPosition = ({ type }: Props) => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectCurrentUser);

  const [removeFromPosition, removeFromPositionState] =
    useRemoveFromPositionMutation();
  // const { data, isFetching, refetch, isError, error } = useAllQuery({
  //   iGameId: Number(userProfile?.iGameId),
  // });

  const { data, isFetching, isError, error, refetch } = useTeamPositionsQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  });
  const [profiles, setProfiles] = useState<TeamPositionProfile[]>([]);
  const [selectedOption, setSelectedOption] =
    useState<TeamPositionProfile | null>(null);

  const onSaveHandler = () => {
    if (!selectedOption) return;
    if (type === "takeOff") {
      removeFromPosition({
        iGameId: Number(userProfile?.iGameId),
        iGameProfileId: Number(selectedOption?.id),
      });
    }
  };

  const handleSelectedOption = (value: TeamPositionProfile) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    if (data) {
      const newProfiles = data.data.model.reduce(
        (prev: TeamPositionProfile[], curr) => {
          return [...prev, ...curr.profiles];
        },
        []
      );

      setProfiles(newProfiles);
    }
  }, [data]);

  useEffect(() => {
    if (profiles?.length) {
      setSelectedOption(profiles[0]);
    } else {
      setSelectedOption(null);
    }
  }, [profiles]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (removeFromPositionState.isSuccess) {
      dispatch(closeSelectModal());
    }
  }, [removeFromPositionState.isSuccess]);

  if (isFetching || removeFromPositionState.isLoading) {
    return <Loader />;
  }

  if (isError && error) {
    return <Error error={error} isError={isError} />;
  }

  if (removeFromPositionState.isError && removeFromPositionState.error) {
    return (
      <Error
        error={removeFromPositionState.error}
        isError={removeFromPositionState.isError}
      />
    );
  }

  return (
    <>
      {!!data ? (
        <>
          <DropdownUser
            data={profiles}
            selectedOption={selectedOption}
            handleSelectedOption={handleSelectedOption}
          />
          <Button
            disabled={!selectedOption}
            text="сохранить"
            className="bottom-menu__button_normal"
            onClick={onSaveHandler}
          />
        </>
      ) : (
        <div>Не удалось загрузить данные</div>
      )}
    </>
  );
};

export default RemoveFromPosition;
