import React, { useEffect, useState } from "react";
import { useAppSelector } from "../store";
import { selectCurrentUser } from "../store/slices/modal/authSlice";

const useIsCaptain = () => {
  const userProfile = useAppSelector(selectCurrentUser);
  const [isCaptain, setIsCaptain] = useState(false);

  useEffect(() => {
    if (userProfile?.sPosition === "Капитан") {
      setIsCaptain(true);
    } else {
      setIsCaptain(false);
    }
  }, [userProfile]);

  return {
    isCaptain,
  };
};

export default useIsCaptain;
