import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type AdminState = {
  activeCellData: null | {
    iIslandId: number;
    iCellId: number;
    iCellType: number;
    iEventId: number | null;
  };
  isChangeCellObject: boolean;
};

const initialState: AdminState = {
  activeCellData: null,
  isChangeCellObject: false,
};

const slice = createSlice({
  name: "map",
  initialState: initialState,
  reducers: {
    updateActiveCellData: (
      state,
      { payload }: PayloadAction<AdminState["activeCellData"]>
    ) => {
      state.activeCellData = payload;
    },
    updateCellChangeStatus: (
      state,
      { payload }: PayloadAction<AdminState["isChangeCellObject"]>
    ) => {
      state.isChangeCellObject = payload;
    },
  },
});

export const { updateActiveCellData, updateCellChangeStatus } = slice.actions;

export default slice.reducer;
