import React from "react";

import "../Modal/Modal.css";
import "./ModalRatingsContent.css";
import Tabs from "../Tabs";

type Props = {
  title: string;
  defaultTab?: string;
};

const ModalRatingsContent = ({ title, defaultTab }: Props) => {
  return (
    <>
      <h3 className="modal-title">{title}</h3>
      <Tabs defaultTab={defaultTab} />
    </>
  );
};

export default ModalRatingsContent;
