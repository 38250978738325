import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/svg/logo.svg";
import { useAppDispatch } from "../../store";
import { signOut } from "../../store/slices/modal/authSlice";

import "./adminHeader.css";

type Props = {};

const AdminHeader = (props: Props) => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(signOut());
  };

  return (
    <header className="admin-header">
      <div className="admin-header__container">
        <Link className="admin-header__button" to="/games">
          <img className="admin-header__logo" src={logo} alt="Логотип" />
        </Link>

        <Link className="admin-header__button" to="/games">
          Список Игр
        </Link>
        <button onClick={handleLogout} className="admin-header__logout">
          Выход
        </button>
      </div>
    </header>
  );
};

export default AdminHeader;
