import React from "react";

import './ProgressBar.css';

type Props = {
  progress: string;
};

const ProgressBar = ({progress}: Props) => {
  return (
    <div className="progress-line">
        <div style={{width: `${progress}%`}} className="progress-bar" />
    </div>

  )
}

export default ProgressBar;
