import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../";
import sea from "../../helpers/seaTestInfo";
import {
  GameMapAllCellsResponseSuccess,
  MapEvent,
  MapEventWithHelp,
  MapGameIslandCellsResponseSuccess,
  MapRow,
  MapRowGameValue,
} from "../../@types/map";
import { NotificationType } from "../../@types/teams";
// import WSservice from "../services/WSservice";

type mapState = {
  mapType: "land" | "sea";
  sea: GameMapAllCellsResponseSuccess | undefined;
  land: MapGameIslandCellsResponseSuccess | undefined;
  shipCoordinates?: { x: number; y: number };
  activeIslandId?: number;
  activeIslandCell: MapRowGameValue | null;
  activeLandImageLink: string | null;
  lastEvent: MapEventWithHelp | null;
  globalError: string | null;
  // WSservice: WSservice | null;
  quizState: boolean;
  unreadNotifications: NotificationType[];
  notifications: MapEventWithHelp[];
};

const initialState: mapState = {
  mapType: "sea",
  sea: undefined,
  land: undefined,
  shipCoordinates: undefined,
  activeIslandId: undefined,
  activeIslandCell: null,
  activeLandImageLink: null,
  lastEvent: null,
  globalError: null,
  quizState: false,
  unreadNotifications: [],
  notifications: [],
  // WSservice: null,
};

const slice = createSlice({
  name: "map",
  initialState: initialState,
  reducers: {
    toggleMapType: (state, { payload }: PayloadAction<undefined>) => {
      state.mapType = state.mapType === "sea" ? "land" : "sea";
    },
    updateSeaInfo: (state, { payload }: PayloadAction<mapState["sea"]>) => {
      state.sea = payload;

      state.mapType = "sea";
    },
    updateLandInfo: (state, { payload }: PayloadAction<mapState["land"]>) => {
      state.land = payload;
    },
    updateShipCoordinates: (
      state,
      { payload }: PayloadAction<typeof initialState.shipCoordinates>
    ) => {
      state.shipCoordinates = payload;
    },
    updateActiveIslandId: (
      state,
      { payload }: PayloadAction<typeof initialState["activeIslandId"]>
    ) => {
      state.activeIslandId = payload;
    },
    updateActiveIslandCell: (
      state,
      { payload }: PayloadAction<typeof initialState["activeIslandCell"]>
    ) => {
      state.activeIslandCell = payload;
    },
    updateLastEvent: (
      state,
      { payload }: PayloadAction<typeof initialState["lastEvent"]>
    ) => {
      state.lastEvent = payload;
    },
    updateGlobalError: (
      state,
      { payload }: PayloadAction<typeof initialState["globalError"]>
    ) => {
      state.globalError = payload;
    },
    updateActiveLandImageLink: (
      state,
      { payload }: PayloadAction<typeof initialState["activeLandImageLink"]>
    ) => {
      state.activeLandImageLink = payload;
    },
    updateQuizState: (
      state,
      { payload }: PayloadAction<typeof initialState["quizState"]>
    ) => {
      state.quizState = payload;
    },
    addUnreadNotifications: (
      state,
      { payload }: PayloadAction<typeof initialState["unreadNotifications"]>
    ) => {
      // state.unreadNotifications = [...state.unreadNotifications, ...payload];
      state.unreadNotifications = payload;
    },
    removeFirstUnreadNotifications: (
      state,
      { payload }: PayloadAction<undefined>
    ) => {
      // const [firstElement, ...elements] = state.unreadNotifications;

      state.unreadNotifications.shift();
    },
    addNotification: (
      state,
      { payload }: PayloadAction<typeof initialState["notifications"][number]>
    ) => {
      state.notifications.push(payload);
    },
    removeFirstNotifications: (
      state,
      { payload }: PayloadAction<undefined>
    ) => {
      state.notifications.shift();
    },
  },
});

export const {
  toggleMapType,
  updateSeaInfo,
  updateLandInfo,
  updateShipCoordinates,
  updateActiveIslandId,
  updateActiveIslandCell,
  updateActiveLandImageLink,
  updateLastEvent,
  updateGlobalError,
  updateQuizState,
  addUnreadNotifications,
  removeFirstUnreadNotifications,
  addNotification,
  removeFirstNotifications,
} = slice.actions;

export default slice.reducer;
