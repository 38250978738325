import React, { useEffect, useRef, useState } from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import MapCell from "../../components/MapCell";
import seaOpen from "../../assets/images/seaOpen.png";
import { MapRow, MapRowMainValue, MapRowValue } from "../../@types/map";
import { useLocation, useParams } from "react-router-dom";
import {
  adminApi,
  useAllCellsQuery,
  useChangeCellMutation,
  useChangeIslandCellMutation,
  useGetEventsQuery,
} from "../../store/services/admin";
import { ResponseError } from "../../@types/api";
import AdminError from "../../components/AdminError";
import Loader from "../../components/Loader";
import island1 from "../../assets/images/decoration/island1.svg";
import MapEvent from "../../components/MapEvent";
// import AdminMapMenu from "./AdminMapMenu";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  updateActiveCellData,
  updateCellChangeStatus,
} from "../../store/slices/adminSlice";
import AdminDraggableMap from "../../components/AdminDraggableMap";
import AdminRow from "../../components/AdminRow";
import AdminColumn from "../../components/AdminColumn";
import AdminMapMenu from "../../components/AdminMapMenu";

const Column = ({ columnElement }: { columnElement: MapRowMainValue }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const activeCell = useAppSelector((state) => state.admin.activeCellData);
  const isChangeCellObject = useAppSelector(
    (state) => state.admin.isChangeCellObject
  );
  const backgroundImage = adminApi.endpoints.allCells.useQueryState({
    iGameId: Number(id),
  }).data?.data?.model?.sImageMap?.sImage;

  const [changeCellEvent, { isLoading, error }] = useChangeCellMutation();
  const [
    changeIslandCellEvent,
    { isLoading: isChangeIslandLoading, error: changeIslandError },
  ] = useChangeIslandCellMutation();

  const handleCell = async () => {
    const { id: cellId, iIslandId, iCellType, iEventId } = columnElement;

    if (isChangeCellObject && activeCell) {
      if (iCellType === "3") return;
      try {
        // Записываем в старую ячейку информацию из новой ячейки
        // if (Number(iIslandId) && iCellType === "3") {
        //   await changeIslandCellEvent({
        //     iGameId: Number(id),
        //     iIslandId: Number(iIslandId),
        //     iCellType: Number(iCellType),
        //     iCellId: activeCell.iCellId,
        //   });
        // } else {
        //   await changeCellEvent({
        //     iGameId: Number(id),
        //     iIslandId: Number(iIslandId),
        //     iCellType: Number(iCellType),
        //     iCellId: activeCell.iCellId,
        //     iEffectId: Number(iEventId),
        //   });
        // }

        // Записываем в новую ячейку информацию из старой ячейки
        if (activeCell.iIslandId && activeCell.iCellType === 3) {
          await changeIslandCellEvent({
            iGameId: Number(id),
            iIslandId: activeCell?.iIslandId,
            iCellType: activeCell?.iCellType,
            iCellId: Number(cellId),
          });
        } else {
          await changeCellEvent({
            type: "sea",
            data: {
              iGameId: Number(id),
              iIslandId: activeCell?.iIslandId,
              iCellType: activeCell?.iCellType,
              iCellId: Number(cellId),
              iEffectId: Number(activeCell.iEventId),
            },
          });
        }

        dispatch(
          updateActiveCellData({
            iIslandId: activeCell?.iIslandId,
            iCellId: Number(cellId),
            iCellType: activeCell?.iCellType,
            iEventId: Number(activeCell.iEventId),
          })
        );
        dispatch(updateCellChangeStatus(false));
      } catch (error) {
        console.log("error when updating cell event: ", error);
      }
      return;
    }

    if (activeCell?.iCellId === Number(cellId)) {
      dispatch(updateActiveCellData(null));
      return;
    }

    dispatch(
      updateActiveCellData({
        iIslandId: Number(iIslandId),
        iCellId: Number(cellId),
        iCellType: Number(iCellType),
        iEventId: typeof iEventId === "string" ? Number(iEventId) : iEventId,
      })
    );
  };

  return (
    <AdminColumn sTitle={columnElement.sTitle}>
      <MapEvent element={columnElement} />
      <MapCell
        isActive={activeCell?.iCellId === Number(columnElement.id)}
        image={backgroundImage || seaOpen}
        onClick={handleCell}
      />
      {(isLoading || isChangeIslandLoading) && (
        <Loader isAbsolute width={"50%"} height={"50%"} />
      )}
    </AdminColumn>
  );
};

const Row = ({
  mapRowElements,
  rowIndex,
}: {
  mapRowElements: MapRow;
  rowIndex: number;
}) => {
  return (
    <AdminRow rowIndex={rowIndex}>
      {Object.values(mapRowElements).map((columnElement, index) => {
        return <Column key={columnElement.id} columnElement={columnElement} />;
      })}
    </AdminRow>
  );
};

const AdminMap = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { data, isLoading, error, isError, refetch } = useAllCellsQuery({
    iGameId: Number(id),
  });

  useGetEventsQuery({
    iGameId: Number(id),
  });

  useEffect(() => {
    refetch();

    return () => {
      dispatch(updateActiveCellData(null));
      dispatch(updateCellChangeStatus(false));
    };
  }, []);

  if (isLoading) {
    return (
      <div className="admin-games-loader">
        <Loader width="20%" height="20%" />
      </div>
    );
  }

  if (error) {
    return <AdminError error={error} isError={isError} />;
  }

  return (
    <>
      <AdminMapMenu />
      <AdminDraggableMap>
        {!!data?.data?.model?.aCells &&
          Object.values(data?.data?.model?.aCells).map(
            (mapRowElements, index) => {
              return (
                <Row
                  key={index}
                  mapRowElements={mapRowElements}
                  rowIndex={index}
                />
              );
            }
          )}
      </AdminDraggableMap>
    </>
  );
};

export default AdminMap;
