import React, { useEffect, useState } from "react";

import "./Tabs.css";
import RatingUserContent from "../RatingUserContent";
import RatingCrewContent from "../RatingCrewContent";
import { useAppSelector } from "../../store";
import {
  useIndividualRatingQuery,
  useTeamRatingQuery,
} from "../../store/services/teams";
import Loader from "../Loader";

const TeamRatingContent = () => {
  const userProfile = useAppSelector((state) => state.auth.user);

  const { data, isLoading, isError, error, refetch, isFetching } =
    useTeamRatingQuery({
      iGameId: Number(userProfile?.iGameId),
      iTeamId: Number(userProfile?.iTeamId),
    });

  useEffect(() => {
    refetch();
  }, []);

  if (isFetching) {
    return (
      <tr>
        <td>
          <Loader />
        </td>
      </tr>
    );
  }

  if (isError) {
    return <tr>Возникла ошибка при загрузке</tr>;
  }

  return (
    <>
      {data?.data.model.map((item, index) => {
        return <RatingCrewContent key={item.id} data={item} index={index} />;
      })}
    </>
  );
};

const InvididualRatingContent = () => {
  const userProfile = useAppSelector((state) => state.auth.user);

  const { data, isLoading, isError, error, refetch, isFetching } =
    useIndividualRatingQuery({
      iGameId: Number(userProfile?.iGameId),
      iTeamId: Number(userProfile?.iTeamId),
    });

  useEffect(() => {
    refetch();
  }, []);

  if (isFetching) {
    return (
      <tr>
        <td>
          <Loader />
        </td>
      </tr>
    );
  }

  if (isError) {
    return <tr>Возникла ошибка при загрузке</tr>;
  }

  return (
    <>
      {data?.data.model.map((item, index) => {
        return <RatingUserContent key={item.id} data={item} />;
      })}
    </>
  );
};

type TabNavType = {
  isActive: boolean;
  handleClick(): void;
  title: string;
};

const TabNavItem = ({ isActive, handleClick, title }: TabNavType) => {
  return (
    <li onClick={handleClick} className={isActive ? "active" : ""}>
      {title}
    </li>
  );
};

type TabContentType = {
  children: React.ReactNode;
};

const TabContent = ({ children }: TabContentType) => {
  return <div className="tab-content">{children}</div>;
};

const Tabs = ({ defaultTab = "tab1" }: { defaultTab?: string }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const renderTab = () => {
    switch (activeTab) {
      case "tab1":
        return <InvididualRatingContent />;
      // return usersData.map((item: UserItemType) => {
      //   return <RatingUserContent key={item.id} data={item} />;
      // });

      case "tab2":
        return <TeamRatingContent />;

      default:
        break;
    }
  };

  return (
    <div className="tabs">
      <ul className="nav">
        <TabNavItem
          title="Индивидуальный"
          isActive={activeTab === "tab1"}
          handleClick={() => setActiveTab("tab1")}
        />
        <TabNavItem
          title="Командный"
          isActive={activeTab === "tab2"}
          handleClick={() => setActiveTab("tab2")}
        />
      </ul>

      <div className="outlet">
        <TabContent>
          <table className="user-list">
            <tbody>{renderTab()}</tbody>
          </table>
        </TabContent>
      </div>
    </div>
  );
};

export default Tabs;
