//@ts-nocheck
import React, { useEffect } from "react";
import { TeamPosition } from "../../@types/teams";
import { useAppSelector } from "../../store";
import { usePositionInfoQuery } from "../../store/services/teams";
import Loader from "../Loader";
import Error from "../Error";

import "../Modal/Modal.css";
import "../ModalCardContent/ModalCardContent.css";
import "./ModalCardItem.css";
import stringToColour from "../../helpers/stringToColour";

const ModalCardItem = ({ data }: { data: TeamPosition | null }) => {
  const userProfile = useAppSelector((state) => state.auth.user);

  const {
    data: position,
    isFetching,
    isError,
    error,
    refetch,
  } = usePositionInfoQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
    iPositionId: data?.id || 0,
  });

  useEffect(() => {
    refetch();
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (isError && error) {
    return <Error error={error} isError={isError} />;
  }

  return (
    <div className="modal-card">
      <h3 className="modal-title">{position?.data.model.sTitle || ""}</h3>
      <div className="card-item">
        <div
          className="card"
          style={{ width: "42%", textAlign: "center", padding: 30 }}
        >
          <img
            className="card-image"
            src={position?.data.model.sImage || undefined}
            alt={position?.data.model.sTitle || ""}
          />
          {/*<p className="card-job" style={{ fontSize: 36, lineHeight: "45px" }}>
            {position?.data.model.sTitle || ""}
          </p>*/}
        </div>
        <div className="card-item__content">
          <div className="card-item__block">
            <h4 className="card-item__title">Описание</h4>
            <p className="card-item__descr">
              {position?.data.model.sDescription || ""}
            </p>
          </div>
          <div className="card-item__block">
            <h4 className="card-item__title">Преимущества</h4>
            <ul className="card-item__list">
              <li className="card-item__list-item">
                {position?.data.model.sAdvantage || ""}
              </li>
            </ul>
          </div>
          {!!position?.data.model.profiles.length && (
            <div className="card-item__block">
              <h4 className="card-item__title">Назначен на должность</h4>
              <div className="card-name__block">
                {position?.data.model.profiles.map((profile) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: 4,
                      }}
                    >
                      <span
                        className="card-name__icon"
                        style={{
                          background: stringToColour(
                            `${profile?.sFirstName || ""} ${
                              profile?.sLastName || ""
                            }`
                          ),
                        }}
                      >
                        {profile?.sFirstName[0] || ""}
                      </span>
                      <p
                        className="card-name"
                        style={{ width: "100%", fontSize: 18 }}
                      >
                        {profile?.sFirstName || ""} {profile?.sLastName || ""}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalCardItem;
