import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import Loader from "../../components/Loader";
import {
  useAllQuery,
  useMapRandomGenerationMutation,
} from "../../store/services/admin";

import "./adminGames.css";
import { useAppDispatch } from "../../store";
import { Link } from "react-router-dom";
import { ResponseError } from "../../@types/api";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import AdminError from "../../components/AdminError";

type TableErrorProps = {
  error: FetchBaseQueryError | SerializedError | undefined;
  isError: boolean;
};

const TableError = ({ error, isError }: TableErrorProps) => {
  if (isError && error && "status" in error) {
    return (
      <tr style={{ backgroundColor: "#f7dfdf" }}>
        <td colSpan={12} style={{ color: "#c74949", textAlign: "center" }}>
          {(error?.data as ResponseError).errors || "Произошла ошибка"}
        </td>
      </tr>
    );
  }

  return null;
};

type Game = {
  id: number;
  sTitle: string;
  sOvertitle: string;
  sDescription: string;
  sHref: string;
  sImage: string;
  sImageSmall: string;
  iConversion: number;
  iCellsCountOnGlobalMap: number;
  iCellsCountOnMapIslands: number;
  bDateStart: number;
  bDateEnd: number;
};

const AdminGame = ({ game }: { game: Game }) => {
  const [generateRandomData, { isLoading, error, isSuccess, isError }] =
    useMapRandomGenerationMutation();

  const handleGenerateRandomData = async () => {
    try {
      await generateRandomData({ iGameId: game.id });
    } catch (error) {
      console.log("error while generate: ", error);
    }
  };

  return (
    <>
      <tr className="admin-game-head__tr">
        <td className="admin-game__td">{game.id}</td>
        <td className="admin-game__td">{game.sTitle}</td>
        <td className="admin-game__td">{game.sOvertitle}</td>
        <td className="admin-game__td">{game.sDescription}</td>
        <td className="admin-game__td">
          <a className="admin-game__link " href={game.sHref}>
            ссылка
          </a>
        </td>
        <td className="admin-game__td">
          <img className="admin-game__image" src={game.sImage} alt="" />
        </td>
        <td className="admin-game__td">{game.iConversion}</td>
        <td className="admin-game__td">{game.iCellsCountOnGlobalMap}</td>
        <td className="admin-game__td">{game.iCellsCountOnMapIslands}</td>
        <td className="admin-game__td">
          {dayjs(game.bDateStart * 1000).format("DD/MM/YYYY")}
        </td>
        <td className="admin-game__td">
          {dayjs(game.bDateEnd * 1000).format("DD/MM/YYYY")}
        </td>
        <td className="admin-game__td">
          <Link to={`/map/${game.id}`} className="admin-game__button">
            Редактирование
          </Link>
          <button
            disabled={isLoading}
            onClick={handleGenerateRandomData}
            className="admin-game__button"
          >
            Генерациях данных
          </button>
        </td>
        {isLoading && (
          <Loader
            width="20%"
            height="20%"
            isAbsolute
            absoluteContainerStyle={{ backgroundColor: "#00000030" }}
          />
        )}
      </tr>
      <TableError error={error} isError={isError} />
      {isSuccess && (
        <tr style={{ backgroundColor: "#72d775" }}>
          <td colSpan={12} style={{ color: "#000", textAlign: "center" }}>
            Генерация данных успешна
          </td>
        </tr>
      )}
    </>
  );
};

type Props = {};

const AdminGames = (props: Props) => {
  const { data, isLoading, error, isError, refetch } = useAllQuery("");

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return (
      <div className="admin-games-loader">
        <Loader width="20%" height="20%" />
      </div>
    );
  }

  if (error) {
    return <AdminError error={error} isError={isError} />;
  }

  return (
    <>
      <h1 className="admin-games-title">Список игр</h1>
      <table className="admin-games-container">
        <thead className="admin-game-head">
          <tr className="admin-game-head__tr">
            <th className="admin-game-head__th">id</th>
            <th className="admin-game-head__th">Заголовок</th>
            <th className="admin-game-head__th">Надзаголовок</th>
            <th className="admin-game-head__th">Описание</th>
            <th className="admin-game-head__th">Ссылка</th>
            <th className="admin-game-head__th">Изображение</th>
            <th className="admin-game-head__th">Конвертация</th>
            <th className="admin-game-head__th">Ячеек карты</th>
            <th className="admin-game-head__th">Ячеек островов</th>
            <th className="admin-game-head__th">Старт</th>
            <th className="admin-game-head__th">Окончание</th>
            <th className="admin-game-head__th"></th>
          </tr>
        </thead>
        <tbody className="admin-game">
          {data?.data?.model?.map((game: Game) => {
            return <AdminGame key={game.id} game={game} />;
          })}
          <TableError error={error} isError={isError} />
        </tbody>
      </table>
    </>
  );
};

export default AdminGames;
