import "./Map.css";

import ChatButton from "../ChatButton";
import { useAppDispatch, useAppSelector } from "../../store";
import Land from "./Land";
import Sea from "./Sea";
import Congratulations from "../Congratulations";
import {
  useHeaderStatisticQuery,
  useNotificationsQuery,
  useReadNotificationMutation,
} from "../../store/services/teams";
import {useEffect, useState} from "react";
import {
  removeFirstNotifications,
  removeFirstUnreadNotifications,
  updateLastEvent,
} from "../../store/slices/mapSlice";
import { setInitialModal } from "../../store/slices/modal/modalSlice";
import { CellTypeCode } from "../../@types/map";
import modal from "../Modal";

const Map = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const mapType = useAppSelector((state) => state.map.mapType);
  const userProfile = useAppSelector((state) => state.auth.user);
  const unreadNotifications = useAppSelector(
    (state) => state.map.unreadNotifications
  );
  const notifications = useAppSelector((state) => state.map.notifications);
  const modalContentIsOpen = useAppSelector((state) => state.modal.showModal);

  const { data } = useHeaderStatisticQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  });

  const [readNotification, readNotificationState] =
    useReadNotificationMutation();

  // С сервер приходят события связанные с глобальной картой(форс мажоры и бонусы)
  // роли и фрагменты не обрабатываются
  const notificationState = useNotificationsQuery({
    iGameProfileId: Number(userProfile?.id),
  });

  const closeModalWindow = () => {
    setShowModal(false);
  };

  useEffect(() => {
    notificationState.refetch();
  }, []);

  useEffect(() => {
    // Для срабатывания необходимо чтобы:
    // - в массиве notifications были элементы
    // - не должно быть непрочитанных уведомлений в unreadNotifications
    // - всплывающее окно должно быть закрыто
    if (
      notifications?.length &&
      !unreadNotifications?.length &&
      !modalContentIsOpen
    ) {
      // Могут быть любые события
      setTimeout(() => {
        // Если iEventType = CellTypeCode.fragment то открываем модальное окно с фрагментами
        if (Number(notifications[0].iEventType) === CellTypeCode.fragment) {
          dispatch(setInitialModal("fragments"));
        } else {
          dispatch(setInitialModal("event"));
          dispatch(updateLastEvent(notifications[0]));
        }
        dispatch(removeFirstNotifications());
      }, 500);
    }
  }, [notifications, unreadNotifications, modalContentIsOpen]);

  useEffect(() => {
    if (
      unreadNotifications?.length &&
      !modalContentIsOpen &&
      !readNotificationState.isLoading
    ) {
      // Проверят соответствует ли первый элемент форс мажору или бонусу
      // Если соответствует то продолжается выполнение эффекта, иначе выходит
      if (
        !(
          Number(unreadNotifications[0].iEventType) === CellTypeCode.bonus ||
          Number(unreadNotifications[0].iEventType) ===
            CellTypeCode.forceMajeure
        )
      )
        return;

      setTimeout(() => {
        // Открываем модалку с событием
        dispatch(setInitialModal("event"));
        dispatch(updateLastEvent(unreadNotifications[0].event));

        // Отправляем на сервер уведомлении о чтении
        readNotification({
          iNotificationId: Number(unreadNotifications[0].id),
        });

        // Убираем первый элемент с уведомлением из массива для того чтобы отображать следующий
        dispatch(removeFirstUnreadNotifications());
      }, 500);
    }
  }, [
    unreadNotifications,
    modalContentIsOpen,
    readNotificationState.isLoading,
  ]);

  if (data && data?.iTotalFragmentCount === data?.iFragmentCount) {
    setShowModal(true);
    return (
      <>
        {!showModal ? null : <Congratulations closeModalWindow={closeModalWindow} />}
      </>
      )
  }

  return (
    <>
      {mapType === "sea" ? <Sea /> : <Land />}
      <ChatButton />
    </>
  );
};

export default Map;
