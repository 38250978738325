import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { DeepRequired, FieldErrorsImpl, useForm } from "react-hook-form";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {
  useForgotPasswordMutation,
  useLoginMutation,
  useRegisterMutation,
} from "../../store/services/auth";
import * as yup from "yup";
import { LoginForm } from "../../@types/authentication";
import "./AuthForm.css";
import Button from "../Button";
import Loader from "../Loader";
import tentacleTopLeft from "../../assets/images/tentacleTopLeft.png";
import tentacleBottomMiddle from "../../assets/images/tentacleBottomMiddle.png";
import tentacleBottomLeft from "../../assets/images/tentacleBottomLeft.png";
import tentacleMiddleLeft from "../../assets/images/tentacleMiddleLeft.png";
import tentacleTopMiddle from "../../assets/images/tentacleTopMiddle.png";
import tentacleTopRight from "../../assets/images/tentacleTopRight.png";
import parchment from "../../assets/images/svg/parchment.svg";
import AuthInputFile from "./AuthInputFile";
import {
  AccessDenisError,
  ResponseAuthError,
  ResponseError,
  StatusCodes,
} from "../../@types/api";

type AuthTypes = "login" | "register" | "recovery";

const getLoginSchema = (type: AuthTypes) => {
  let loginSchema = yup
    .object({
      sEmail: yup
        .string()
        .required("Электронная почта не может быть пустой.")
        .trim()
        .email("Электронная почта не является действительной."),
    })
    .required();

  if (type !== "recovery") {
    const passwordSchema = yup
      .object({
        password: yup
          .string()
          .required("Пароль не может быть пустым.")
          .trim()
          .min(6, "Пароль должен состоять минимум из 6 символов."),
      })
      .required();

    loginSchema = loginSchema.concat(passwordSchema);
  }

  if (type === "register") {
    const imageSchema = yup
      .object({
        sFile: yup.lazy((value) =>
          /^data/.test(value)
            ? yup
                .string()
                .trim()
                .matches(
                  /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
                  "Must be a valid data URI"
                )
                .required("")
            : yup
                .string()
                .trim()
                .url("Must be a valid URL")
                .required("Необходимо выбрать файл для аватара")
        ),
      })
      .required("");

    return loginSchema.concat(imageSchema);
  }
  return loginSchema;
};

const Errors = ({
  errors,
  signupError,
  isSignupError,
}: {
  errors: FieldErrorsImpl<DeepRequired<LoginForm>>;
  signupError: FetchBaseQueryError | SerializedError | undefined;
  isSignupError: boolean;
}) => {
  if (
    isSignupError &&
    signupError &&
    "status" in signupError &&
    signupError?.status === StatusCodes.accessDenied
  ) {
    return (
      <p className="login__input-error">
        {(signupError?.data as AccessDenisError).data.errors}
      </p>
    );
  }

  if (isSignupError && signupError && "status" in signupError) {
    return (
      <p className="login__input-error">
        {(signupError?.data as ResponseAuthError)?.data.errors ||
          "Произошла ошибка"}
      </p>
    );
  }

  return (
    <p className="login__input-error">
      {!!Object.values(errors)?.length && Object.values(errors)[0]?.message}
    </p>
  );
};

type Props = {};

const AuthForm = (props: Props) => {
  const [
    login,
    { isLoading: isLoginLoading, error: loginError, isError: isLoginError },
  ] = useLoginMutation();
  const [
    signup,
    {
      isLoading: isSignupLoading,
      error: signupError,
      isError: isSignupError,
      isSuccess: isSignupSuccess,
    },
  ] = useRegisterMutation();
  const [recoveryPassword, recoveryPasswordMeta] = useForgotPasswordMutation();

  const [authType, setAuthType] = useState<AuthTypes>("login");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: yupResolver(getLoginSchema(authType)),
    defaultValues: {
      sEmail: "",
      password: "",
      sFile: "",
    },
  });

  const handleLogin = async ({ sEmail, password, sFile }: LoginForm) => {
    try {
      switch (authType) {
        case "login":
          await login({
            sEmail,
            password,
          });
          break;

        case "register":
          await signup({
            sEmail,
            password,
            sFile,
          });
          break;

        case "recovery":
          await recoveryPassword({ sEmail });
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAuthTypePress = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (e?.currentTarget?.id !== authType)
      setAuthType(e?.currentTarget?.id as AuthTypes);
  };

  const authTypeText = (authType: AuthTypes) => {
    switch (authType) {
      case "login":
        return "Авторизация";

      case "register":
        return "Регистрация";

      case "recovery":
        return "Восстановить";
      default:
        return "Авторизация";
    }
  };

  return (
    <form noValidate className="login" onSubmit={handleSubmit(handleLogin)}>
      <img src={tentacleTopLeft} className="login__tentacle-left-top" />
      <img
        src={tentacleBottomMiddle}
        className="login__tentacle-bottom-middle"
      />
      <img src={tentacleBottomLeft} className="login__tentacle-bottom-left" />
      <img src={tentacleMiddleLeft} className="login__tentacle-middle-left" />
      <img src={tentacleTopMiddle} className="login__tentacle-top-middle" />
      <img src={tentacleTopRight} className="login__tentacle-top-right" />
      <img src={parchment} className="login__parchment" />
      <p className="login__title">{authTypeText(authType)}</p>
      <div className="auth">
        <button
          id="login"
          className={`auth__button ${
            authType === "login" ? "auth__button_red" : "auth__button_opacity"
          }`}
          onClick={handleAuthTypePress}
        >
          <svg
            className="auth__button-logo"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0001 6C13.1001 6 14.0001 6.9 14.0001 8C14.0001 9.1 13.1001 10 12.0001 10C10.9001 10 10.0001 9.1 10.0001 8C10.0001 6.9 10.9001 6 12.0001 6ZM12.0001 16C14.7001 16 17.8001 17.29 18.0001 18H6.00008C6.23008 17.28 9.31008 16 12.0001 16ZM12.0001 4C9.79008 4 8.00008 5.79 8.00008 8C8.00008 10.21 9.79008 12 12.0001 12C14.2101 12 16.0001 10.21 16.0001 8C16.0001 5.79 14.2101 4 12.0001 4ZM12.0001 14C9.33008 14 4.00008 15.34 4.00008 18V20H20.0001V18C20.0001 15.34 14.6701 14 12.0001 14Z"
              fill={`${authType === "login" ? "#A92406" : "#001439"}`}
            />
          </svg>
          авторизация
        </button>
        <button
          id="register"
          className={`auth__button auth__button_ml ${
            authType === "register"
              ? "auth__button_red"
              : "auth__button_opacity"
          }`}
          onClick={handleAuthTypePress}
        >
          <svg
            className="auth__button-logo"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 9V6H18V9H15V11H18V14H20V11H23V9H20ZM9 12C11.21 12 13 10.21 13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12ZM9 6C10.1 6 11 6.9 11 8C11 9.1 10.1 10 9 10C7.9 10 7 9.1 7 8C7 6.9 7.9 6 9 6ZM15.39 14.56C13.71 13.7 11.53 13 9 13C6.47 13 4.29 13.7 2.61 14.56C2.12206 14.8113 1.71331 15.1929 1.4291 15.6625C1.1449 16.132 0.996374 16.6712 1 17.22V20H17V17.22C17 16.1 16.39 15.07 15.39 14.56ZM15 18H3V17.22C3 16.84 3.2 16.5 3.52 16.34C4.71 15.73 6.63 15 9 15C11.37 15 13.29 15.73 14.48 16.34C14.8 16.5 15 16.84 15 17.22V18Z"
              fill={`${authType === "register" ? "#A92406" : "#001439"}`}
            />
          </svg>
          регистрация
        </button>
      </div>
      <input
        type="email"
        className="login__input"
        placeholder="Укажите ваш e-mail"
        {...register("sEmail", { required: true })}
      />
      {authType !== "recovery" && (
        <input
          type="password"
          className="login__input"
          placeholder="Введите пароль"
          {...register("password", { required: true })}
        />
      )}
      <div className="login__forgot-container">
        <button
          id="recovery"
          onClick={handleAuthTypePress}
          className="login__forgot"
        >
          Забыли пароль?
        </button>

        {authType === "register" && (
          <>
            <AuthInputFile
              callbackFunction={(base64: string) => {
                setValue("sFile", base64);
              }}
              className="login__file-input"
              type="file"
              id="sFile"
              accept="image/*"
              {...register("sFile", { required: true })}
            />
          </>
        )}
      </div>
      <Errors
        errors={errors}
        signupError={signupError || loginError || recoveryPasswordMeta?.error}
        isSignupError={
          isSignupError || isLoginError || recoveryPasswordMeta.isError
        }
      />
      {isSignupSuccess && (
        <p className="login__input-success">Реистрация прошла успешно</p>
      )}
      {isLoginLoading || isSignupLoading || recoveryPasswordMeta?.isLoading ? (
        <Loader />
      ) : (
        <Button
          type="submit"
          // onClick={handleLogin}
          text="войти"
          className="button_red-small"
        />
      )}
    </form>
  );
};

export default AuthForm;
