import { NavLink } from "react-router-dom";
import Stat from "../Stat";
import "./Topbar.css";

type Props = {};

const Topbar = ({}: Props) => {
  return (
    <header className="topbar">
      <NavLink className="logo" to="/">
      </NavLink>
      <Stat />
    </header>
  );
};

export default Topbar;
