import dayjs from "dayjs";
import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { ChatMessages } from "../../@types/chat";
import stringToColour from "../../helpers/stringToColour";
import { useAppDispatch, useAppSelector } from "../../store";
import { handleSendWS, sendWS } from "../../store/slices/wsSlice";
import Button from "../Button";
import "dayjs/locale/ru";

import "../Modal/Modal.css";
import "./ModalChat.css";
import { useTeamChatQuery } from "../../store/services/teams";

const Avatar = ({
  name,
  color = "#2cf0aa",
}: {
  name: string;
  color?: string;
}) => {
  return (
    <div className="chat-avatar" style={{ background: color }}>
      {name.charAt(0)}
    </div>
  );
};

const MessageComponent = ({ message }: { message: ChatMessages }) => {
  const userProfile = useAppSelector((state) => state.auth.user);
  const isActiveUser = message.iFromGameProfileId === userProfile?.id;

  return (
    <div className="message">
      {!isActiveUser && (
        <Avatar
          name={message.gameProfile.sLastName}
          color={stringToColour(
            `${message.gameProfile.sFirstName} ${message.gameProfile.sLastName}`
          )}
        />
      )}
      <div
        className={`message__text ${
          isActiveUser ? "message__text_active-user" : ""
        }`}
      >
        {message.sMessage}
        {!!message.event?.sImage && (
          <div>
            <img
              className="message__image"
              src={message.event?.sImage || ""}
              alt=""
            />
          </div>
        )}
        <p className="message__time">
          {dayjs(Number(message.bSendDate) * 1000).format("HH:mm")}
        </p>
      </div>
    </div>
  );
};

const MessageTime = ({ timestamp }: { timestamp: number }) => {
  return (
    <div className="message-time">
      {dayjs(timestamp).locale("ru").format("DD MMMM")}
    </div>
  );
};

const Messages = () => {
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.chat.messages);
  const isCanLoadOldMessages = useAppSelector(
    (state) => state.chat.isCanLoadOldMessages
  );
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const [isLoadFirstMessages, setIsLoadFirstMessages] = useState(false);

  const isSameDay = (currentMessageDate: number, prevMessageDate: number) => {
    return (
      dayjs(currentMessageDate).format("DD") !==
      dayjs(prevMessageDate).format("DD")
    );
  };

  const renderDay = (message: ChatMessages, index: number) => {
    if (!messages?.length) return null;
    if (index === 0)
      return (
        <MessageTime timestamp={Number(messages[index].bSendDate) * 1000} />
      );
    if (
      isSameDay(
        Number(messages[index].bSendDate) * 1000,
        Number(messages[index - 1].bSendDate) * 1000
      )
    ) {
      return (
        <MessageTime timestamp={Number(messages[index].bSendDate) * 1000} />
      );
    }
    return null;
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollTop === 0 && isCanLoadOldMessages) {
      handleSendWS({ action: "moreMessages", iOffset: messages.length });
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (!isLoadFirstMessages && messages.length) {
      setIsLoadFirstMessages(true);
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 600);
    }
  }, [isLoadFirstMessages, messages]);

  return (
    <div className="messages" onScroll={handleScroll}>
      {messages?.map((message, index) => (
        <React.Fragment key={message.id}>
          {renderDay(message, index)}
          <MessageComponent message={message} key={message.id} />
        </React.Fragment>
      ))}
      <div ref={bottomRef} />
    </div>
  );
};

const MessageInput = ({
  placeholder = "Напишите сообщение...",
}: {
  placeholder?: string;
}) => {
  const userProfile = useAppSelector((state) => state.auth.user);
  const [sMessage, setMessage] = useState("");

  const { data, isLoading, isError, error } = useTeamChatQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  });

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(e.target.value);

  const handleSend = () => {
    if (!sMessage) return;

    handleSendWS({
      action: "sendMessage",
      iChatId: Number(data?.id),
      sMessage,
    });
    setMessage("");
  };

  return (
    <>
      <textarea
        value={sMessage}
        onChange={handleChange}
        placeholder={placeholder}
        className="message-input"
      />
      <Button
        disabled={isLoading || isError}
        text="Отправить"
        className="send-message"
        onClick={handleSend}
      />
    </>
  );
};

const Crews = () => {
  const crews = useAppSelector((state) => state.chat.profiles);

  return (
    <div className="chat__crews">
      {crews?.map((crew, index) => {
        return (
          <div className="crew" key={index}>
            <Avatar
              name={crew?.sLastName}
              color={stringToColour(`${crew?.sFirstName} ${crew?.sLastName}`)}
            />
            <div className="crew__block">
              <p className="crew__name">
                {crew?.sFirstName} {crew?.sLastName}
              </p>
              <p className="crew__position">{crew?.sPosition}</p>
            </div>
            <div
              className={`crew__status ${
                crew?.online ? "crew__status_online" : "crew__status_offline"
              }`}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

type Props = {
  title: string;
};

function ModalChat({ title }: Props) {
  // const dispatch = useAppDispatch();

  // const myFormRef = useRef<HTMLFormElement | null>(null);
  // const [message, setMessage] = useState("");

  // const handleButtonClick = () => {
  //   if (!message) return;

  //   setMessage("");
  // };

  // const handleEnterPress = (e) => {
  //   if (e.keyCode == 13 && e.shiftKey == false) {
  //     e.preventDefault();
  //     myFormRef.current?.submit();
  //   }
  // };

  useEffect(() => {
    handleSendWS({ action: "onlineProfiles" });
    handleSendWS({ action: "openChat" });
  }, []);

  return (
    <>
      <h3 className="modal-title">{title}</h3>
      <div className="chat">
        <Crews />
        <div className="chat__messages">
          <Messages />
          <div className="chat__send">
            <MessageInput />
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalChat;
