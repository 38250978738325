import { ResponseSuccess } from "./api";

export enum CellTypeCode {
  sea = 1,
  land = 2,
  island = 3,
  bonus = 4,
  forceMajeure = 5,
  rank = 6,
  fragment = 7,
}

export type MapEvent = {
  id: number;
  iGameId: number;
  sTitle: string;
  iCellId: string | null;
  iEventType: number;
  iCardType: number;
  sImage: string | null;
  sImageSmall: string | null;
  sEventDescription: string;
  sEffectDescription: string;
  iPositionId: null | number;
  iKeyForValue: number;
  iValue: number;
};

export type MapEventWithHelp = MapEvent & {
  help?: {
    iCellId: null;
    iEventType: string;
    iGameId: string;
    iSequenceNumber: null;
    id: string;
    sAdvantage: string;
    sDescription: string;
    sImage: string;
    sImageSmall: string;
    sTitle: string;
  };
};

export type MapPosition = {
  id: string;
  iGameId: string;
  iCellId: string;
  iSequenceNumber: null;
  sTitle: string;
  sDescription: string;
  sImage: string;
  sImageSmall: string;
  sAdvantage: string;
};

export type MapFragment = {
  id: string;
  iGameId: string;
  sImage: string;
  sImageSmall: string;
  iCellId: string;
  iRow: string;
  iNumber: string;
};

export type MapRowValue = {
  id: string;
  iGameId: string;
  sTitle: string;
  sRow: string;
  iNumber: string;
  iTypeCard: string;
  iIslandId: string;
  iCellType: string;
  iEventId: string | null;
  sImage: string | null;
  // position: null | MapPosition;
  event: null | MapEventWithHelp;
};

export type MapAdditionalPosition = {
  id: string;
  iGameId: string;
  iCellId: null;
  iSequenceNumber: null;
  sTitle: string;
  sDescription: string;
  sImage: string;
  sImageSmall: string;
  sAdvantage: string;
};

export type MapProfile = {
  id: string;
  iGameId: string;
  iUserId: null;
  iProfileId: null;
  iTeamId: string;
  sFirstName: string;
  sLastName: string;
  sPatronymic: string;
  sEmail: string;
  sPosition: string;
  iAdditionalPositionId: null;
  iStepCount: string;
  additionalPosition: null | MapAdditionalPosition;
  sImage: null;
  sImageSmall: null;
};

export type MapRowMainValue = MapRowValue & {
  fragment: never;
  iCurrentPosition: never;
  profiles: never;
  sDecorationImage: never;
};

export type MapRowWithFragment = MapRowValue & {
  fragment: MapFragment | null;
  iCurrentPosition: never;
  profiles: never;
  sDecorationImage: never;
};

export type MapRowGameValue = MapRowValue & {
  fragment: never;
  iCurrentPosition: number;
  profiles: never;
  sDecorationImage: never;
};

export type MapGameRowIsland = MapRowValue & {
  fragment: MapFragment | null;
  iCurrentPosition: number;
  profiles?:
    | MapProfile[]
    | {
        [key: string]: MapProfile;
      };
  sDecorationImage?: string;
};

export type MapRow<T = MapRowMainValue> = {
  [key: string]: T;
};

export type MapFragmet = {
  id: number;
  iGameId: number;
  sImage: string;
  sImageSmall: string;
  iCellId: number;
  iRow: number;
  iNumber: number;
};

export type MapImageDefault = {
  id: number;
  iGameId: number;
  sImage: string;
  iTypeMap: number;
  iType: number;
};

export type MapAllCellsResponse<T = MapRow> = ResponseSuccess<{
  sImageMap: MapImageDefault;
  aCells: { [key: string]: T };
}>;

export type GameMapAllCellsResponseSuccess = ResponseSuccess<{
  aCells: { [key: string]: MapRow<MapRowGameValue> };
}>;

export type GameIslandMapResponseSuccess = ResponseSuccess<{
  aCells: { [key: string]: MapRow<MapRowWithFragment> };
}>;

export type MapIslandAllCellsResponse = MapAllCellsResponse<
  MapRow<MapRowWithFragment>
>;

export type MapGameIslandCellsResponseSuccess = ResponseSuccess<{
  aCells: { [key: string]: MapRow<MapGameRowIsland> };
}>;
