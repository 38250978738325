import React, { useState } from "react";

type Props = {
  title: string;
  children: React.ReactNode;
};

const AdminMapMenuDropDown = ({ title, children }: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const toggling = () => setIsOpened((isOpened) => !isOpened);

  return (
    <div>
      <button
        style={{
          width: "calc(100% - 20px)",
          margin: 10,
          height: 35,
          cursor: "pointer",
        }}
        onClick={toggling}
      >
        {title}
      </button>
      {isOpened && children}
    </div>
  );
};

export default AdminMapMenuDropDown;
