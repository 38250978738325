import React, { useEffect, useRef, useState } from "react";
import Draggable, {
  DraggableEventHandler,
  DraggableProps,
} from "react-draggable";
import useWindowSize from "../../hooks/useWindowSize";
import backgroundCity from "../../assets/images/backgroundCity.png";
import backgroundForest from "../../assets/images/forestBackground.png";

import "./adminDraggableMap.css";

type Props = Partial<DraggableProps> & {
  disabled?: boolean;
  children: React.ReactNode;
  mapType?: 'sea' | 'island';
};

const AdminDraggableMap = ({ disabled, children, mapType, ...props }: Props) => {
  const blockRef = useRef<HTMLDivElement | null>(null);
  const [bound, setBound] = useState({
    left: -1000,
    right: 300,
    top: -100,
    bottom: 999,
  });
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [width, height] = useWindowSize();

  const eventControl: DraggableEventHandler = (event) => {
    if (event.type === "mousemove" || event.type === "touchmove") {
      setIsDragging(true);
    }

    if (event.type === "mouseup" || event.type === "touchend") {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isDragging) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (blockRef?.current) {
      const offsetWidth = blockRef.current.offsetWidth;
      const offsetHeight = blockRef.current.offsetHeight;

      setBound({
        left: -offsetWidth + width / 2,
        right: width / 2,
        bottom: height / 2,
        top: -offsetHeight + height / 2,
      });
    }
  }, [blockRef, width, height]);

  return (
    <div className="admin-draggable" style={{backgroundImage: mapType === "island" ? backgroundCity : backgroundForest } }>
      <Draggable
        disabled={disabled}
        nodeRef={blockRef}
        bounds={bound}
        // handle=".handle"
        onDrag={eventControl}
        onStop={eventControl}
        {...props}
      >
        <div
          className="admin-draggable__map"
          ref={blockRef}
          onClickCapture={handleClick}
        >
          {children}
        </div>
      </Draggable>
    </div>
  );
};

export default AdminDraggableMap;
