import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MapEvent } from "../../@types/map";

import Loader from "../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../store";
import { adminApi, useChangeCellMutation } from "../../store/services/admin";
import { updateCellChangeStatus } from "../../store/slices/adminSlice";

import "./AdminMapMenu.css";
import AdminMapMenuDropDown from "./AdminMapMenuDropDown";
import MoveCellData from "./MoveCellData";
// import MoveCellData from "./MoveCellData";

type DrodownTyp = {
  data?: MapEvent[] | null;
  type: number;
  title: string;
  disabled: boolean;
  onClick: (event: MapEvent) => void;
};
const Dropdown = ({ data, type, title, disabled, onClick }: DrodownTyp) => {
  if (!data) return null;

  return (
    <AdminMapMenuDropDown title={title}>
      {data
        .filter((event) => event.iEventType === type)
        .map((event) => {
          return (
            <button
              disabled={disabled}
              onClick={() => onClick(event)}
              key={event.id}
              className={`admin-map-menu__item admin-map-menu__item_${event.iEventType}`}
            >
              <h2>{event.sTitle}</h2>
              <p>{event.sEventDescription}</p>
              <img style={{ maxHeight: 100 }} src={event.sImage || ""} alt="" />
            </button>
          );
        })}
    </AdminMapMenuDropDown>
  );
};

type Props = {
  type?: "island" | "sea";
};

const AdminMapMenu = ({ type = "sea" }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const activeCell = useAppSelector((state) => state.admin.activeCellData);
  const isChangeCellObject = useAppSelector(
    (state) => state.admin.isChangeCellObject
  );
  const [changeCellEvent, { isLoading, error }] = useChangeCellMutation();

  const data = adminApi.endpoints.getEvents.useQueryState({
    iGameId: Number(id),
  });

  const handleChangeEvent = async (iEffectId: number, iCellType: number) => {
    try {
      await changeCellEvent({
        type,
        data: {
          iGameId: Number(id),
          iIslandId: Number(activeCell?.iIslandId),
          iCellType,
          iCellId: Number(activeCell?.iCellId),
          iEffectId,
        },
      });
    } catch (error) {
      console.log("error when updating cell: ", error);
    }
  };

  if (!activeCell) return null;
  if (activeCell.iCellType === 7) return null;

  if (activeCell?.iCellType === 3) {
    return (
      <div className="admin-map-menu">
        <MoveCellData />;
        <button
          className="admin-map-menu__button"
          onClick={() =>
            navigate(`/map/${id}/${Number(activeCell?.iIslandId)}`)
          }
          disabled={isChangeCellObject}
        >
          Перейти в город
        </button>
      </div>
    );
  }

  return (
    <div className="admin-map-menu">
      {activeCell?.iCellType >= 4 && (
        <button
          className="admin-map-menu__button"
          onClick={() => handleChangeEvent(0, 1)}
          disabled={isChangeCellObject}
        >
          Удалить событие
        </button>
      )}
      <Dropdown
        data={data?.currentData?.data?.model?.events}
        type={5}
        title="Форс мажоры"
        disabled={isChangeCellObject}
        onClick={(event) =>
          handleChangeEvent(Number(event.id), Number(event.iEventType))
        }
      />
      <Dropdown
        data={data?.currentData?.data?.model?.events}
        type={4}
        title="Бонус"
        disabled={isChangeCellObject}
        onClick={(event) =>
          handleChangeEvent(Number(event.id), Number(event.iEventType))
        }
      />
      {type === "island" && !!data && (
        <Dropdown
        data={data?.currentData?.data?.model?.events}
        type={6}
        title="Должности"
        disabled={isChangeCellObject}
        onClick={(event) =>
          handleChangeEvent(Number(event.id), Number(event.iEventType))
        }
        />
      )}
      {/* <MoveCellData title="Перенести событие" /> */}
      {/* {activeCell?.iCellType >= 4 && (
        <button
          className="admin-map-menu__button"
          onClick={() => handleChangeEvent(0, 1)}
          disabled={isChangeCellObject}
        >
          Удалить событие
        </button>
      )}
      {!!data &&
        data.currentData?.data.model.events
          // 6 - это роли которые могут быть только на острове
          .filter((event) => {
            if (type === "sea") {
              return event?.iEventType !== 6;
            } else {
              return true;
            }
          })
          .map((event) => {
            return (
              <button
                disabled={isChangeCellObject}
                onClick={() =>
                  handleChangeEvent(Number(event.id), Number(event.iEventType))
                }
                key={event.id}
                className={`admin-map-menu__item admin-map-menu__item_${event.iEventType}`}
              >
                <h2>{event.sTitle}</h2>
                <p>{event.sEventDescription}</p>
                <img
                  style={{ maxHeight: 100 }}
                  src={event.sImage || ""}
                  alt=""
                />
              </button>
            );
          })} */}
      {type === "island" && !!data && (
        <AdminMapMenuDropDown title="Фрагменты">
          {data.currentData?.data.model.fragments.map((fragment) => {
            return (
              <button
                disabled={isChangeCellObject}
                onClick={() => handleChangeEvent(Number(fragment.id), 7)}
                key={fragment.id}
                className="admin-map-menu__item"
              >
                <img
                  style={{ maxHeight: 100 }}
                  src={fragment.sImage || ""}
                  alt=""
                />
              </button>
            );
          })}
        </AdminMapMenuDropDown>
      )}
      {isLoading && (
        <div className="admin-map-menu__loader">
          <Loader width="100px" height="100px" />
        </div>
      )}
    </div>
  );
};

export default AdminMapMenu;
