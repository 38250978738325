import React, { useEffect, useState } from "react";
import { MapGameRowIsland, MapProfile } from "../../@types/map";
import stringToColour from "../../helpers/stringToColour";

import "./MapPlayers.css";

type Props = {
  element: MapGameRowIsland;
};

const MapPlayers = ({ element }: Props) => {
  const [players, setPlayers] = useState<
    { id: string; letter: string; color: string }[]
  >([]);

  useEffect(() => {
    if (!element || !element?.profiles) {
      setPlayers([]);
      return;
    }

    let array: MapProfile[] = [];

    if (Array.isArray(element?.profiles)) {
      array = element.profiles;
    } else {
      array = Object.values(element.profiles);
    }

    const firstLetterProfileNames = array.map((profile) => {
      return {
        id: profile?.id,
        letter: profile?.sLastName?.slice(0, 1) || "",
        color: stringToColour(`${profile?.sFirstName} ${profile?.sLastName}`),
      };
    });

    setPlayers(firstLetterProfileNames);
  }, [element]);

  return (
    <div className="map-players-wrapper">
      <div className="map-players">
        {players.map((player, index) => {
          return (
            <div
              key={player?.id}
              className="map-players__item"
              style={{ background: player?.color || undefined }}
            >
              {player?.letter || ""}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MapPlayers;
