import React from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { updateCellChangeStatus } from "../../store/slices/adminSlice";

type Props = {
  title?: string;
};

const MoveCellData = ({ title = "Перенести город" }: Props) => {
  const dispatch = useAppDispatch();
  const isChangeCellObject = useAppSelector(
    (state) => state.admin.isChangeCellObject
  );

  const handleChangeCell = () => {
    dispatch(updateCellChangeStatus(!isChangeCellObject));
  };

  return (
    <>
      <button
        className={`admin-map-menu__button${
          isChangeCellObject ? " admin-map-menu__button_active" : ""
        }`}
        onClick={handleChangeCell}
      >
        {title}
      </button>
      {isChangeCellObject && (
        <p style={{ textAlign: "center" }}>Выберите ячейку для переноса</p>
      )}
    </>
  );
};

export default MoveCellData;
