import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import Button from "../Button";

import "./ModalSelectContent.css";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectCurrentUser } from "../../store/slices/modal/authSlice";
import {
  useAppointToPositionMutation,
  useFreePositionsQuery,
} from "../../store/services/teams";
import Loader from "../Loader";
import Error from "../Error";
import { FreePosition } from "../../@types/teams";
import {
  closeSelectModal,
  setSelectModal,
} from "../../store/slices/modal/modalSlice";
import AppointToPosition from "./AppointToPosition";
import RemoveFromPosition from "./RemoveFromPosition";
import RemoveFromTeam from "./RemoveFromTeam";

const ModalSelectContent = ({
  title,
  // data,
  type,
}: {
  title: string;
  // data: any;
  type?: "appoint" | "takeOff" | "delete";
}) => {
  // const [isShowDelete, setIsShowDelete] = useState(false);

  // if (isShowDelete) {
  //   return (
  //     <>
  //       <h3 className="select-modal__title select-modal__title_center">
  //         Вы уверены?
  //       </h3>
  //       <p className="select-content_text">
  //         уволить
  //         <br />
  //         ЛИДИЮ ЯРОСЛАВЦЕВУ
  //       </p>
  //       <div className="select-content__buttons">
  //         <Button
  //           text="да"
  //           className="select-content__button button_gradient-red"
  //           onClick={onSaveHandler}
  //         />
  //         <Button
  //           text="нет"
  //           className="select-content__button"
  //           onClick={onSaveHandler}
  //         />
  //       </div>
  //     </>
  //   );
  // }

  const renderContent = () => {
    switch (type) {
      case "appoint":
        return <AppointToPosition type={type} />;

      case "takeOff":
        return <RemoveFromPosition type={type} />;

      case "delete":
        return <RemoveFromTeam />;
      default:
        return null;
    }
  };

  return (
    <>
      <h3 className="select-modal__title">{title}</h3>
      <div className="select-content">{renderContent()}</div>
    </>
  );
};

export default ModalSelectContent;
