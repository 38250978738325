import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { StatusCodes } from "../../@types/api";
import { LoginForm } from "../../@types/authentication";
import { UserProfile } from "../../@types/profile";
import { baseUrl } from "../../api/instance";
import {
  setCredentials,
  signOut,
  updateAvatar,
} from "../slices/modal/authSlice";

export interface UserResponse {
  success: boolean;
  data: {
    status: number;
    message: string;
    jwt: string;
    model: UserProfile;
  };
}

export const authApi = createApi({
  reducerPath: "authApi",
  // baseQuery: axiosBaseQuery({
  baseQuery: fetchBaseQuery({
    // mode: "no-cors",
    baseUrl: `${baseUrl}/user/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginForm>({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
      async onQueryStarted(credentials, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;

          if (result?.data?.data?.status === StatusCodes.success) {
            const { jwt, model } = result.data.data;

            localStorage.setItem("jwt", jwt);
            localStorage.setItem("user", JSON.stringify(model));

            dispatch(
              setCredentials({
                token: jwt,
                user: model,
              })
            );
          }
        } catch (error) {
          console.log("query error: ", error);
        }
      },
    }),
    register: builder.mutation<UserResponse, LoginForm>({
      query: (body) => ({
        url: "signup",
        method: "POST",
        body,
      }),
    }),
    refreshToken: builder.mutation({
      query: (body) => ({
        url: "refresh-token",
        method: "POST",
        body,
      }),
    }),

    // Изменение аватара
    attachAvatar: builder.mutation<any, { sFile: string }>({
      query: (body) => ({
        url: "attach-avatar",
        method: "POST",
        body,
      }),
      async onQueryStarted(credentials, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          console.log(result?.data);
          if (result?.data?.status === StatusCodes.success) {
            const { model } = result.data;
            console.log("new avatar: ", model?.gameProfile?.sImage);
            dispatch(
              updateAvatar({
                sImage: model?.gameProfile?.sImage || "",
              })
            );
          }
        } catch (error) {
          console.log("query error: ", error);
        }
      },
    }),

    // Восстановление пароля
    forgotPassword: builder.mutation<any, { sEmail: string }>({
      query: (body) => ({
        url: "forgot-password",
        method: "POST",
        body,
      }),
    }),

    logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "POST",
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log("error on logout: ", error);
        }
        dispatch(signOut());
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useRefreshTokenMutation,
  useAttachAvatarMutation,
  useForgotPasswordMutation,
  useLogoutMutation,
} = authApi;
