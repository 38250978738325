import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { AccessDenisError, ResponseError, StatusCodes } from "../../@types/api";

import "./AdminError.css";

type Props = {
  error: FetchBaseQueryError | SerializedError | undefined;
  isError: boolean;
};

const AdminError = ({ error, isError }: Props) => {
  if (
    isError &&
    error &&
    "status" in error &&
    error?.status === StatusCodes.accessDenied
  ) {
    return (
      <div className="admin-error">
        <div>{(error?.data as AccessDenisError).data.errors}</div>
      </div>
    );
  }

  if (isError && error && "status" in error) {
    return (
      <div className="admin-error">
        <div>
          {(error?.data as ResponseError)?.errors ||
            "Произошла ошибка при загрузке"}
        </div>
      </div>
    );
  }

  return (
    <div className="admin-error">
      <div>Произошла ошибка при загрузке</div>
    </div>
  );
};

export default AdminError;
