import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import convertBase64 from "../../helpers/toBase64";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  callbackFunction: (base64: string) => void;
  labelTitle?: string;
};

const AuthInputFile = forwardRef(
  (
    { callbackFunction, labelTitle = "Выберите файл", ...props }: Props,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    const [name, setName] = useState("");

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      try {
        setName(e.target.files[0].name);
        const base64 = await convertBase64(e.target.files[0]);
        callbackFunction(base64);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      callbackFunction("");
    }, []);

    return (
      <>
        <label className="login__file" htmlFor="sFile">
          {!!name ? name : labelTitle}
        </label>
        <input ref={ref} {...props} onChange={handleFileChange} />
      </>
    );
  }
);

export default AuthInputFile;
