import React, { useEffect, useRef, useState } from "react";
import Loader from "../Loader";
import tentacleTopLeft from "../../assets/images/tentacleTopLeft.png";
import tentacleBottomMiddle from "../../assets/images/tentacleBottomMiddle.png";
import tentacleBottomLeft from "../../assets/images/tentacleBottomLeft.png";
import tentacleMiddleLeft from "../../assets/images/tentacleMiddleLeft.png";
import tentacleTopMiddle from "../../assets/images/tentacleTopMiddle.png";
import tentacleTopRight from "../../assets/images/tentacleTopRight.png";
import ship from "../../assets/images/decoration/ship.svg";
import car from "../../assets/images/svg/car.svg"

import "./Preloader.css";
import { useAppSelector } from "../../store";

type Props = {};

const Preloader = (props: Props) => {
  const mapType = useAppSelector((state) => state.map.mapType);
  const ref = useRef<HTMLDivElement | null>(null);
  const [isFirstTime, setIsFirstTime] = useState(true);

  useEffect(() => {
    if (ref.current) {
      const elem = ref.current;
      elem.style.animation = "";
      const a = elem.offsetHeight;
      if (isFirstTime) {
        elem.style.animation = "preloader 3s linear";
        setIsFirstTime(false);
      } else {
        elem.style.animation = "preloader .5s linear";
      }
    }
  }, [mapType]);

  return (
    <div className="preloader" ref={ref}>
      {/*<img src={tentacleTopLeft} className="preloader__tentacle1" />
      <img src={tentacleTopRight} className="preloader__tentacle2" />
      <img src={tentacleTopMiddle} className="preloader__tentacle3" />

      <img src={tentacleBottomMiddle} className="preloader__tentacle6" />
      <img src={tentacleBottomLeft} className="preloader__tentacle4" />
      <img src={tentacleMiddleLeft} className="preloader__tentacle5" />*/}
      <img src={car} className="preloader__ship" />
      <div className="preloader__loader">
        <Loader width={"30vh"} height={"30vh"} animationDuration={"5s"} />
      </div>
    </div>
  );
};

export default Preloader;
