import React from "react";
import {
  ProfileAchievement,
  ProfileAchievements,
  ProfileAvatar,
  ProfileInfo,
  ProfileKpi,
  ProfileKpiContainer,
  ProfileMotivation,
  ProfileStat,
  ProfileStatsContainer,
  ProfileTeam,
  ProfileTopBlock,
} from ".";

import salesman from "../../assets/images/salesman.png";
import chat from "../../assets/images/chat.png";
import compass from "../../assets/images/compass.png";
import investigation from "../../assets/images/investigation.png";
import searcher from "../../assets/images/searcher.png";
import avatar from "../../assets/images/avatar.jpeg";
import teamAvatar from "../../assets/images/teamAvatar.jpeg";
import { useAppSelector } from "../../store";

type Props = {
  title: string;
};

const ModalIndividualProfile = ({ title }: Props) => {
  const user = useAppSelector((state) => state.modal.selectedModalData);

  return (
    <>
      <h3 className="modal-title">{title}</h3>
      <div className="modal-profile">
        <ProfileTopBlock>
          <ProfileAvatar
            sImage={user?.sImage}
            sFirstName={user?.sFirstName}
            sLastName={user?.sLastName}
            sPosition={user?.sPosition}
            isInputDisabled
          />
          <ProfileInfo>
            <ProfileTeam sImage={user?.team.sImage} sName={user?.team?.sName}>
              <ProfileMotivation
                name={user?.sParameter4?.name}
                sum={user?.sParameter4?.sum}
                month={user?.sParameter4?.month}
              />
            </ProfileTeam>

            <ProfileKpiContainer>
              <ProfileKpi kpi={user?.sParameter1} />
              <ProfileKpi kpi={user?.sParameter2} />
              <ProfileKpi kpi={user?.sParameter3} />
            </ProfileKpiContainer>

            <ProfileStatsContainer>
              <ProfileStat count={user?.iStepCount || 0} name="шагов" />
              <ProfileStat count={user?.iFragmentCount || 0} name="фрагмента" />
              <ProfileStat count={user?.iPositionCount || 0} name="карт" />
              <ProfileStat count={user?.iPositiveCount || 0} name="баффа" />
            </ProfileStatsContainer>
          </ProfileInfo>
        </ProfileTopBlock>
        <ProfileAchievements>
          {!!user?.achievements &&
            Object.values(user?.achievements).map((achievement) => {
              return (
                <ProfileAchievement
                  key={achievement.id}
                  imgSrc={achievement.sImage}
                  imgAlt={achievement.sTitle}
                  title={achievement.sTitle}
                  description={achievement.sDescription}
                  isOpened={achievement.open}
                />
              );
            })}
          {/* <ProfileAchievement
            imgSrc={salesman}
            imgAlt="продавец"
            title="Гуру продаж"
            description="Два и более раз выполнял план продаж"
          />
          <ProfileAchievement
            imgSrc={chat}
            imgAlt="чат"
            title="коммуникатор"
            description="более 30 сообщений в командном чате"
          />
          <ProfileAchievement
            imgSrc={compass}
            imgAlt="компас"
            title="Навигатор"
            description="более 5 раз капитан выбирал ваше направление"
          />
          <ProfileAchievement
            imgSrc={investigation}
            imgAlt="лупа"
            title="Сыщик"
            description="нашел более 3 фрагментов"
          />
          <ProfileAchievement
            imgSrc={searcher}
            imgAlt="поиск"
            title="Кадровик"
            description="нашел более 3 карт должностей"
          /> */}
        </ProfileAchievements>
      </div>
    </>
  );
};

export default ModalIndividualProfile;
