import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAppSelector } from "../../store";
import { setCredentials } from "../../store/slices/modal/authSlice";
import Login from "../Login";
import Main from "../Main";
import Start from "../Start";
import AdminGames from "../AdminGames";
import AdminHeader from "../../components/AdminHeader";
import AdminMap from "../AdminMap";
import AdminMapIsland from "../AdminMapIsland";
import { UserRoles } from "../../@types/api";

const App = () => {
  const dispatch = useDispatch();
  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    const checkToken = async () => {
      const jwt = localStorage.getItem("jwt");
      const user = localStorage.getItem("user");

      dispatch(
        setCredentials({
          token: jwt || null,
          user: user ? JSON.parse(user) : null,
        })
      );
    };

    checkToken();
  }, [dispatch]);

  if (!auth.isInitialized) {
    return null;
  }

  if (!auth.token) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/admin" element={<AdminLogin />} /> */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  if (auth.user?.sRole === UserRoles.admin) {
    return (
      <>
        <AdminHeader />
        <Routes>
          <Route path="/games" element={<AdminGames />} />
          <Route path="/map/:id" element={<AdminMap />} />
          <Route path="/map/:id/:islandId" element={<AdminMapIsland />} />
          {/* <Route path="/" element={<Main />} /> */}
          <Route path="*" element={<Navigate to="/games" replace />} />
        </Routes>
      </>
    );
  } else {
    return (
      <>
        <Routes>
          <Route path="/game/start" element={<Start />} />
          <Route path="/game/pirate" element={<Main />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="*" element={<Navigate to="/game/start" replace />} />
        </Routes>
      </>
    );
  }
};

export default App;
