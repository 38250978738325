import React, { useEffect, useState } from "react";
import { FreePosition } from "../../@types/teams";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  useAppointToPositionMutation,
  useFreePositionsQuery,
} from "../../store/services/teams";
import { selectCurrentUser } from "../../store/slices/modal/authSlice";
import { closeSelectModal } from "../../store/slices/modal/modalSlice";
import Loader from "../Loader";
import Error from "../Error";
import Dropdown from "../Dropdown";
import Button from "../Button";

type Props = {
  type?: "appoint" | "takeOff" | "delete";
};

const AppointToPosition = ({ type }: Props) => {
  const dispatch = useAppDispatch();

  const userProfile = useAppSelector(selectCurrentUser);
  const teamUser = useAppSelector((state) => state.modal.positionModalUserData);

  const { data, isFetching, isError, error, isSuccess, refetch } =
    useFreePositionsQuery({
      iGameId: Number(userProfile?.iGameId),
      iTeamId: Number(userProfile?.iTeamId),
    });
  const [appointToPosition, appointToPositionState] =
    useAppointToPositionMutation();

  const [selectedOption, setSelectedOption] = useState<FreePosition | null>(
    null
  );

  const handleSelectedOption = (value: FreePosition) => {
    setSelectedOption(value);
  };

  const onSaveHandler = () => {
    if (!selectedOption) return;
    if (type === "appoint") {
      appointToPosition({
        iGameId: Number(teamUser?.iGameId),
        iGameProfileId: Number(teamUser?.id),
        iPositionId: selectedOption.id,
      });
    }
  };

  useEffect(() => {
    if (appointToPositionState.isSuccess) {
      dispatch(closeSelectModal());
    }
  }, [appointToPositionState.isSuccess]);

  useEffect(() => {
    if (data) {
      setSelectedOption(data[0]);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  if (isFetching || appointToPositionState.isLoading) {
    return <Loader />;
  }

  if (isError && error) {
    return <Error error={error} isError={isError} />;
  }

  if (appointToPositionState.isError && appointToPositionState.error) {
    return (
      <Error
        error={appointToPositionState.error}
        isError={appointToPositionState.isError}
      />
    );
  }

  return (
    <>
      {!!data ? (
        <>
          <Dropdown
            data={data}
            selectedOption={selectedOption}
            handleSelectedOption={handleSelectedOption}
          />
          <Button
            disabled={!selectedOption}
            text="сохранить"
            className="bottom-menu__button_normal"
            onClick={onSaveHandler}
          />
        </>
      ) : (
        <div>Не удалось загрузить данные</div>
      )}
    </>
  );
};

export default AppointToPosition;
