import React, { useEffect } from "react";
import { useAppSelector } from "../../store";
import {
  useTeamObjectsQuery,
  useTeamPositionsQuery,
} from "../../store/services/teams";
import Loader from "../Loader";
import Error from "../Error";
import stringToColour from "../../helpers/stringToColour";
import { TeamPosition } from "../../@types/teams";

const ModalCards = ({
  // title,
  handlePressCard,
}: {
  // title: string;
  handlePressCard: (data: TeamPosition) => void;
}) => {
  const userProfile = useAppSelector((state) => state.auth.user);

  const { data, isFetching, isError, error, refetch } = useTeamPositionsQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  });

  useEffect(() => {
    refetch();
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (isError && error) {
    return <Error error={error} isError={isError} />;
  }

  return (
    <>
      <h3 className="modal-title">Должности</h3>
      <div className="cards">
        {data?.data.model.map((item) => {
          return (
            <div
              key={item.id}
              className="card"
              onClick={() => handlePressCard(item)}
            >
              <img className="card-image" src={item.sImage} alt={item.sTitle} />
              {!!item.bActivePosition || !!item.bFreePosition ? (
                <>
                  <p className="card-job">{item?.sTitle}</p>
                  <div className="card-name__block">
                    {!!item.profiles.length ? (
                      <>
                        {item.profiles.map((profile) => {
                          return (
                            <div style={{ display: "flex", paddingBottom: 4 }}>
                              <span
                                className="card-name__icon"
                                style={{
                                  background: stringToColour(
                                    `${profile?.sFirstName} ${profile?.sLastName}`
                                  ),
                                }}
                              >
                                {profile?.sLastName[0]}
                              </span>
                              <p className="card-name">
                                {profile?.sFirstName} {profile?.sLastName}
                              </p>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <p className="card-name__open">Свободно</p>
                    )}
                    {/* {!!item.profiles.length &&
                      item.profiles.map((profile) => {
                        return (
                          <div style={{ display: "flex", paddingBottom: 4 }}>
                            <span
                              className="card-name__icon"
                              style={{
                                background: stringToColour(
                                  `${profile?.sFirstName} ${profile?.sLastName}`
                                ),
                              }}
                            >
                              {profile?.sLastName[0]}
                            </span>
                            <p className="card-name">
                              {profile?.sFirstName} {profile?.sLastName}
                            </p>
                          </div>
                        );
                      })} */}
                  </div>
                </>
              ) : (
                <>
                  <p className="card-job" style={{ opacity: 0.3 }}>
                    {item.sTitle}
                  </p>
                  <div className="card-name__block">
                    <p className="card-name__404">Не найдено</p>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ModalCards;
