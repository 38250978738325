import React, { Fragment } from "react";

import "../Modal/Modal.css";
import "./ModalStatContent.css";
import stepArrow from "../../assets/images/svg/stepArrow.svg";
import { StatHistory } from "../../@types/teams";

export const StatCardItem = ({
  count,
  title,
}: {
  count?: number;
  title: string;
}) => {
  return (
    <div className="statistics-cards__item">
      <p className="statistics-cards__count">{count || 0}</p>
      <p className="statistics-cards__text">{title}</p>
    </div>
  );
};

export const StatTitle = ({ title }: { title: string }) => {
  return <h3 className="modal-title">{title}</h3>;
};

export const StatSubtitle = ({ title }: { title: string }) => {
  return <h4 className="statistics-title">{title}</h4>;
};

export const StatSteps = ({
  history,
}: {
  history: StatHistory[] | undefined;
}) => {
  if (!history) return null;

  return (
    <div
      className="statistics-steps"
      style={{
        overflow: "auto",
      }}
      // style={
      // data.steps.length >= 4
      //   ? { overflowY: "scroll" }
      //   : { overflowY: "auto" }
      // }
    >
      {history?.map((item, index) => {
        return (
          <div key={index} className="statistics-steps__item">
            <div className="statistics-steps__number">
              <span className="statistics-steps__number-count">
                {item.sTitle}
              </span>
              <img
                className="statistics-steps__image"
                src={item.sImage}
                alt="Клетка"
              />
            </div>
            {item.stat.map((statEl, index) => (
              <Fragment key={index}>
                <img
                  className="statistics-steps__arrow"
                  src={stepArrow}
                  alt="Стрелка"
                />
                <p className="statistics-steps__text">{statEl.text || ""}</p>
              </Fragment>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export { default as ModalStatIsland } from "./ModalStatIsland";
export { default as ModalStatSea } from "./ModalStatSea";
