import React, { useEffect } from "react";
import {
  MapGameRowIsland,
  MapRowGameValue,
  MapRowMainValue,
  MapRowValue,
  MapRowWithFragment,
} from "../../@types/map";
import SeaIsland1 from "../../assets/images/SeaIsland1.png";
import ship from "../../assets/images/decoration/ship.svg";

import "./MapEvent.css";
import { useAppDispatch } from "../../store";
import { updateActiveIslandId } from "../../store/slices/mapSlice";

const MapShip = ({ iIslandId }: { iIslandId: string }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Number(iIslandId)) {
      dispatch(updateActiveIslandId(Number(iIslandId)));
    } else {
      dispatch(updateActiveIslandId(undefined));
    }
  }, [iIslandId]);

  return <MapEventDecor image={ship} id={"0"} />;
};

// iCellType:  1 - море
//             2 - земля
//             3 - остров
//             4 - бонус - позитивные события
//             5 - форс мажор - негативные события
//             6 - звание - карточки должностей
//             7 - фрагмент - части финальной карты

type MapEventCardType = {
  type: string;
  image?: string | null;
  title?: string;
};

const MapEventCard = ({ type, image, title }: MapEventCardType) => {
  return (
    <div className={`map-event map-event_${type}`}>
      <img
        className={`map-event__image map-event__image_${type}`}
        src={image || ""}
        alt={title || ""}
      />
      <p className={`map-event__title map-event__title_${type}`}>
        {title || ""}
      </p>
    </div>
  );
};

type MapEventDecorType = {
  image: string;
  id: string;
};

const MapEventDecor = ({ image, id }: MapEventDecorType) => {
  return (
    <img
      style={{ transform: `rotate(${Number(id) % 360 || 0}deg)` }}
      className="cell__decoration"
      src={image}
      alt=""
    />
  );
};

type Props = {
  element:
    | MapRowMainValue
    | MapRowWithFragment
    | MapRowGameValue
    | MapGameRowIsland;
};

const MapEvent = ({ element }: Props) => {
  // if (element?.iCurrentPosition) {
  //   return <MapShip iIslandId={element.iIslandId} />;
  //   // return <MapEventDecor image={ship} id={"0"} />;
  // }
  /*if (element?.sDecorationImage) {
    return <MapEventDecor image={element.sDecorationImage} id={element.id} />;
  }*/

  /*if (
    !!element?.iIslandId &&
    element?.iIslandId !== "0" &&
    element.iCellType === "3"
  ) {
    return <MapEventDecor image={SeaIsland1} id={element.id} />;
  }*/

  if (element?.fragment) {
    return (
      <MapEventCard
        type={element.iCellType}
        image={element?.fragment?.sImage}
        title={""}
      />
    );
  }

  if (["4", "5", "6"].some((el) => el === element.iCellType)) {
    return (
      <MapEventCard
        type={element.iCellType}
        image={element?.event?.sImage}
        title={element.event?.sTitle}
      />
    );
  }

  return null;
};

export default MapEvent;
