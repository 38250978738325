import "../../components/Modal/Modal.css";
import Topbar from "../../components/Topbar";
import Map from "../../components/Map";
import BottomMenu from "../../components/BottomMenu";
import ModalSelect from "../../components/ModalSelect";
import ModalContent from "../../components/ModalContent";
import Preloader from "../../components/Preloader";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { teamsApi } from "../../store/services/teams";
import {
  // startWSservice,
  // stopWSservice,
  updateLandInfo,
  updateSeaInfo,
} from "../../store/slices/mapSlice";
import {
  GameMapAllCellsResponseSuccess,
  MapGameIslandCellsResponseSuccess,
} from "../../@types/map";
import { sendWS, startWS, stopWS } from "../../store/slices/wsSlice";
// import WSservice from "../../store/services/WSservice";

type Props = {};

const Main = (props: Props) => {
  const wsState = useAppSelector((state) => state.ws.status);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(startWS());
    return () => {
      dispatch(stopWS());
    };
  }, []);

  useEffect(() => {
    if (wsState === "ready") {
      dispatch(sendWS({ action: "quizState" }));
      dispatch(sendWS({ action: "unreadMessages" }));
    }
  }, [wsState]);

  return (
    <div>
      <Topbar />
      <Map />
      <BottomMenu />
      <ModalContent />
      <ModalSelect />
      <Preloader />
    </div>
  );
};

export default Main;
