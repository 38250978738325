import React from "react";

import chat from "../../assets/images/svg/chat.svg";
import { useAppDispatch, useAppSelector } from "../../store";
import { setInitialModal } from "../../store/slices/modal/modalSlice";
import exclamation from "../../assets/images/svg/exclamation.svg";

import "./ChatButton.css";

type Props = {};

function ChatButton({}: Props) {
  const dispatch = useAppDispatch();
  const isUnredMessages = useAppSelector(
    (state) => state.chat.isUnreadMessages
  );

  return (
    <div
      className="chat-button"
      onClick={() => {
        dispatch(setInitialModal("chat"));
      }}
    >
      <img src={chat} alt="Чат" className="chat-button__icon" />
      <p className="chat-button__title">чат</p>
      {!!isUnredMessages && (
        <img
          className="chat-button__exclamation"
          src={exclamation}
          alt="уведомление"
        />
      )}
    </div>
  );
}

export default ChatButton;
