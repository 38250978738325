import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useContext,
  useMemo,
} from "react";
import {
  GameMapAllCellsResponseSuccess,
  MapRow,
  MapRowGameValue,
} from "../../@types/map";
import { CellForQuiz } from "../../@types/teams";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  useAllCellsQuery,
  useCellForQuizQuery,
  useGoToCellMutation,
} from "../../store/services/teams";
import AdminColumn from "../AdminColumn";
import AdminDraggableMap from "../AdminDraggableMap";
import AdminError from "../AdminError";
import AdminRow from "../AdminRow";
import Loader from "../Loader";
import MapCell from "../MapCell";
import MapEvent from "../MapEvent";
import seaOpen from "../../assets/images/seaOpen.png";
import {
  updateActiveIslandCell,
  updateActiveIslandId,
} from "../../store/slices/mapSlice";
import ship from "../../assets/images/decoration/ship.svg";
import { handleSendWS, sendWS } from "../../store/slices/wsSlice";
import { selectCurrentUser } from "../../store/slices/modal/authSlice";

const MapContext = createContext<any>("");

const Column = ({
  columnElement,
  isMovePossible,
}: {
  columnElement: MapRowGameValue;
  isMovePossible: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { contextValues, setContextValues } = useContext(MapContext);
  const ref = useRef<HTMLDivElement | null>(null);
  const userProfile = useAppSelector((state) => state.auth.user);
  // const [goToCell, { data, isLoading, error }] = useGoToCellMutation();

  const handleCell = async () => {
    if (isMovePossible) {
      try {
        handleSendWS({ action: "goToCell", iCellId: Number(columnElement.id) });
        // const result = await goToCell({
        //   iGameId: Number(userProfile?.iGameId),
        //   iTeamId: Number(userProfile?.iTeamId),
        //   iCellId: Number(columnElement.id),
        // });
        // // @ts-ignore
        // console.log("result: ", result, result?.error?.data?.errors);
        // // @ts-ignore
        // if (result?.error) return;

        // contextValues.refetchCellsForQuiz();

        // contextValues.handleNewShipCoordinates(ref?.current?.offsetLeft);

        // setContextValues({ ...contextValues, isWaitingNewShipYCoord: true });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (columnElement.iCurrentPosition === 0) return;

    dispatch(updateActiveIslandCell(columnElement));

    if (Number(columnElement?.iIslandId)) {
      dispatch(updateActiveIslandId(Number(columnElement?.iIslandId)));
    } else {
      dispatch(updateActiveIslandId(undefined));
    }
  }, [columnElement]);

  useEffect(() => {
    if (ref?.current && columnElement.iCurrentPosition !== 0) {
      contextValues.handleChangeCoordinates(ref?.current?.offsetLeft);
    }
  }, [ref]);

  // Получени новых координатов для корабля
  useEffect(() => {
    if (ref?.current && columnElement.iCurrentPosition !== 0) {
      // contextValues.handleChangeCoordinates(ref?.current?.offsetLeft);
      contextValues.handleNewShipCoordinates(ref?.current?.offsetLeft);
    }
  }, [ref, columnElement]);

  return (
    <AdminColumn ref={ref} sTitle={columnElement.sTitle}>
      <MapEvent element={columnElement} />
      <MapCell
        // isActive={activeCell?.iCellId === Number(columnElement.id)}
        image={columnElement.sImage || seaOpen}
        onClick={handleCell}
        isActiveStroke={isMovePossible}
      />
    </AdminColumn>
  );
};

const Row = ({
  mapRowElements,
  rowIndex,
  quizCell,
}: {
  mapRowElements: MapRow<MapRowGameValue>;
  rowIndex: number;
  quizCell: CellForQuiz[] | undefined;
}) => {
  const { contextValues, setContextValues } = useContext(MapContext);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      ref?.current &&
      Object.values(mapRowElements).some((el) => el.iCurrentPosition !== 0)
    ) {
      contextValues.handleChangeCoordinates(
        undefined,
        ref?.current?.offsetTop + 1
      );
    }
  }, [ref]);

  // Получение новых данных для корабля
  useEffect(() => {
    if (
      // contextValues.isWaitingNewShipYCoord &&
      ref?.current &&
      Object.values(mapRowElements).some((el) => el.iCurrentPosition === 1)
    ) {
      contextValues.handleNewShipCoordinates(
        undefined,
        ref?.current?.offsetTop + 1
      );

      // setContextValues({ ...contextValues, isWaitingNewShipYCoord: false });
    }
  }, [mapRowElements, ref]);

  return (
    <AdminRow ref={ref} rowIndex={rowIndex}>
      {Object.values(mapRowElements).map((columnElement, index) => {
        return (
          <Column
            key={columnElement.id}
            columnElement={columnElement}
            isMovePossible={
              quizCell?.some(
                (cell) =>
                  typeof cell !== "string" &&
                  cell.id === Number(columnElement.id)
              ) || false
            }
          />
        );
      })}
    </AdminRow>
  );
};

type ShipCoordinatesType =
  | {
      x: number;
      y: number;
    }
  | undefined;

type Props = {};

const Sea = (props: Props) => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectCurrentUser);
  const isOnTheIsland = useAppSelector((state) => !!state.map.activeIslandId);
  const seaData = useAppSelector((state) => state.map.sea);
  const wsStatus = useAppSelector((state) => state.ws.status);

  const [shipCoordinates, setShipCoordinates] =
    useState<ShipCoordinatesType>(undefined);

  const [oldShipCoordinates, setOldShipCoordinates] =
    useState<ShipCoordinatesType>(undefined);

  const [isAnimating, setIsAnimating] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const { data, isLoading, error, isError, refetch } = useAllCellsQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  });

  const cellForQuery = useCellForQuizQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  });

  const handleChangeCoordinates = (x?: number, y?: number) => {
    setShipCoordinates((shipCoordinates) => {
      return {
        x: (typeof x === "number" && -x) || shipCoordinates?.x || 0,
        y: (typeof y === "number" && -y) || shipCoordinates?.y || 0,
      };
    });
  };

  const handleNewShipCoordinates = (x?: number, y?: number) => {
    setOldShipCoordinates((oldShipCoordinates) => {
      return {
        x: (typeof x === "number" && x) || oldShipCoordinates?.x || 0,
        y: (typeof y === "number" && y) || oldShipCoordinates?.y || 0,
      };
    });
  };

  const renderRow = (data: GameMapAllCellsResponseSuccess | undefined) => {
    if (!data) return null;
    return Object.values(data.data.model.aCells).map(
      (mapRowElements, index) => {
        return (
          <Row
            key={index}
            mapRowElements={mapRowElements}
            rowIndex={index}
            quizCell={
              !!cellForQuery.data
                ? (Object.values(cellForQuery?.data?.data.model).filter(
                    (quiz) => typeof quiz !== "string"
                  ) as CellForQuiz[])
                : undefined
            }
          />
        );
      }
    );
  };

  const [contextValues, setContextValues] = useState({
    refetchCellsForQuiz: cellForQuery.refetch,
    handleChangeCoordinates: handleChangeCoordinates,
    handleNewShipCoordinates: handleNewShipCoordinates,
    isWaitingNewShipYCoord: false,
  });

  const value = useMemo(
    () => ({ contextValues, setContextValues }),
    [contextValues]
  );

  // чтобы не срабатывала анимация пермещения корабля с координаты [0,0]
  // при загрузке карты
  useEffect(() => {
    if (seaData || data) {
      setTimeout(() => {
        setIsMapLoaded(true);
      }, 100);
    }
  }, [seaData, data]);

  useEffect(() => {
    if (seaData) {
      setIsAnimating(true);

      setTimeout(() => {
        setIsAnimating(false);
      }, 2000);
    }
  }, [seaData]);

  useEffect(() => {
    if (shipCoordinates?.y && shipCoordinates?.x) {
      setOldShipCoordinates({
        x: Math.abs(shipCoordinates?.x),
        y: Math.abs(shipCoordinates?.y),
      });

      setTimeout(() => {
        setShipCoordinates(undefined);
      }, 0);
    }
  }, [shipCoordinates?.x, shipCoordinates?.y]);

  useEffect(() => {
    refetch();
    cellForQuery.refetch();
  }, []);

  useEffect(() => {
    cellForQuery.refetch();
  }, [seaData]);

  // useEffect(() => {
  //   if (wsStatus === "ready") {
  //     // handleSendWS({ action: "globalCells" });
  //     refetch();
  //     cellForQuery.refetch();
  //   }
  // }, [wsStatus]);

  if (isLoading) {
    return (
      <div className="admin-games-loader">
        <Loader width="20%" height="20%" />
      </div>
    );
  }

  if (error) {
    return <AdminError error={error} isError={isError} />;
  }

  return (
    <MapContext.Provider value={value}>
      <AdminDraggableMap
        key="sea"
        mapType="sea"
        position={
          shipCoordinates
            ? {
                x: shipCoordinates?.x + window.innerWidth / 2,
                y: shipCoordinates?.y + window.innerHeight / 2,
              }
            : undefined
        }
      >
        <div
          key="ship"
          style={{
            transform: `translate(${
              (oldShipCoordinates?.x || 0) - (isOnTheIsland ? 40 : 0)
            }px, ${
              (oldShipCoordinates?.y || 0) + (isOnTheIsland ? 20 : 0)
            }px) rotate(${isOnTheIsland ? -5 : 0}deg) scale(${
              isOnTheIsland ? 0.8 : 1
            })`,
          }}
          className={`ship-container ${
            isMapLoaded ? "ship-container_transition" : ""
          }`}
        >
          <img
            className={`ship ${isAnimating ? "ship_animate" : ""}`}
            src={ship}
            alt=""
          />
        </div>
        {renderRow(seaData || data)}
        {/* {!!data?.data?.model?.aCells &&
          Object.values(data?.data?.model?.aCells).map(
            (mapRowElements, index) => {
              return (
                <Row
                  key={index}
                  mapRowElements={mapRowElements}
                  rowIndex={index}
                  quizCell={
                    !!cellForQuery.data
                      ? (Object.values(cellForQuery?.data?.data.model).filter(
                          (quiz) => typeof quiz !== "string"
                        ) as CellForQuiz[])
                      : undefined
                  }
                />
              );
            }
          )} */}
      </AdminDraggableMap>
    </MapContext.Provider>
  );
};

export default Sea;
