import React, { useEffect, useState } from "react";
import { TeamUser } from "../../@types/teams";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  useAllQuery,
  useRemoveFromTeamMutation,
} from "../../store/services/teams";
import { selectCurrentUser } from "../../store/slices/modal/authSlice";
import Button from "../Button";
import { DropdownUser } from "../Dropdown";
import Loader from "../Loader";
import Error from "../Error";
import { closeSelectModal } from "../../store/slices/modal/modalSlice";

type Props = {};

const RemoveFromTeam = (props: Props) => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectCurrentUser);

  const [removeFromTeam, removeFromTeamState] = useRemoveFromTeamMutation();
  const { data, isFetching, isError, error, refetch, isSuccess } = useAllQuery({
    iGameId: Number(userProfile?.iGameId),
  });

  const [profiles, setProfiles] = useState<TeamUser[]>([]);
  const [selectedOption, setSelectedOption] = useState<TeamUser | null>(null);

  const onSaveHandler = () => {
    if (!selectedOption) return;
    removeFromTeam({
      iGameId: Number(userProfile?.iGameId),
      iGameProfileId: Number(selectedOption?.id),
    });
  };

  const handleSelectedOption = (value: TeamUser) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    if (data?.data?.model?.team) {
      const filteredProfiles = data.data.model.team.filter(
        (team) => team.sPosition !== "Капитан"
      );

      setProfiles(filteredProfiles);
    }
  }, [data]);

  useEffect(() => {
    if (profiles?.length) {
      setSelectedOption(profiles[0]);
    } else {
      setSelectedOption(null);
    }
  }, [profiles]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (removeFromTeamState.isSuccess) {
      refetch();
      dispatch(closeSelectModal());
    }
  }, [removeFromTeamState.isSuccess]);

  if (isFetching || removeFromTeamState.isLoading) {
    return <Loader />;
  }

  if (isError && error) {
    return <Error error={error} isError={isError} />;
  }

  if (removeFromTeamState.isError && removeFromTeamState.error) {
    return (
      <Error
        error={removeFromTeamState.error}
        isError={removeFromTeamState.isError}
      />
    );
  }

  return (
    <>
      {!!data ? (
        <>
          <DropdownUser<TeamUser>
            data={profiles}
            selectedOption={selectedOption}
            handleSelectedOption={handleSelectedOption}
          />
          <Button
            disabled={!selectedOption}
            text="сохранить"
            className="bottom-menu__button_normal"
            onClick={onSaveHandler}
          />
        </>
      ) : (
        <div>Не удалось загрузить данные</div>
      )}
    </>
  );
};

export default RemoveFromTeam;
