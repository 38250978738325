import { useNavigate } from "react-router-dom";

import Button from "../Button";

import exclamation from "../../assets/images/svg/exclamation.svg";
import { useAppDispatch, useAppSelector } from "../../store";
import { setInitialModal } from "../../store/slices/modal/modalSlice";

import "./BottomMenu.css";
import { toggleMapType } from "../../store/slices/mapSlice";
import {
  useGoToIslandMutation,
  useHeaderStatisticQuery,
  useLeaveIslandMutation,
} from "../../store/services/teams";
import useIsCaptain from "../../hooks/useIsCaptain";
import { useEffect, useState } from "react";
import Minimap from "./Minimap";
import { selectCurrentUser } from "../../store/slices/modal/authSlice";

const GlobalError = () => {
  const error = useAppSelector((state) => state.map.globalError);
  if (error) {
    return (
      <div
        style={{
          position: "absolute",
          bottom: 85,
          left: 0,
          right: 0,
          height: 30,
          backgroundColor: "#fff0e2",
          color: "#961e02",
          textAlign: "center",
          fontSize: 18,
        }}
      >
        {error}
      </div>
    );
  }

  return null;
};

const ToIslandButton = () => {
  const dispatch = useAppDispatch();
  const isOnTheIsland = useAppSelector((state) => !!state.map.activeIslandId);
  const activeIslandCell = useAppSelector(
    (state) => state.map.activeIslandCell
  );
  const mapType = useAppSelector((state) => state.map.mapType);
  const userProfile = useAppSelector((state) => state.auth.user);

  const { isCaptain } = useIsCaptain();
  const [goToIsland, goToIslandState] = useGoToIslandMutation();
  const [leaveIsland, leaveIslandState] = useLeaveIslandMutation();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleChangeMapType = async () => {
    if (!userProfile?.iGameId && !userProfile?.iTeamId) return;
    const { iGameId, iTeamId } = userProfile;

    if (!isCaptain) {
      dispatch(toggleMapType());
      return;
    }

    if (mapType === "sea") {
      //2 - означает, что корабль с пользователями уже высадился на остров
      if (activeIslandCell?.iCurrentPosition === 2) {
        dispatch(toggleMapType());
        return;
      }

      try {
        const result = await goToIsland({
          iGameId: Number(iGameId),
          iTeamId: Number(iTeamId),
        });

        //@ts-ignore
        if (result?.error) return;

        dispatch(toggleMapType());
      } catch (error) {
        console.log("error on go to island: ", error);
      }
    } else {
      try {
        const result = await leaveIsland({
          iGameId: Number(iGameId),
          iTeamId: Number(iTeamId),
        });

        //@ts-ignore
        if (result?.error) return;

        dispatch(toggleMapType());
      } catch (error) {
        console.log("error on go to island: ", error);
      }
    }

    // if(mapType === "sea") {
    //   goToIsland({

    //   })
    // }
  };

  useEffect(() => {
    if (!isOnTheIsland) {
      setIsButtonDisabled(true);
      // } else if (activeIslandCell?.iCurrentPosition !== 2 && !isCaptain) {
      //   setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [isOnTheIsland]);

  return (
    <div>
      <Button
        disabled={isButtonDisabled}
        text={`${mapType === "sea" ? "В город!" : "В лес!"}`}
        // text="На остров!"
        className="bottom-menu__button_big bottom-menu__button_gradient-red"
        onClick={handleChangeMapType}
      />
    </div>
  );
};

type Props = {};

const BottomMenu = ({}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const quizState = useAppSelector((state) => state.map.quizState);
  const userProfile = useAppSelector(selectCurrentUser);

  const [isPollBlocked, setIsPollBlocked] = useState(false);

  const { data } = useHeaderStatisticQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  });

  const handleOpenModal = (value: string) => {
    dispatch(setInitialModal(value));
  };

  useEffect(() => {
    if (data && data?.iTotalFragmentCount === data?.iFragmentCount) {
      setIsPollBlocked(false); //потом может изменится на true
    } else {
      setIsPollBlocked(false);
    }
  }, [data]);

  return (
    <div className="bottom-menu">
      <GlobalError />
      <ToIslandButton />
      <ul className="bottom-menu__list">
        <li className="bottom-menu__item-team">
          <Button
            text="Команда"
            className="bottom-menu__button_normal"
            onClick={() => handleOpenModal("team")}
          />
        </li>
        <li className="bottom-menu__item">
          <Button
            disabled={isPollBlocked}
            text="Опрос"
            className={`bottom-menu__button_normal ${
              quizState ? "bottom-menu__button_gradient-red" : ""
            }`}
            onClick={() => handleOpenModal("interview")}
          >
            {!!quizState && !isPollBlocked && (
              <img src={exclamation} className="bottom-menu__exclamation" />
            )}
          </Button>
        </li>
        <li className="bottom-menu__item">
          <Button
            text="рейтинги"
            className="bottom-menu__button_normal"
            onClick={() => handleOpenModal("ratings")}
          />
        </li>
        <li className="bottom-menu__item">
          <Button
            text="меню"
            className="bottom-menu__button_normal"
            onClick={() => navigate("/start")}
          />
        </li>
      </ul>
      <Minimap />
      {/* <div className="bottom-menu__minimap">
        {[...Array(11)].map((el, row) => {
          return (
            <div
              key={row}
              style={
                row % 2
                  ? {
                      width: 155,
                      height: 17,
                      pointerEvents: "none",
                    }
                  : {
                      position: "relative",
                      left: 9,

                      width: 155,
                      height: 17,

                      marginTop: -4,
                      marginBottom: -4,
                      pointerEvents: "none",
                    }
              }
            >
              <>
                {[...Array(11)].map((elem, col) =>
                  arrMapWaterOpen.includes(11 * row + col + 1) ? (
                    <SeaOpen
                      key={col}
                      width={14}
                      height={14}
                      onClick={() => console.log(11 * row + col + 1)}
                    />
                  ) : (
                    <SeaFog
                      key={col}
                      width={14}
                      height={14}
                      onClick={() => console.log(11 * row + col + 1)}
                    />
                  )
                )}
              </>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default BottomMenu;
