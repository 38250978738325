import React from "react";
import { TeamRating } from "../../@types/teams";

import FlagImage from "../../assets/images/flag.png";

const RatingCrewContent = ({
  data,
  index,
}: {
  data: TeamRating;
  index: number;
}) => {
  return (
    <tr className="user-item" style={{ paddingLeft: 60 }}>
      {Number(data.iFragmentCount) === 15 && (
        <td className="flag-image">
          <img src={FlagImage} alt="Флаг" />
        </td>
      )}
      <td className="user-item__block">
        <span className="user-item__crew-icon">{index + 1}</span>
        <div>
          <p className="user-item__name">{data?.sName || ""}</p>
          <p className="user-item__job">команда</p>
        </div>
      </td>
      <td className="user-item__param">
        <span className="user-item__param-count user-item__param-count_bold">
          {data?.iStepCount || 0}
        </span>
        <p className="user-item__param-title user-item__param-title_bold">
          шагов
        </p>
      </td>
      <td className="user-item__param">
        <span className="user-item__param-count">{data?.iPercent || 0}%</span>
        <p className="user-item__param-title">открытие</p>
      </td>
      <td className="user-item__param">
        <span className="user-item__param-count">
          {data?.iFragmentCount || 0}
        </span>
        <p className="user-item__param-title">фрагменты</p>
      </td>
      <td className="user-item__param">
        <span className="user-item__param-count">
          {data?.iPositionCount || 0}
        </span>
        <p className="user-item__param-title">карты</p>
      </td>
      <td className="user-item__param">
        <span className="user-item__param-count">
          {data?.iPositiveCount || 0}
        </span>
        <p className="user-item__param-title">баффы</p>
      </td>
    </tr>
  );
};

export default RatingCrewContent;
