import logo from "../../assets/images/svg/logo.svg";
import wheel from "../../assets/images/svg/wheel.svg";

import "./Login.css";

import AuthForm from "../../components/AuthForm";

type Props = {};

const Login = ({}: Props) => {
  return (
    <div className="wrapper">
      <header className="header">
        <img src={logo} className="header__logo" />
      </header>
      <main className="info">
        {/* подробная информация */}
        <div className="info__container info__container_left">
          <h1 className="info__title">Геймификация для малого бизнеса</h1>
          <h2 className="info__subtitle">Пираты</h2>
          <h2 className="info__subtitle">активных</h2>
          <h2 className="info__subtitle">продаж</h2>
          <p className="info__about">
            Отправляйся с коллегами в виртуальное путешествие под пиратским
            флагом! Исследуй острова, собери все фрагменты карты и найди путь к
            Источнику Вечного дохода
          </p>
        </div>
        {/* поля аутентификации */}
        <div className="info__container info__container_right">
          <AuthForm />
        </div>
        <img src={wheel} className="wheel" />
      </main>
    </div>
  );
};

export default Login;
