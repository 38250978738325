import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { IndividualRating, TeamUser } from "../../../@types/teams";

const initialState = {
  showModal: false,
  initialModal: "",
  selectModal: false,
  initialSelect: "",
  selectedModalData: null as IndividualRating | null,
  notification: "",
  positionModalUserData: null as TeamUser | null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setInitialModal: (state, action: PayloadAction<string>) => {
      state.initialModal = action.payload;
      state.showModal = true;
    },
    closeInitialModal: (state) => {
      state.showModal = false;
      state.initialModal = "";
    },

    setSelectModal: (state, action: PayloadAction<string>) => {
      state.initialSelect = action.payload;
      state.selectModal = true;
    },
    closeSelectModal: (state) => {
      state.selectModal = false;
      state.initialSelect = "";
    },
    updateSelectedModalData: (
      state,
      { payload }: PayloadAction<typeof initialState.selectedModalData>
    ) => {
      state.selectedModalData = payload;
    },
    updateAndShowNotification: (state, { payload }: PayloadAction<string>) => {
      state.notification = payload;
      state.initialSelect = "notification";
      state.selectModal = true;
    },
    updatePositionModalUserData: (
      state,
      { payload }: PayloadAction<TeamUser>
    ) => {
      state.positionModalUserData = payload;
    },
  },
});

export const {
  setInitialModal,
  closeInitialModal,
  setSelectModal,
  closeSelectModal,
  updateSelectedModalData,
  updateAndShowNotification,
  updatePositionModalUserData,
} = modalSlice.actions;

export default modalSlice.reducer;

export const selectModalState = (state: RootState) => state.modal;
