import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MapGameIslandCellsResponseSuccess,
  MapGameRowIsland,
  MapProfile,
  MapRow,
  MapRowGameValue,
} from "../../@types/map";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  useAllCellsQuery,
  useGoToCellIslandMutation,
  useIslandCellsQuery,
} from "../../store/services/teams";
import AdminColumn from "../AdminColumn";
import AdminDraggableMap from "../AdminDraggableMap";
import AdminError from "../AdminError";
import AdminRow from "../AdminRow";
import Loader from "../Loader";
import MapCell from "../MapCell";
import MapEvent from "../MapEvent";
import seaOpen from "../../assets/images/seaOpen.png";
import MapPlayers from "../MapPlayers";
import { useDispatch } from "react-redux";
import { handleSendWS, sendWS } from "../../store/slices/wsSlice";
import { selectCurrentUser } from "../../store/slices/modal/authSlice";
import { isNumber } from "lodash";

type CellPosition = {
  row: number;
  column: number;
} | null;

const IslandContext = createContext<{
  activeCell: CellPosition;
  setActiveCell: React.Dispatch<React.SetStateAction<CellPosition>>;
} | null>(null);

const Column = ({
  columnElement,
  rowIndex,
  columnIndex,
}: {
  columnElement: MapGameRowIsland;
  rowIndex: number;
  columnIndex: number;
}) => {
  const dispatch = useDispatch();
  const userProfile = useAppSelector(selectCurrentUser);
  const activeLandImageLink = useAppSelector(
    (state) => state.map.activeLandImageLink
  );

  const ref = useRef<HTMLDivElement | null>(null);
  const cellContext = useContext(IslandContext);
  const [isActiveStroke, setIsActiveStroke] = useState(false);
  const [isActiveLand, setIsActiveLand] = useState(false);
  // const [goToCellisland, { data, isLoading, error, isError }] =
  //   useGoToCellIslandMutation();

  const handleCell = async () => {
    if (!isActiveStroke) return;
    if (!userProfile?.id) return;

    handleSendWS({ action: "goToCellIsland", iCellId: columnElement.id });
    // if (isLoading) return;

    // try {
    //   const result = await goToCellisland({
    //     iGameId: Number(userProfile?.iGameId),
    //     iTeamId: Number(userProfile?.iTeamId),
    //     iCellId: Number(columnElement.id),
    //     iUserId: Number(userProfile?.iUserId),
    //   });
    // } catch (error) {
    //   console.log("go to cell island error: ", error);
    // }
  };

  // проверяем надо ли отображать активную клетку
  useEffect(() => {
    if (columnElement?.iCurrentPosition && columnElement?.profiles) {
      let profiles: MapProfile[] = [];

      if (Array.isArray(columnElement?.profiles)) {
        profiles = columnElement.profiles;
      } else {
        profiles = Object.values(columnElement?.profiles);
      }

      const haveActveLand = profiles.some(
        (profile) => profile.id === userProfile?.id
      );

      if (isActiveLand !== haveActveLand) {
        setIsActiveLand(haveActveLand);
      }
    } else {
      if (isActiveLand) {
        setIsActiveLand(false);
      }
    }
  }, [columnElement]);

  // находим местоположение текущего пользователя
  useEffect(() => {
    if (!columnElement.iCurrentPosition && !columnElement?.profiles) return;
    if (!columnElement?.profiles) return;

    let array: MapProfile[] = [];

    if (Array.isArray(columnElement?.profiles)) {
      array = columnElement.profiles;
    } else {
      array = Object.values(columnElement?.profiles);
    }

    if (!array.length) return;

    const isCurrentuser = array.some((el) => el.id === userProfile?.id);

    if (!isCurrentuser) return;

    cellContext?.setActiveCell({ row: rowIndex, column: columnIndex });
  }, [columnElement]);

  // определяем на какие клетки возможно движение
  useEffect(() => {
    console.log(cellContext?.activeCell?.row, cellContext?.activeCell?.column);

    if (
      !isNumber(cellContext?.activeCell?.row) &&
      !isNumber(cellContext?.activeCell?.column)
    )
      return;
    const activeRow = cellContext?.activeCell?.row as number;
    const activeColumn = cellContext?.activeCell?.column as number;

    // смотрим четное или нечетное поле где находится персонаж
    let coef = rowIndex % 2 ? 1 : -1;

    if (activeRow === rowIndex && activeColumn + 1 === columnIndex) {
      setIsActiveStroke(true);
      return;
    }

    if (activeRow === rowIndex && activeColumn - 1 === columnIndex) {
      setIsActiveStroke(true);
      return;
    }

    if (activeRow + 1 === rowIndex && activeColumn === columnIndex) {
      setIsActiveStroke(true);
      return;
    }

    if (activeRow + 1 === rowIndex && activeColumn + coef === columnIndex) {
      setIsActiveStroke(true);
      return;
    }

    if (activeRow - 1 === rowIndex && activeColumn === columnIndex) {
      setIsActiveStroke(true);
      return;
    }

    if (activeRow - 1 === rowIndex && activeColumn + coef === columnIndex) {
      setIsActiveStroke(true);
      return;
    }

    if (isActiveStroke) setIsActiveStroke(false);
  }, [cellContext?.activeCell?.row, cellContext?.activeCell?.column]);

  return (
    <AdminColumn ref={ref} sTitle={columnElement.sTitle}>
      <MapEvent element={columnElement} />
      <MapCell
        // isActive={activeCell?.iCellId === Number(columnElement.id)}
        image={
          isActiveLand && activeLandImageLink
            ? activeLandImageLink
            : columnElement?.sImage
        }
        onClick={handleCell}
        isActiveStroke={isActiveStroke}
      />
      <MapPlayers element={columnElement} />
    </AdminColumn>
  );
};

const Row = ({
  mapRowElements,
  rowIndex,
}: {
  mapRowElements: MapRow<MapGameRowIsland>;
  rowIndex: number;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <AdminRow ref={ref} rowIndex={rowIndex}>
      {Object.values(mapRowElements).map((columnElement, index) => {
        return (
          <Column
            key={columnElement.id}
            columnElement={columnElement}
            rowIndex={rowIndex}
            columnIndex={index}
          />
        );
      })}
    </AdminRow>
  );
};

type Props = {};

const Land = (props: Props) => {
  const dispatch = useAppDispatch();
  // const userProfile = useAppSelector((state) => state.auth.user);
  // const iIslandId = useAppSelector(
  //   (state) => state.map.activeIslandId as number
  // );
  const landData = useAppSelector((state) => state.map.land);
  const wsStatus = useAppSelector((state) => state.ws.status);

  // const { data, isFetching, error, isError, refetch } = useIslandCellsQuery({
  //   iGameId: Number(userProfile?.iGameId),
  //   iTeamId: Number(userProfile?.iTeamId),
  //   iIslandId,
  // });

  const [activeCell, setActiveCell] = useState<CellPosition>(null);

  const contextValue = useMemo(
    () => ({ activeCell, setActiveCell }),
    [activeCell]
  );

  useEffect(() => {
    // refetch();
    if (wsStatus === "ready") {
      handleSendWS({ action: "islandCells" });
    }
  }, [wsStatus]);

  const renderRow = (data: MapGameIslandCellsResponseSuccess | undefined) => {
    if (!data) return null;
    return Object.values(data.data.model.aCells).map(
      (mapRowElements, index) => {
        return (
          <Row key={index} mapRowElements={mapRowElements} rowIndex={index} />
        );
      }
    );
  };

  if (!landData) {
    return (
      <div className="admin-games-loader">
        <Loader width="20%" height="20%" />
      </div>
    );
  }

  // if (error) {
  //   return <AdminError error={error} isError={isError} />;
  // }

  return (
    <IslandContext.Provider value={contextValue}>
      <AdminDraggableMap mapType="island" key="land" position={undefined} >
        {renderRow(landData)}
        {/* {(!!data?.data.model.aCells || !!landData?.data.model.aCells )&&
          Object.values(landData.data.model.aCells || data.data.model.aCells).map((mapRowElements, index) => {
            // console.log(mapRowElements);
            // return <div className=""></div>;
            return (
              <Row
                key={index}
                mapRowElements={mapRowElements}
                rowIndex={index}
              />
            );
          })} */}
      </AdminDraggableMap>
    </IslandContext.Provider>
  );
};

export default Land;
