import React, { useEffect, useState } from "react";
import {
  GameMapAllCellsResponseSuccess,
  MapGameIslandCellsResponseSuccess,
  MapGameRowIsland,
  MapProfile,
  MapRow,
  MapRowGameValue,
} from "../../@types/map";
import { useAppSelector } from "../../store";
import {
  useAllCellsQuery,
  useHeaderStatisticQuery,
} from "../../store/services/teams";
import { selectCurrentUser } from "../../store/slices/modal/authSlice";
import Loader from "../Loader";

const LandColumn = ({ columnElement }: { columnElement: MapGameRowIsland }) => {
  const [isActiveLand, setIsActiveLand] = useState(false);

  const userProfile = useAppSelector(selectCurrentUser);
  const activeLandImageLink = useAppSelector(
    (state) => state.map.activeLandImageLink
  );

  // проверяем надо ли отображать активную клетку
  useEffect(() => {
    if (columnElement?.iCurrentPosition && columnElement?.profiles) {
      let profiles: MapProfile[] = [];

      if (Array.isArray(columnElement?.profiles)) {
        profiles = columnElement.profiles;
      } else {
        profiles = Object.values(columnElement?.profiles);
      }

      const haveActveLand = profiles.some(
        (profile) => profile.id === userProfile?.id
      );

      if (isActiveLand !== haveActveLand) {
        setIsActiveLand(haveActveLand);
      }
    } else {
      if (isActiveLand) {
        setIsActiveLand(false);
      }
    }
  }, [columnElement]);

  return (
    <>
      <img
        src={
          isActiveLand && activeLandImageLink
            ? activeLandImageLink
            : columnElement?.sImage || ""
        }
        className="minimap__image minimap__image_land"
      />
    </>
  );
};

const LandRow = ({
  mapRowElements,
  rowIndex,
}: {
  mapRowElements: MapRow<MapGameRowIsland>;
  rowIndex: number;
}) => {
  return (
    <div
      className={`minimap__row${
        rowIndex % 2 ? " minimap__row_odd minimap__row_land" : ""
      }`}
    >
      {Object.values(mapRowElements).map((columnElement, index) => {
        return (
          <LandColumn key={columnElement.id} columnElement={columnElement} />
        );
      })}
    </div>
  );
};

const LandMinimap = () => {
  const landData = useAppSelector((state) => state.map.land);

  const renderRow = (data: MapGameIslandCellsResponseSuccess | undefined) => {
    if (!data) return null;
    return Object.values(data.data.model.aCells).map(
      (mapRowElements, index) => {
        return (
          <LandRow
            key={index}
            mapRowElements={mapRowElements}
            rowIndex={index}
          />
        );
      }
    );
  };

  if (!landData) {
    return (
      <div className="admin-games-loader">
        <Loader width="20%" height="20%" />
      </div>
    );
  }

  return <div>{renderRow(landData)}</div>;
};

const SeaColumn = ({ columnElement }: { columnElement: MapRowGameValue }) => {
  return (
    <>
      <img src={columnElement.sImage || ""} className="minimap__image" />
    </>
  );
};

const SeaRow = ({
  mapRowElements,
  rowIndex,
}: {
  mapRowElements: MapRow<MapRowGameValue>;
  rowIndex: number;
}) => {
  return (
    <div className={`minimap__row${rowIndex % 2 ? " minimap__row_odd" : ""}`}>
      {Object.values(mapRowElements).map((columnElement, index) => {
        return (
          <SeaColumn key={columnElement.id} columnElement={columnElement} />
        );
      })}
    </div>
  );
};

const SeaMinimap = () => {
  const userProfile = useAppSelector(selectCurrentUser);
  const seaData = useAppSelector((state) => state.map.sea);

  const { data, isLoading, error, isError, refetch } = useAllCellsQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  });

  const renderRow = (data: GameMapAllCellsResponseSuccess | undefined) => {
    if (!data) return null;

    return Object.values(data.data.model.aCells).map(
      (mapRowElements, index) => {
        return (
          <SeaRow
            key={index}
            mapRowElements={mapRowElements}
            rowIndex={index}
          />
        );
      }
    );
  };

  return <div>{renderRow(seaData || data)}</div>;
};

type Props = {};

const Minimap = (props: Props) => {
  const mapType = useAppSelector((state) => state.map.mapType);
  const userProfile = useAppSelector((state) => state.auth.user);

  const { data, isLoading, isError, isSuccess, error } =
    useHeaderStatisticQuery({
      iGameId: Number(userProfile?.iGameId),
      iTeamId: Number(userProfile?.iTeamId),
    });

  if (data && data?.iTotalFragmentCount === data.iFragmentCount) {
    return null;
  }

  return (
    <div className="minimap">
      {mapType === "sea" ? <SeaMinimap /> : <LandMinimap />}
    </div>
  );
};

export default Minimap;
