import React, { useState } from "react";
import { TeamUser } from "../../@types/teams";
import { useAppDispatch } from "../../store";
import {
  setSelectModal,
  updatePositionModalUserData,
} from "../../store/slices/modal/modalSlice";

const ItemContentMenu = ({
  handlePositionModal,
  user,
  openedModal,
  index,
}: {
  handlePositionModal: (value: number | null) => void;
  user: TeamUser;
  openedModal: number | null;
  index: number;
}) => {
  const dispatch = useAppDispatch();

  const handlePressMenu = () => {
    // setVisibleMenu(!visibleMenu);
    // console.log(index);
    handlePositionModal(Number(user.id));
  };

  const handlePressListButton = (value: string) => {
    dispatch(setSelectModal(value));
    dispatch(updatePositionModalUserData(user));
  };

  return (
    <td
      className="user-item__menu"
      style={
        openedModal === Number(user.id)
          ? { backgroundColor: "#AA2102" }
          : { backgroundColor: "#F89854" }
      }
      onClick={() => handlePressMenu()}
    >
      <span className="user-item__menu-dot"></span>
      <span className="user-item__menu-dot"></span>
      <span className="user-item__menu-dot"></span>
      {openedModal === Number(user.id) && (
        <ul
          className={`user-item__menu-list ${
            index < 3 ? "user-item__menu-list_bottom" : "dasds"
          }`}
        >
          <li className="user-item__menu-list-item">
            <button
              className="user-item__menu-list-button"
              onClick={() => handlePressListButton("appoint")}
              // onClick={() => {
              // setSelectModal(true);
              // setInitialSelect("appoint");
              // }}
            >
              назначить
            </button>
          </li>
          <li className="user-item__menu-list-item">
            <button
              className="user-item__menu-list-button"
              onClick={() => handlePressListButton("takeOff")}
              // onClick={() => {
              // setSelectModal(true);
              // setInitialSelect("takeOff");
              // }}
            >
              снять
            </button>
          </li>
          <li className="user-item__menu-list-item">
            <button
              className="user-item__menu-list-button"
              onClick={() => handlePressListButton("dismiss")}
              // onClick={() => {
              // setSelectModal(true);
              // setInitialSelect("dismiss");
              // }}
            >
              уволить
            </button>
          </li>
        </ul>
      )}
    </td>
  );
};

export default ItemContentMenu;
