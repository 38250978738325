import React, { useEffect, useState } from "react";

import "../Modal/Modal.css";
import "./ModalCardContent.css";
import ModalCardItem from "../ModalCardItem";
import ModalCards from "../ModalCards";
import { TeamPosition } from "../../@types/teams";

type TabNavType = {
  isActive: boolean;
  handleClick(): void;
  title: string;
};


const ModalCardContent = () => {
  const [cardVisible, setCardVisible] = useState(false);
  const [cardData, setCardData] = useState<TeamPosition | null>(null);

  const handlePressCard = (data: TeamPosition) => {
    setCardVisible(true);
    setCardData(data);
  };

  if (cardVisible) {
    return <ModalCardItem data={cardData} />;
  } else {
    return (
      <ModalCards
        //  title={title}
        handlePressCard={handlePressCard}
      />
    );
  }
};

export default ModalCardContent;
