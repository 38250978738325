import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { UserProfile } from "../../../@types/profile";
import jwtDecode from "jwt-decode";
import { TokenPayload } from "../../../@types/api";

type AuthState = {
  user: UserProfile | null;
  token: string | null;
  refreshToken: string | null;
  isInitialized: boolean;
};

const initialState: AuthState = {
  user: null,
  token: null,
  refreshToken: null,
  isInitialized: false,
};

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { user, token },
      }: PayloadAction<{ user?: UserProfile; token?: string | null }>
    ) => {
      if (user) state.user = user;
      if (token) {
        state.token = token;

        const tokenPayload = jwtDecode<TokenPayload>(token);

        const sRefreshToken = tokenPayload.refresh_token;
        if (sRefreshToken) state.refreshToken = sRefreshToken;
      }

      if (!state.isInitialized) {
        state.isInitialized = true;
      }
    },
    updateAvatar: (
      state,
      { payload: { sImage } }: PayloadAction<{ sImage: string }>
    ) => {
      if (state?.user?.sImage && sImage) {
        state.user.sImage = sImage;
      }
    },
    signOut: (state) => {
      localStorage.removeItem("jwt");
      localStorage.removeItem("sRefreshToken");
      localStorage.removeItem("user");

      return { ...initialState, isInitialized: true };
    },
  },
});

export const { setCredentials, signOut, updateAvatar } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
