import React, { useEffect, useState } from "react";

// import FullFragments from '../../assets/images/full-fragments.png';
// import Сhest from '../../assets/images/chest.png';
// import CoinsOne from '../../assets/images/coins-1.png';
// import CoinsTwo from '../../assets/images/coins-2.png';
import fullmap from "../../assets/images/fullmap-min.jpeg";
import goldWithRubies from "../../assets/images/goldWithRubies.png";
import pileOfGold from "../../assets/images/pileOfGold.png";
import chestWithGold from "../../assets/images/chestWithGold.png";

import Button from "../Button";
import closeModal from "../../assets/images/svg/closeModal.svg";
import fragment1 from "../../assets/images/fragments/fragments-1.png";
import fragment2 from "../../assets/images/fragments/fragments-2.png";
import fragment3 from "../../assets/images/fragments/fragments-3.png";
import fragment4 from "../../assets/images/fragments/fragments-4.png";
import fragment5 from "../../assets/images/fragments/fragments-5.png";
import fragment6 from "../../assets/images/fragments/fragments-6.png";
import fragment7 from "../../assets/images/fragments/fragments-7.png";
import fragment8 from "../../assets/images/fragments/fragments-8.png";
import fragment9 from "../../assets/images/fragments/fragments-9.png";
import fragment10 from "../../assets/images/fragments/fragments-10.png";
import fragment11 from "../../assets/images/fragments/fragments-11.png";
import fragment12 from "../../assets/images/fragments/fragments-12.png";
import fragment13 from "../../assets/images/fragments/fragments-13.png";
import fragment14 from "../../assets/images/fragments/fragments-14.png";
import fragment15 from "../../assets/images/fragments/fragments-15.png";

import "./Congratulations.css";
import { Fragment } from "../../@types/teams";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectCurrentUser } from "../../store/slices/modal/authSlice";
import { useCollectedFragmentQuery } from "../../store/services/teams";
import {closeInitialModal, setInitialModal} from "../../store/slices/modal/modalSlice";
import closeImg from "../../assets/images/svg/closeModal.svg";


const FragmentImg = ({
  fragments,
  index,
}: {
  fragments: Fragment[] | undefined;
  index: number;
}) => {
  const [fragment, setFragment] = useState<Fragment | undefined>(undefined);

  useEffect(() => {
    if (fragments?.length) {
      const findedFragment = fragments.find((fragment) => {
        if ((fragment.iRow - 1) * 5 + fragment.iNumber === index + 1) {
          console.log(index);
          return true;
        } else {
          return false;
        }
      });

      setFragment(findedFragment);
    }
  }, [fragments]);

  if (!fragments || !fragment) return null;

  return (
    <img
      src={fragment.sImage}
      alt="Фрагмент карты"
      className={`fragments-item__img fragments-item__img_${index + 1}`}
    />
    // {!!data?.data.model.length && data.data.model.find((fragment) => {
    //   if((fragment.iRow*4 + fragment.iNumber) === index) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // })}
  );
};

const Congratulations = ({ closeModalWindow } : any) => {
  const fragments = [
    { id: "1", image: `${fragment1}`, visible: true },
    { id: "2", image: `${fragment2}`, visible: true },
    { id: "3", image: `${fragment3}`, visible: true },
    { id: "4", image: `${fragment4}`, visible: true },
    { id: "5", image: `${fragment5}`, visible: true },
    { id: "6", image: `${fragment6}`, visible: true },
    { id: "7", image: `${fragment7}`, visible: true },
    { id: "8", image: `${fragment8}`, visible: true },
    { id: "9", image: `${fragment9}`, visible: true },
    { id: "10", image: `${fragment10}`, visible: true },
    { id: "11", image: `${fragment11}`, visible: true },
    { id: "12", image: `${fragment12}`, visible: true },
    { id: "13", image: `${fragment13}`, visible: true },
    { id: "14", image: `${fragment14}`, visible: true },
    { id: "15", image: `${fragment15}`, visible: true },
    { id: "16", image: `${fragment15}`, visible: true },
  ];
  const dispatch = useAppDispatch();

  const userProfile = useAppSelector(selectCurrentUser);

  const { data, isFetching, isError, error, refetch } =
    useCollectedFragmentQuery({
      iGameId: Number(userProfile?.iGameId),
      iTeamId: Number(userProfile?.iTeamId),
    });

  const handlePressStat = () => {
    dispatch(setInitialModal("ratings"));
  };


  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="congratulations">
      <div className="congratulations__close-button">
        <Button
          text=""
          onClick={closeModalWindow}
          style={{
            width: 22,
            height: 22,
            position: "absolute",
            top: 0,
            right: 0,
            margin: 30,
            background: "transparent",
          }}
          className="close-btn"
        >
          <img src={closeImg} alt="close" />
        </Button>
      </div>
      <h3 className="congratulations__title">Поздравляем!</h3>
      <p className="congratulations__subtitle">
        Вы собрали все фрагменты карты города, в котором скрывается вечный источник дохода!
      </p>
      <div className="congratulations__images">
        <div className="congratulations__fake"></div>
        <div className="congratulations__fragments">
          <div className="fragments">
            {fragments.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className={`fragments-item fragments-item__${index + 1}`}
                  style={item.visible ? { opacity: 1 } : { opacity: 0 }}
                >
                  <img src={item.image} alt="Пустой фрагмент карты" />
                  <FragmentImg fragments={data?.data.model} index={index} />
                </div>
              );
            })}
          </div>
        </div>
        {/* <img src={fullmap} alt="Карта сокровищ" /> */}
        {/*<img
          src={goldWithRubies}
          alt="Золото с рубинами"
          className="congratulations__gold-with-rubies"
        />
        <img
          src={chestWithGold}
          alt="Ящик с золотом"
          className="congratulations__chest-with-gold"
        />
        <img
          src={pileOfGold}
          alt="Гора золота"
          className="congratulations__pile-of-gold"
        />*/}
      </div>
      {/*<Button
        text="посмотреть статистику"
        className="congratulations__button"
        onClick={handlePressStat}
      />*/}
    </div>
  );
};

export default Congratulations;
// type Props = {};

// const Fragments = (props: Props) => {
//   return (
//     <div className="fragments-full">
//       <h2 className="fragments-full__title">поздравляем!</h2>
//       <p className="fragments-full__text">Вы собрали все фрагменты карты местоположения острова с источником вечного дохода</p>
//       <div className="fragments-full__image">
//         <img src={FullFragments} alt="Карта"/>
//         <img className="chests chests-1" src={Сhest} alt="Сундук"/>
//         <img className="chests chests-2" src={CoinsOne} alt="Монеты"/>
//         <img className="chests chests-3" src={CoinsTwo} alt="Сокровища"/>
//       </div>
//       <Button
//         text="посмотреть статистику"
//         className="bottom-menu__button_big bottom-menu__button_gradient-red"
//         style={{width: 360}}
//         // onClick={() => setShowModal(true)}
//       />
//     </div>
//   )
// }

// export default Fragments;
