import React, { ForwardedRef, forwardRef, ReactNode } from "react";

type Props = {
  sTitle: string;
  children: ReactNode;
};

const AdminColumn = forwardRef(
  ({ sTitle, children }: Props, ref: ForwardedRef<HTMLDivElement | null>) => {
    return (
      <div
        ref={ref}
        style={{
          position: "relative",
          marginLeft: -30,
          pointerEvents: "none",
        }}
      >
        <div className="cell">
          <div className="cell__title">{sTitle}</div>
          {children}
        </div>
      </div>
    );
  }
);

export default AdminColumn;
