import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { closeSelectModal } from "../../store/slices/modal/modalSlice";
import Modal from "../Modal";
import ModalNotification from "../ModalNotification";
import ModalProfile from "../ModalProfile";
import ModalIndividualProfile from "../ModalProfile/ModalIndividualProfile";
import ModalSelectContent from "../ModalSelectContent";

const options = ["Квартирмейстер", "Капитан", "Штурман"];
const users = ["Лидия Ярославцева", "Иван константинов", "Сергей Устинов"];

const selectModalStyles = {
  width: 550,
  height: "auto",
  minHeight: 220,
  padding: 50,
  paddingTop: 60,
  boxSizing: "border-box",
  overflowY: "visible",
  overflowX: "visible",
};

const profileModalStyles = {
  position: "relative",
  top: 108,
  width: 1024,
};

const notificationStyles = {
  width: 640,
  height: "auto",
  minHeight: 180,

  padding: "76px 40px",
};

type Props = {};

const ModalSelect = ({}: Props) => {
  const dispatch = useAppDispatch();
  const { selectModal, initialSelect } = useAppSelector((state) => state.modal);
  const [showContent, setShowContent] = useState(false);

  const closeModal = () => {
    dispatch(closeSelectModal());
  };

  useEffect(() => {
    if (selectModal) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  }, [selectModal]);

  const renderSelectData = useCallback((type: string, data?: any) => {
    switch (type) {
      case "appoint":
        return (
          <ModalSelectContent
            title="НАЗНАЧИТЬ НА ДОЛЖНОСТЬ"
            // data={options}
            type="appoint"
          />
        );
      case "takeOff":
        return (
          <ModalSelectContent
            title="СНЯТЬ С ДОЛЖНОСТИ"
            // data={users}
            type="takeOff"
          />
        );
      case "dismiss":
        return (
          <ModalSelectContent
            title="УВОЛИТЬ ИЗ КОМАНДЫ"
            // data={users}
            type="delete"
          />
        );
      case "delete":
        return (
          <ModalSelectContent
            title="УВОЛИТЬ ИЗ КОМАНДЫ"
            // data={users}
            type="delete"
          />
        );
      case "profileIndividual":
        return <ModalIndividualProfile title="Профиль игрока" />;
      case "notification":
        return <ModalNotification />;
      default:
        return null;
    }
  }, []);

  const selectStyles = (select: string) => {
    if (select === "profileIndividual") {
      return profileModalStyles;
    } else if (select === "notification") {
      return notificationStyles;
    } else {
      return selectModalStyles;
    }
  };

  return (
    <Modal
      showModal={selectModal}
      onClose={closeModal}
      modalStyle={selectStyles(initialSelect)}
    >
      {showContent && renderSelectData(initialSelect)}
    </Modal>
  );
};

export default ModalSelect;
