import { ButtonHTMLAttributes, Children, DetailedHTMLProps } from "react";
import "./Button.css";

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  text: string;
};

const Button = ({ text, className = "", children, ...props }: Props) => {
  return (
    <button className={`${className} button`} {...props}>
      {text}
      {children}
    </button>
  );
};

export default Button;
