export enum StatusCodes {
  success = 200,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  accessDenied = 403,
  unprocessable = 422,
}

export enum UserRoles {
  admin = "admin",
  user = "user",
}

export type TokenPayload = {
  iss: string;
  aud: string;
  jti: string;
  iat: number;
  exp: number;
  uid: number;
  refresh_token: string;
};

export type ApiResponse<T, D = true> = {
  success: D;
  data: T;
};

export type ResponseError = {
  success: boolean;
  status:
    | StatusCodes.badRequest
    | StatusCodes.unauthorized
    | StatusCodes.unprocessable;
  message: string;
  errors: string;
};

export type ResponseAuthError = {
  success: boolean;
  data: {
    errors: string;
    message: string;
    status:
      | StatusCodes.badRequest
      | StatusCodes.unauthorized
      | StatusCodes.unprocessable;
  };
};

export type AccessDenisError = ApiResponse<
  { errors: string; message: string; status: number },
  false
>;

export type Response<T> = {
  status: StatusCodes.success | StatusCodes.noContent | boolean;
  message: string;
  model: T;
};

export type ResponseSuccess<T> = ApiResponse<Response<T>>;
