import React, { useEffect, useState } from "react";

import "../Modal/Modal.css";
import "./ModalFragmentsContent.css";
import Map from "../../assets/images/map.png";
import fragment1 from "../../assets/images/fragments/fragments-1.png";
import fragment2 from "../../assets/images/fragments/fragments-2.png";
import fragment3 from "../../assets/images/fragments/fragments-3.png";
import fragment4 from "../../assets/images/fragments/fragments-4.png";
import fragment5 from "../../assets/images/fragments/fragments-5.png";
import fragment6 from "../../assets/images/fragments/fragments-6.png";
import fragment7 from "../../assets/images/fragments/fragments-7.png";
import fragment8 from "../../assets/images/fragments/fragments-8.png";
import fragment9 from "../../assets/images/fragments/fragments-9.png";
import fragment10 from "../../assets/images/fragments/fragments-10.png";
import fragment11 from "../../assets/images/fragments/fragments-11.png";
import fragment12 from "../../assets/images/fragments/fragments-12.png";
import fragment13 from "../../assets/images/fragments/fragments-13.png";
import fragment14 from "../../assets/images/fragments/fragments-14.png";
import fragment15 from "../../assets/images/fragments/fragments-15.png";
import { useAppSelector } from "../../store";
import { useCollectedFragmentQuery } from "../../store/services/teams";
import Loader from "../Loader";
import Error from "../Error";
import { Fragment } from "../../@types/teams";
import { selectCurrentUser } from "../../store/slices/modal/authSlice";

const FragmentImg = ({
  fragments,
  index,
}: {
  fragments: Fragment[] | undefined;
  index: number;
}) => {
  const [fragment, setFragment] = useState<Fragment | undefined>(undefined);

  useEffect(() => {
    if (fragments?.length) {
      const findedFragment = fragments.find((fragment) => {
        if ((fragment.iRow - 1) * 5 + fragment.iNumber === index + 1) {
          console.log(index);
          return true;
        } else {
          return false;
        }
      });

      setFragment(findedFragment);
    }
  }, [fragments]);

  if (!fragments || !fragment) return null;

  return (
    <img
      src={fragment.sImage}
      alt="Фрагмент карты"
      className={`fragments-item__img fragments-item__img_${index + 1}`}
    />
    // {!!data?.data.model.length && data.data.model.find((fragment) => {
    //   if((fragment.iRow*4 + fragment.iNumber) === index) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // })}
  );
};

const ModalFragmentsContent = ({
  // data,
  title,
}: {
  // data: any;
  title: string;
}) => {
  const fragments = [
    { id: "1", image: `${fragment1}`, visible: true },
    { id: "2", image: `${fragment2}`, visible: true },
    { id: "3", image: `${fragment3}`, visible: true },
    { id: "4", image: `${fragment4}`, visible: true },
    { id: "5", image: `${fragment5}`, visible: true },
    { id: "6", image: `${fragment6}`, visible: true },
    { id: "7", image: `${fragment7}`, visible: true },
    { id: "8", image: `${fragment8}`, visible: true },
    { id: "9", image: `${fragment9}`, visible: true },
    { id: "10", image: `${fragment10}`, visible: true },
    { id: "11", image: `${fragment11}`, visible: true },
    { id: "12", image: `${fragment12}`, visible: true },
    { id: "13", image: `${fragment13}`, visible: true },
    { id: "14", image: `${fragment14}`, visible: true },
    { id: "15", image: `${fragment15}`, visible: true },
    { id: "16", image: `${fragment15}`, visible: true },
  ];

  const userProfile = useAppSelector(selectCurrentUser);
  const [count, setCount] = useState(0);

  const { data, isFetching, isError, error, refetch } =
    useCollectedFragmentQuery({
      iGameId: Number(userProfile?.iGameId),
      iTeamId: Number(userProfile?.iTeamId),
    });

  useEffect(() => {
    if (data) {
      setCount(data?.data?.model?.length || 0);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (isError && error) {
    return <Error error={error} isError={isError} />;
  }

  return (
    <>
      {/*<h3
        className="modal-title"
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          lineHeight: "50px",
        }}
      >
        {title}
        <span className="fragments-count__present">{count}</span>
        <span className="fragments-count__full">из 16</span>
      </h3>*/}
      <div className="fragments-container">
        <div className="fragments">
          {/* <img src={Map} alt="Карта" /> */}
          {fragments.map((item, index) => {
            return (
              <div
                key={item.id}
                className={`fragments-item fragments-item__${index + 1}`}
                style={item.visible ? { opacity: 1 } : { opacity: 0 }}
              >
                <img src={item.image} alt="Пустой фрагмент карты" />
                <FragmentImg fragments={data?.data.model} index={index} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ModalFragmentsContent;
