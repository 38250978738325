import avatar from "../../assets/images/avatar.jpeg";
import teamAvatar from "../../assets/images/teamAvatar.jpeg";
import Error from "../Error";

import "./ModalProfile.css";
import {useAppSelector} from "../../store";
import {useGameProfileQuery} from "../../store/services/teams";
import Loader from "../Loader";
import {ReactNode, useEffect} from "react";
import {useAttachAvatarMutation} from "../../store/services/auth";
import convertBase64 from "../../helpers/toBase64";
import {KpiParameters, UserProfile} from "../../@types/profile";
import {selectCurrentUser} from "../../store/slices/modal/authSlice";
import blueCard from "../../assets/images/modal-images/blue.png"
import greenCard from "../../assets/images/modal-images/green.png"
import orangeCard from "../../assets/images/modal-images/orange.png"
import pinkCard from "../../assets/images/modal-images/pink.png"
import redCard from "../../assets/images/modal-images/red.png"


export const ProfileAchievement = ({
                                     imgSrc,
                                     imgAlt,
                                     title,
                                     description,
                                     background,
                                     isOpened = false,
                                   }: {
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
  isOpened?: boolean;
  background?: string
}) => {

  return (
    <div className="modal-profile__achievement-container">
      <div
        className={`modal-profile__achievement ${
          isOpened ? "modal-profile__achievement_active" : ""
        }`}
      >
        <img src={background}/>
        <div className="modal-profile__achievement-card-bottom-container">
          <img
            src={imgSrc || ""}
            alt={imgAlt}
            className="modal-profile__achievement-avatar"
          />
          <div className="modal-profile__achievement-title">{title}</div>
          <div className="modal-profile__achievement-description">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProfileAchievements = ({children}: { children: ReactNode }) => {
  return <div className="modal-profile__achievements">{children}</div>;
};

export const ProfileStat = ({
                              count,
                              name,
                            }: {
  count: number;
  name: string;
}) => {
  return (
    <div className="modal-profile__stat">
      <div className="modal-profile__stat-count">{count || 0}</div>
      <div className="modal-profile__stat-name">{name || ""}</div>
    </div>
  );
};

export const ProfileStatsContainer = ({
                                        children,
                                      }: {
  children: ReactNode;
}) => {
  return <div className="modal-profile__stats">{children}</div>;
};

export const ProfileKpi = ({kpi}: { kpi?: KpiParameters }) => {
  return (
    <div className="modal-profile__kpi">
      <div className="modal-profile__kpi-title">{kpi?.name || ""}</div>

      <div className="modal-profile__kpi-row">
        <div className="modal-profile__kpi-column modal-profile__kpi-column_first">
          <div className="modal-profile__kpi-num">{kpi?.fact || 0}</div>
          <div className="modal-profile__kpi-num-subtitle">факт</div>
        </div>

        <div className="modal-profile__kpi-column">
          <div className="modal-profile__kpi-row-text">
            <div className="modal-profile__kpi-num-subtitle-right">план</div>
            <div className="modal-profile__kpi-num-right">{kpi?.plan || 0}</div>
          </div>
          <div className="modal-profile__kpi-row-text">
            <div className="modal-profile__kpi-num-subtitle-right">
              выполнение
            </div>
            <div className="modal-profile__kpi-num-right">
              {kpi?.percent || 0}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProfileKpiContainer = ({children}: { children: ReactNode }) => {
  return <div className="modal-profile__kpi-container">{children}</div>;
};

export const ProfileMotivation = ({
                                    name,
                                    sum,
                                    month,
                                  }: {
  name?: string;
  sum?: string;
  month?: string;
}) => {
  return (
    <div className="modal-profile__motivation">
      <div className="modal-profile__motivation-title">{name || ""}</div>
      <div className="modal-profile__motivation-sum">{sum || ""} р.</div>
      <div className="modal-profile__motivation-date">{month || ""}</div>
    </div>
  );
};

export const ProfileTeam = ({
                              sImage,
                              sName,
                              children,
                            }: {
  sImage?: string | null;
  sName?: string;
  children: ReactNode;
}) => {
  return (
    <div className="modal-profile__team">
      <img
        src={sImage || teamAvatar}
        alt="аватар команды"
        className="modal-profile__team-avatar"
      />
      <div className="modal-profile__team-info">
        <div className="modal-profile__team-title">Команда</div>
        <div className="modal-profile__team-name">"{sName || ""}"</div>
      </div>
      {children}
    </div>
  );
};

export const ProfileAvatar = ({
                                sImage,
                                sFirstName,
                                sLastName,
                                sPosition,
                                handleUploadAvatar,
                                isInputDisabled,
                                isLoading = false,
                              }: {
  sImage?: string | null;
  sFirstName?: string;
  sLastName?: string;
  sPosition?: string;
  handleUploadAvatar?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isInputDisabled?: boolean;
  isLoading?: boolean;
}) => {
  // if (true) {
  //   return (

  //   );
  // }

  return (
    <>
      <input
        disabled={isInputDisabled}
        className="modal-profile__input-avatar"
        type="file"
        name=""
        id="profile-avatar"
        onChange={handleUploadAvatar}
      />
      <label className="modal-profile__label" htmlFor="profile-avatar">
        <img
          src={sImage || avatar}
          alt="Аватар"
          className={`modal-profile__avatar ${
            isInputDisabled ? "modal-profile__avatar_disabled" : ""
          }`}
        />
        <div className="modal-profile__user">
          <div className="modal-profile__name">
            {`${sFirstName} ${sLastName}`}
          </div>
          <div className="modal-profile__role">{sPosition || "нет роли"}</div>
        </div>
        {isLoading && (
          <div className="modal-profile__avatar-loader">
            <Loader/>
          </div>
        )}
      </label>
      {/* <div className="modal-profile__user">
          <div className="modal-profile__name">{`${user?.sFirstName} ${user?.sLastName}`}</div>
          <div className="modal-profile__job">
            {user?.sPosition || "нет роли"}
          </div>
        </div> */}
    </>
  );
};

export const ProfileInfo = ({children}: { children: ReactNode }) => {
  return <div className="modal-profile__info">{children}</div>;
};

export const ProfileTopBlock = ({children}: { children: ReactNode }) => {
  return <div className="modal-profile__top-block">{children}</div>;
};

type Props = {
  title: string;
};

const achievementCards = [
  blueCard, greenCard, orangeCard, pinkCard, redCard
]

const ModalProfile = ({title}: Props) => {
  const user = useAppSelector(selectCurrentUser)
  const {isLoading, isError, error, refetch} = useGameProfileQuery({
    iGameId: Number(user?.iGameId),
  });
  const [uploadAvatar, uploadAvatarData] = useAttachAvatarMutation();

  const keys = user && user.achievements ? Object.keys(user.achievements) : [];

  const cards = user && user.achievements ? keys.map((key, index) => {
    return {background: achievementCards[index], achievement: user.achievements[key]}
  }) : []

  const handleUploadAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    try {
      const sFile = await convertBase64(e.target.files[0]);

      uploadAvatar({sFile});
    } catch (error) {
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return <Loader/>;
  }

  if (isError && error) {
    return <Error error={error} isError={isError}/>;
  }

  return (
    <>
      <h3 className="modal-title">{title}</h3>
      <div className="modal-profile">
        <ProfileTopBlock>
          <ProfileAvatar
            sImage={user?.sImage}
            sFirstName={user?.sFirstName}
            sLastName={user?.sLastName}
            sPosition={user?.sPosition}
            handleUploadAvatar={handleUploadAvatar}
            isLoading={uploadAvatarData?.isLoading}
          />
          <ProfileInfo>
            <ProfileTeam sImage={user?.team?.sImage} sName={user?.team?.sName}>
              <ProfileMotivation
                name={user?.sParameter4?.name}
                sum={user?.sParameter4?.sum}
                month={user?.sParameter4?.month}
              />
            </ProfileTeam>

            <ProfileKpiContainer>
              <ProfileKpi kpi={user?.sParameter1}/>
              <ProfileKpi kpi={user?.sParameter2}/>
              <ProfileKpi kpi={user?.sParameter3}/>
            </ProfileKpiContainer>

            <ProfileStatsContainer>
              <ProfileStat count={Number(user?.iStepCount) || 0} name="шагов"/>
              <ProfileStat
                count={Number(user?.iFragmentCount) || 0}
                name="фрагмента"
              />
              <ProfileStat
                count={Number(user?.iPositionCount) || 0}
                name="карт"
              />
              <ProfileStat
                count={Number(user?.iPositiveCount) || 0}
                name="баффа"
              />
            </ProfileStatsContainer>
          </ProfileInfo>
        </ProfileTopBlock>
        <ProfileAchievements>
          {!!user?.achievements &&
            cards.map((card) => {
              return (
                <ProfileAchievement
                  key={card?.achievement.id}
                  imgSrc={card?.achievement.sImage}
                  imgAlt={card?.achievement.sTitle}
                  title={card?.achievement.sTitle}
                  background={card?.background}
                  description={card?.achievement.sDescription}
                  isOpened={card?.achievement.open}
                />
              );
            })
          }
        </ProfileAchievements>
      </div>
    </>
  );
};

export default ModalProfile;
