import React, { SVGProps } from "react";
import Canvas from "./Canvas";

import "./MapCell.css";

type Props = SVGProps<SVGSVGElement> & {
  onClick(): void;
  image: string | null;
  isActive?: boolean;
  isActiveStroke?: boolean;
};

const MapCell = ({
  onClick,
  image,
  isActive,
  isActiveStroke,
  ...props
}: Props) => {
  return (
    <>
      {/* <Canvas /> */}

      <svg
        width="100%"
        height="100%"
        viewBox="0 0 210 210"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <image x="0" y="0" width="100%" height="100%" href={image || ""} />
        <polygon
          onClick={onClick}
          // stroke="#16D6FF"
          // strokeWidth={3}
          className={`map-cell__polygon ${
            isActiveStroke ? "map-cell__polygon_active" : ""
          }`}
          // style={{ pointerEvents: "auto", cursor: "pointer" }}
          fill={`${isActive ? "#7f82b2" : "transparent"}`}
          points="105 5 195 55 195 155 105 205 17 155 17 55"
        />
      </svg>
    </>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  /*
  возвращает true, если nextProps рендерит
  тот же результат что и prevProps,
  иначе возвращает false
  */
  if (prevProps.image !== nextProps.image) return false;
  if (prevProps.isActive !== nextProps.isActive) return false;
  return true;
}

// export default React.memo(MapCell, areEqual);
export default MapCell;
