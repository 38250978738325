import * as Sentry from "@sentry/react";
import { store } from "..";
import { ChatMessages, ChatProfile } from "../../@types/chat";
import {
  GameMapAllCellsResponseSuccess,
  MapEvent,
  MapEventWithHelp,
  MapGameIslandCellsResponseSuccess,
} from "../../@types/map";
import { socketUrl } from "../../api/instance";
import {
  loadChatMessages,
  loadNewMessage,
  loadOldMessages,
  updateIsUnreadMessages,
  updateProfiles,
} from "../slices/chatSlice";
import {
  addNotification,
  updateActiveLandImageLink,
  updateLandInfo,
  updateLastEvent,
  updateQuizState,
  updateSeaInfo,
} from "../slices/mapSlice";
import {
  setInitialModal,
  updateAndShowNotification,
} from "../slices/modal/modalSlice";
import { sendWS, updateWSStatus } from "../slices/wsSlice";
import { teamsApi } from "./teams";

let ws: WebSocket | null = null;

const createChannel = () => {
  cleanUp();
  ws?.close();
  ws = new WebSocket(socketUrl);
  setupListeners();
};

const openHandler = () => {
  console.log("ws opened");

  const userProfile = store.getState().auth.user;

  ws?.send(
    JSON.stringify({
      action: "userConfig",
      iGameId: userProfile?.iGameId,
      iTeamId: userProfile?.iTeamId,
      iUserId: userProfile?.iUserId,
    })
  );
};

const closeHandler = (e: CloseEvent) => {
  console.log("ws closed: ", e);
  store.dispatch(updateWSStatus("pending"));
  setTimeout(() => {
    createChannel();
  }, 2000);
};

const errorHandler = (e: Event) => {
  console.log("ws error: ", e);
  Sentry.captureException(e);
  store.dispatch(updateWSStatus("pending"));
  setTimeout(() => {
    createChannel();
  }, 2000);
};

const messageHandler = (e: MessageEvent) => {
  const response = JSON.parse(e.data);

  if (response === "Set user config success") {
    console.log("ws initialized");
    store.dispatch(updateWSStatus("ready"));
  }

  if (response?.type === "unreadMessages") {
    store.dispatch(updateIsUnreadMessages(!!response?.unreadMessages));
  }

  if (response?.type === "quizState") {
    store.dispatch(updateQuizState(!!response?.quiz));
  }

  if (response?.type === "notification") {
    if (response?.data) {
      store.dispatch(addNotification(response.data as MapEventWithHelp));
      // if (Number(response?.data?.iEventType) === 7) {
      //   store.dispatch(setInitialModal("fragments"));
      // } else {
      //   store.dispatch(setInitialModal("event"));
      //   store.dispatch(updateLastEvent(response?.data as MapEvent));
      // }
    }

    if (response?.errors) {
      store.dispatch(updateAndShowNotification(response?.errors));
    }
    return;
  }

  if (response?.type === "goToCellIsland" || response?.type === "islandCells") {
    store.dispatch(
      updateLandInfo(response.result as MapGameIslandCellsResponseSuccess)
    );

    if (response?.sImageActive) {
      store.dispatch(updateActiveLandImageLink(response?.sImageActive));
    }

    if (response?.headerStatistic) {
      const userProfile = store.getState().auth.user;

      store.dispatch(
        teamsApi.util.updateQueryData(
          "headerStatistic",
          {
            iGameId: Number(userProfile?.iGameId),
            iTeamId: Number(userProfile?.iTeamId),
          },
          () => response.headerStatistic
        )
      );
    }
    return;
  }

  if (response?.type === "globalCells" || response?.type === "goToCell") {
    store.dispatch(
      updateSeaInfo(response.result as GameMapAllCellsResponseSuccess)
    );
    store.dispatch(sendWS({ action: "quizState" }));
    if (response?.headerStatistic) {
      const userProfile = store.getState().auth.user;

      store.dispatch(
        teamsApi.util.updateQueryData(
          "headerStatistic",
          {
            iGameId: Number(userProfile?.iGameId),
            iTeamId: Number(userProfile?.iTeamId),
          },
          () => response.headerStatistic
        )
      );
    }
    return;
  }

  if (response?.type === "onlineProfiles") {
    if (!response?.profiles) return;
    store.dispatch(updateProfiles(response?.profiles as ChatProfile[]));
    return;
  }

  if (response?.type === "openChat") {
    store.dispatch(loadChatMessages(response?.messages as ChatMessages[]));
    store.dispatch(sendWS({ action: "unreadMessages" }));
    return;
  }

  if (response?.type === "sendMessage") {
    const { message, profile } = response;
    store.dispatch(loadNewMessage({ message, profile }));
    store.dispatch(sendWS({ action: "unreadMessages" }));
    return;
  }

  if (response?.type === "moreMessages") {
    store.dispatch(loadOldMessages(response?.messages));
    // store.dispatch(sendWS({ action: "unreadMessages" }));
    return;
  }
};

const cleanUp = () => {
  ws?.removeEventListener("close", closeHandler);
  ws?.removeEventListener("open", openHandler);
  ws?.removeEventListener("message", messageHandler);
  ws?.removeEventListener("error", errorHandler);
};

const setupListeners = () => {
  ws?.addEventListener("close", closeHandler);
  ws?.addEventListener("open", openHandler);
  ws?.addEventListener("message", messageHandler);
  ws?.addEventListener("error", errorHandler);
};

export const wsApi = {
  start: () => {
    createChannel();
  },
  stop: () => {
    ws?.close();
    cleanUp();
  },
  send: (data: any) => {
    ws?.send(JSON.stringify(data));
  },
};
