import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import "../Modal/Modal.css";
import "./ModalTeamContent.css";
import TeamItemContent from "../TeamItemContent";
import { useAllQuery } from "../../store/services/teams";
import Loader from "../Loader";
import { TeamUser } from "../../@types/teams";
import { useAppSelector } from "../../store";
import Error from "../Error";

type Props = {};

const ModalTeamContent = ({}: Props) => {
  const userProfile = useAppSelector((state) => state.auth.user);
  const [openedModal, setOpenedModal] = useState<null | number>(null);

  const { data, isFetching, error, isError, refetch } = useAllQuery({
    iGameId: Number(userProfile?.iGameId),
  });

  const handlePositionModal = (value: number | null) => {
    if (value === openedModal) {
      setOpenedModal(null);
    } else {
      setOpenedModal(value);
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (isError && error) {
    return <Error error={error} isError={isError} />;
  }

  return (
    <>
      <h3 className="modal-title">
        Команда корабля {data?.data?.model?.sName || ""}
      </h3>
      <div className="team">
        <table className="user-list">
          <tbody>
            {data?.data?.model?.team?.map((user: TeamUser, index) => {
              return (
                <TeamItemContent
                  key={user.id}
                  user={user}
                  handlePositionModal={handlePositionModal}
                  openedModal={openedModal}
                  index={index}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ModalTeamContent;
