import { build } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/cacheLifecycle";
import { defaultSerializeQueryArgs } from "@reduxjs/toolkit/dist/query/defaultSerializeQueryArgs";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState, store } from "..";
import { ResponseSuccess, StatusCodes } from "../../@types/api";
import {
  GameMapAllCellsResponseSuccess,
  MapGameIslandCellsResponseSuccess,
  MapGameRowIsland,
  MapRowGameValue,
} from "../../@types/map";
import {
  CellsForQuizResponse,
  CollectedFragmentsResponseSuccess,
  EventResponseSuccess,
  FragmentResponseSuccess,
  GameProfileResponseSuccess,
  GlobalStatisticResponseSuccess,
  HeaderStatisticResponseSuccess,
  InvididualRatingResponseSuccess,
  IslandStatisticResponseSuccess,
  PositionResponseSuccess,
  TeamRatingResponseSuccess,
  TeamUsersResponse,
  TeamPositionResponseSuccess,
  PositionInfoResponseSuccess,
  HeaderStatistic,
  FreePositionsResponseSuccess,
  FreePosition,
  NotificationResponseSuccess,
  NotificationType,
  TeamObject,
  TeamObjectsResponseSucess,
} from "../../@types/teams";
import { baseUrl } from "../../api/instance";
import {
  addUnreadNotifications,
  updateGlobalError,
  updateLandInfo,
  updateLastEvent,
} from "../slices/mapSlice";
import { setCredentials } from "../slices/modal/authSlice";
import { setInitialModal } from "../slices/modal/modalSlice";

export const teamsApi = createApi({
  reducerPath: "teamsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/team/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Получение данных обо всех игроках в определенной игре
    all: builder.query<TeamUsersResponse, { iGameId: number }>({
      query: (body) => ({
        url: "all",
        method: "POST",
        body,
      }),
    }),

    //добавление доп должности
    appointToPosition: builder.mutation<
      any,
      { iGameId: number; iGameProfileId: number; iPositionId: number }
    >({
      query: (body) => ({
        url: "appoint-to-position",
        method: "POST",
        body,
      }),
    }),

    // удаление доп должности
    removeFromPosition: builder.mutation<
      any,
      { iGameId: number; iGameProfileId: number }
    >({
      query: (body) => ({
        url: "remove-from-position",
        method: "POST",
        body,
      }),
    }),

    // удаление из команды
    removeFromTeam: builder.mutation<
      any,
      { iGameId: number; iGameProfileId: number }
    >({
      query: (body) => ({
        url: "dismiss-from-team",
        method: "POST",
        body,
      }),
    }),

    // получение клеток
    // карты для команды
    // (глобальная или остров)
    allCells: builder.query<
      GameMapAllCellsResponseSuccess,
      { iGameId: number; iTeamId: number; iIslandId?: number }
    >({
      query: (body) => ({
        url: "all-cells",
        method: "POST",
        body,
      }),
    }),
    // "получение
    // информации
    // для голосования"
    cellForQuiz: builder.query<
      CellsForQuizResponse,
      { iGameId: number; iTeamId: number }
    >({
      query: (body) => ({
        url: "cell-for-quiz",
        method: "POST",
        body,
      }),
    }),
    // "голосование, получение
    // информации
    // для голосования"
    quiz: builder.mutation<
      any,
      { iGameId: number; iTeamId: number; iCellId: number }
    >({
      query: (body) => ({
        url: "quiz",
        method: "POST",
        body,
      }),
      async onQueryStarted(
        { iGameId, iCellId, iTeamId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const result = await queryFulfilled;

          const patchResult = dispatch(
            teamsApi.util.updateQueryData(
              "cellForQuiz",
              {
                iGameId,
                iTeamId,
              },
              () => result.data
            )
          );
        } catch (error) {
          console.log("query error: ", error);
        }
      },
    }),
    // принятие решения
    goToCell: builder.mutation<
      GameMapAllCellsResponseSuccess,
      { iGameId: number; iTeamId: number; iCellId: number }
    >({
      query: (body) => ({
        url: "go-to-cell",
        method: "POST",
        body,
      }),
      async onQueryStarted(
        { iGameId, iTeamId, iCellId },
        { dispatch, queryFulfilled }
      ) {
        try {
          dispatch(updateGlobalError(null));
          const result = await queryFulfilled;

          let activeCell: MapRowGameValue | undefined;

          const cells = Object.values(result.data.data.model.aCells);

          // TODO: Переделать так как проходит по всему списку
          // Необходимо выходить из циклов при нахождении элемента
          cells.forEach((cell) => {
            Object.values(cell).forEach((cellElement) => {
              if (Number(cellElement.id) === iCellId) {
                activeCell = cellElement;
              }
            });
          });

          if (activeCell?.event) {
            setTimeout(() => {
              if (activeCell?.event) {
                dispatch(setInitialModal("event"));
                dispatch(updateLastEvent(activeCell?.event));
              }
            }, 1500);
          }

          const patchResult = dispatch(
            teamsApi.util.updateQueryData(
              "allCells",
              { iGameId, iTeamId },
              () => result.data
            )
          );
        } catch (error) {
          //@ts-ignore
          console.log("query error: ", error?.error?.data?.errors);
          //@ts-ignore
          if (error?.error?.data?.errors) {
            //@ts-ignore
            dispatch(updateGlobalError(error?.error?.data?.errors || null));
          }
        }
      },
    }),
    // получение собранных фрагментов
    collectedFragment: builder.query<
      CollectedFragmentsResponseSuccess,
      { iGameId: number; iTeamId: number }
    >({
      query: (body) => ({
        url: "collected-fragments",
        method: "POST",
        body,
      }),
    }),

    // получение
    // фрагмента по ид
    fragmentById: builder.query<
      FragmentResponseSuccess,
      { iFragmentId: number }
    >({
      query: (body) => ({
        url: "fragment-by-id",
        method: "POST",
        body,
      }),
    }),

    // получение
    // события по ид
    eventById: builder.query<EventResponseSuccess, { iEventId: number }>({
      query: (body) => ({
        url: "event-by-id",
        method: "POST",
        body,
      }),
    }),

    // получение
    // должности по ид
    positionById: builder.query<
      PositionResponseSuccess,
      { iPositionId: number }
    >({
      query: (body) => ({
        url: "position-by-id",
        method: "POST",
        body,
      }),
    }),

    // Получение данных профиля
    gameProfile: builder.query<GameProfileResponseSuccess, { iGameId: number }>(
      {
        query: (body) => ({
          url: "game-profile",
          method: "POST",
          body,
        }),
        async onQueryStarted({}, { dispatch, queryFulfilled }) {
          try {
            const result = await queryFulfilled;
            if (result.meta?.response?.status === StatusCodes.success) {
              const { model } = result?.data?.data;

              if (!model) return;

              localStorage.setItem("user", JSON.stringify(model));

              dispatch(
                setCredentials({
                  user: model,
                })
              );
            }
          } catch (error) {
            console.log('error', error)
          }
        },
      }
    ),

    goToIsland: builder.mutation<any, { iGameId: number; iTeamId: number }>({
      query: (body) => ({
        url: "go-to-island",
        method: "POST",
        body,
      }),
      // async onQueryStarted({}, {dispatch, queryFulfilled}) {
      //   try {
      //     const result = aw
      //   }
      // }
    }),

    leaveIsland: builder.mutation<any, { iGameId: number; iTeamId: number }>({
      query: (body) => ({
        url: "leave-island",
        method: "POST",
        body,
      }),
      // async onQueryStarted({}, {dispatch, queryFulfilled}) {
      //   try {
      //     const result = aw
      //   }
      // }
    }),

    // временный метод до вебсокетов
    // получение ячеек острова
    islandCells: builder.query<
      MapGameIslandCellsResponseSuccess,
      { iGameId: number; iTeamId: number; iIslandId: number }
    >({
      query: (body) => ({
        url: "island-cells",
        method: "POST",
        body,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(updateLandInfo(result.data));
        } catch (error) {
          console.log("error when loading land: ", error);
        }
      },
    }),

    // временный метод до вебсокетов
    // хождение по острову
    goToCellIsland: builder.mutation<
      MapGameIslandCellsResponseSuccess,
      { iGameId: number; iTeamId: number; iCellId: number; iUserId: number }
    >({
      query: (body) => ({
        url: "go-to-cell-island",
        method: "POST",
        body,
      }),
      async onQueryStarted(
        { iGameId, iTeamId, iCellId },
        { dispatch, queryFulfilled, getState }
      ) {
        try {
          // dispatch(updateGlobalError(null));
          const result = await queryFulfilled;

          let activeCell: MapGameRowIsland | undefined;

          const cells = Object.values(result?.data?.data?.model?.aCells);

          // TODO: Переделать так как проходит по всему списку
          // Необходимо выходить из циклов при нахождении элемента
          cells.forEach((cell) => {
            Object.values(cell).forEach((cellElement) => {
              if (Number(cellElement.id) === iCellId) {
                activeCell = cellElement;
              }
            });
          });

          if (activeCell?.event) {
            if (activeCell?.event) {
              dispatch(setInitialModal("event"));
              dispatch(updateLastEvent(activeCell?.event));
            }
          }

          const iIslandId = (getState() as RootState).map.activeIslandId;

          if (!iIslandId) return;

          const patchResult = dispatch(
            teamsApi.util.updateQueryData(
              "islandCells",
              { iGameId, iTeamId, iIslandId },
              () => result.data
            )
          );
        } catch (error) {
          //@ts-ignore
          console.log("query error: ", error?.error?.data?.errors);
        }
      },
    }),

    // Статистика шапки на странице с картой
    headerStatistic: builder.query<
      HeaderStatistic,
      { iGameId: number; iTeamId: number }
    >({
      query: (body) => ({
        url: "header-statistic",
        method: "POST",
        body,
      }),

      transformResponse: (
        response: HeaderStatisticResponseSuccess,
        meta,
        arg
      ) => response.data.model,
    }),

    // получение командного рейтинга
    teamRating: builder.query<
      TeamRatingResponseSuccess,
      {
        iGameId: number;
        iTeamId: number;
      }
    >({
      query: (body) => ({
        url: "team-rating",
        method: "POST",
        body,
      }),
    }),

    // получение индивидуального рейтинга
    individualRating: builder.query<
      InvididualRatingResponseSuccess,
      {
        iGameId: number;
        iTeamId: number;
      }
    >({
      query: (body) => ({
        url: "individual-rating",
        method: "POST",
        body,
      }),
    }),

    // статистика по ходам
    globalStatistic: builder.query<
      GlobalStatisticResponseSuccess,
      {
        iGameId: number;
        iTeamId: number;
      }
    >({
      query: (body) => ({
        url: "global-statistic",
        method: "POST",
        body,
      }),
    }),

    // статистика по островам
    islandStatistic: builder.query<
      IslandStatisticResponseSuccess,
      {
        iGameId: number;
        iTeamId: number;
      }
    >({
      query: (body) => ({
        url: "island-statistic",
        method: "POST",
        body,
      }),
    }),

    // получение должностей (назначенные, найденные, не найденные)
    teamPositions: builder.query<
      TeamPositionResponseSuccess,
      {
        iGameId: number;
        iTeamId: number;
      }
    >({
      query: (body) => ({
        url: "team-positions",
        method: "POST",
        body,
      }),
    }),

    // получение подробной информации
    positionInfo: builder.query<
      PositionInfoResponseSuccess,
      {
        iGameId: number;
        iTeamId: number;
        iPositionId: number;
      }
    >({
      query: (body) => ({
        url: "position-info",
        method: "POST",
        body,
      }),
    }),

    freePositions: builder.query<
      FreePosition[],
      {
        iGameId: number;
        iTeamId: number;
      }
    >({
      query: (body) => ({
        url: "free-positions",
        method: "POST",
        body,
      }),
      transformResponse: (response: FreePositionsResponseSuccess, meta, arg) =>
        response?.data?.model,
    }),

    teamChat: builder.query<
      {
        bCreatedAt: number;
        bLastMessage: number;
        iGameId: number;
        iTeamId: number;
        id: number;
      },
      {
        iGameId: number;
        iTeamId: number;
      }
    >({
      query: (body) => ({
        url: "team-chat",
        method: "POST",
        body,
      }),
      transformResponse: (
        response: ResponseSuccess<{
          bCreatedAt: number;
          bLastMessage: number;
          iGameId: number;
          iTeamId: number;
          id: number;
        }>,
        meta,
        arg
      ) => response?.data?.model,
    }),

    notifications: builder.query<
      NotificationType[],
      { iGameProfileId: number }
    >({
      query: (body) => ({
        url: "notifications",
        method: "POST",
        body,
      }),
      transformResponse: (response: NotificationResponseSuccess, meta, arg) =>
        response?.data?.model,
      async onQueryStarted({}, { dispatch, queryFulfilled, getState }) {
        try {
          const result = await queryFulfilled;

          if (result?.data?.length) {
            dispatch(addUnreadNotifications(result.data));
          }
        } catch (error) {
          console.log("query error: ", error);
        }
      },
    }),

    readNotification: builder.mutation<any, { iNotificationId: number }>({
      query: (body) => ({
        url: "read-notification",
        method: "POST",
        body,
      }),
    }),

    teamObjects: builder.query<
      TeamObject[],
      { iGameId: number; iTeamId: number }
    >({
      query: (body) => ({
        url: "team-objects",
        method: "POST",
        body,
      }),
      transformResponse: (response: TeamObjectsResponseSucess, meta, arg) =>
        response?.data?.model,
    }),
  }),
});

export const {
  useAllQuery,
  useAllCellsQuery,
  useCellForQuizQuery,
  useQuizMutation,
  useGoToCellMutation,
  useGameProfileQuery,
  useGoToIslandMutation,
  useIslandCellsQuery,
  useGoToCellIslandMutation,
  useLeaveIslandMutation,
  useHeaderStatisticQuery,
  useTeamRatingQuery,
  useIndividualRatingQuery,
  useGlobalStatisticQuery,
  useIslandStatisticQuery,
  useCollectedFragmentQuery,
  useTeamPositionsQuery,
  usePositionInfoQuery,
  useFreePositionsQuery,
  useAppointToPositionMutation,
  useRemoveFromPositionMutation,
  useRemoveFromTeamMutation,
  useTeamChatQuery,
  useNotificationsQuery,
  useReadNotificationMutation,
  useTeamObjectsQuery,
} = teamsApi;
