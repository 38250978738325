import React, { useState } from "react";

import "./Dropdown.css";
import Arrow from "../../assets/images/svg/dropdown-arrow.svg";
import {
  FreePosition,
  TeamPositionProfile,
  TeamUser,
} from "../../@types/teams";

type Props<T> = {
  data: T[];
  selectedOption: T | null;
  handleSelectedOption: (value: T) => void;
};
const Dropdown = <T extends FreePosition>({
  data,
  selectedOption,
  handleSelectedOption,
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => {
    if (!data?.length) return;
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (value: typeof data[number]) => () => {
    handleSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <div className="dropdown-container">
      <div className="dropdown-header" onClick={toggling}>
        {selectedOption?.sTitle}
        <img src={Arrow} alt="Стрелка" />
      </div>
      {isOpen && (
        <div className="dropdown-list__container">
          <ul className="dropdown-list">
            {data.map((position: typeof data[number]) => {
              return (
                <li
                  key={Math.random()}
                  className="dropdown-list__item"
                  onClick={onOptionClicked(position)}
                >
                  {position.sTitle}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export const DropdownUser = <T extends TeamPositionProfile | TeamUser>({
  data,
  selectedOption,
  handleSelectedOption,
}: {
  data: T[];
  selectedOption: T | null;
  handleSelectedOption: (value: T) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => {
    if (!data?.length) return;
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (value: T) => () => {
    handleSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <div className="dropdown-container">
      <div className="dropdown-header" onClick={toggling}>
        {selectedOption?.sFirstName} {selectedOption?.sLastName}
        <img src={Arrow} alt="Стрелка" />
      </div>
      {isOpen && (
        <div className="dropdown-list__container">
          <ul className="dropdown-list">
            {data.map((user: T) => {
              return (
                <li
                  key={Math.random()}
                  className="dropdown-list__item"
                  onClick={onOptionClicked(user)}
                >
                  {user.sFirstName} {user.sLastName}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
