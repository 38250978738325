import React, { useEffect, useRef, useState } from "react";

import "../Modal/Modal.css";
import "./ModalInterviewContent.css";
import Image from "../../assets/images/interview-img.png";
import ProgressBar from "../ProgressBar";
import {
  teamsApi,
  useCellForQuizQuery,
  useQuizMutation,
} from "../../store/services/teams";
import Loader from "../Loader";
import AdminDraggableMap from "../AdminDraggableMap";
import { MapRow, MapRowGameValue } from "../../@types/map";
import AdminRow from "../AdminRow";
import AdminColumn from "../AdminColumn";
import MapCell from "../MapCell";
import MapEvent from "../MapEvent";
import { CellForQuiz, CellsForQuizResponse } from "../../@types/teams";
import { useAppDispatch, useAppSelector } from "../../store";
import { sendWS } from "../../store/slices/wsSlice";

const Column = ({
  columnElement,
  handleChangeCoordinates,
  isActive,
  handleError,
}: {
  columnElement: MapRowGameValue;
  handleChangeCoordinates: (x?: number, y?: number) => void;
  isActive: boolean;
  handleError: (value: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector((state) => state.auth.user);
  const ref = useRef<HTMLDivElement | null>(null);
  const [voteQuiz, { isLoading, data, isError, error, isSuccess }] =
    useQuizMutation();

  const handleCell = () => {
    if (isActive) {
      voteQuiz({
        iGameId: Number(userProfile?.iGameId),
        iTeamId: Number(userProfile?.iTeamId),
        iCellId: Number(columnElement.id),
      });
    }
  };

  useEffect(() => {
    if (isSuccess || isError) {
      dispatch(sendWS({ action: "quizState" }));
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (error) {
      //@ts-ignore
      handleError(error?.data?.errors || "Произошла ошибка при сохранении");
    }
  }, [error]);

  useEffect(() => {
    if (ref?.current && columnElement.iCurrentPosition) {
      handleChangeCoordinates(ref?.current?.offsetLeft);
    }
  }, [ref]);

  return (
    <AdminColumn ref={ref} sTitle={columnElement.sTitle}>
      {/* @ts-ignore */}
      <MapEvent element={columnElement} />
      <MapCell
        image={columnElement.sImage || ""}
        onClick={handleCell}
        isActiveStroke={isActive}
      />
    </AdminColumn>
  );
};

const Row = ({
  mapRowElements,
  rowIndex,
  handleChangeCoordinates,
  quizCell,
  handleError,
}: {
  mapRowElements: MapRow<MapRowGameValue>;
  rowIndex: number;
  handleChangeCoordinates: (x?: number, y?: number) => void;
  quizCell: (string | CellForQuiz)[] | undefined;
  handleError: (value: string) => void;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      ref?.current &&
      Object.values(mapRowElements).some((el) => el.iCurrentPosition)
    ) {
      handleChangeCoordinates(undefined, ref?.current?.offsetTop);
    }
  }, [ref]);

  return (
    <AdminRow ref={ref} rowIndex={rowIndex}>
      {Object.values(mapRowElements).map((columnElement, index) => {
        return (
          <Column
            key={columnElement.id}
            columnElement={columnElement}
            handleChangeCoordinates={handleChangeCoordinates}
            isActive={
              quizCell?.some(
                (cell) =>
                  typeof cell !== "string" &&
                  cell.id === Number(columnElement.id)
              ) || false
            }
            handleError={handleError}
          />
        );
      })}
    </AdminRow>
  );
};

const ModalInterviewMap = ({
  quizCell,
}: {
  quizCell: (string | CellForQuiz)[] | undefined;
}) => {
  const userProfile = useAppSelector((state) => state.auth.user);
  const [shipCoordinates, setShipCoordinates] = useState<
    | {
        x: number;
        y: number;
      }
    | undefined
  >(undefined);
  const [error, setError] = useState("");

  const handleError = (value: string) => {
    setError(value);
  };

  const data = teamsApi.endpoints.allCells.useQueryState({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  })?.data?.data?.model?.aCells;

  const socketData = useAppSelector((state) => state.map.sea)?.data.model
    .aCells;

  const handleChangeCoordinates = (x?: number, y?: number) => {
    setShipCoordinates((shipCoordinates) => {
      return {
        x: (typeof x === "number" && -x) || shipCoordinates?.x || 0,
        y: (typeof y === "number" && -y) || shipCoordinates?.y || 0,
      };
    });
  };

  let mainData = socketData || data;

  return (
    <div className="interview-map">
      <AdminDraggableMap
        disabled
        position={
          shipCoordinates
            ? {
                x: shipCoordinates?.x + 170,
                y: shipCoordinates?.y + 200,
              }
            : undefined
        }
      >
        {!!mainData &&
          Object.values(mainData).map((mapRowElements, index) => {
            return (
              <Row
                key={index}
                //@ts-ignore
                mapRowElements={mapRowElements as MapRowGameValue}
                rowIndex={index}
                handleChangeCoordinates={handleChangeCoordinates}
                quizCell={quizCell}
                handleError={handleError}
              />
            );
          })}
      </AdminDraggableMap>
      <p className="interview-error">{error}</p>
    </div>
  );
};

const ModalInterviewContent = ({}: {}) => {
  const userProfile = useAppSelector((state) => state.auth.user);
  const [quizData, setQuizData] = useState<null | CellForQuiz[]>(null);
  const { data, isLoading, isError, error, refetch } = useCellForQuizQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data) {
      const newQuizData = Object.values(data.data.model);

      const filterQuizData = newQuizData.filter(
        (value) => typeof value !== "string"
      );

      setQuizData(filterQuizData as CellForQuiz[]);
    }
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <h3 className="modal-title">ОПРОС: следующий шаг?</h3>
      <div className="interview">
        <ModalInterviewMap
          quizCell={!!data ? Object.values(data?.data.model) : undefined}
        />
        {/* <img src={Image} alt="Image"/> */}
        <div className="interview-content">
          {/* <p className="interview-title">
            Поддержите своего капитана и укажите ему куда сделать следующий шаг
          </p> */}
          <p className="interview-text">
            Выберите на карте ту ячейку куда считаете должен переместиться ваш
            корабль.
          </p>
          <p className="interview-text">
            Капитан получит совет от всех участников команды и сам примет
            решение о следующем ходе.
          </p>
          <p className="interview-title">
            Проголосовало: {data?.data.model.iCountVotedGameProfiles || 0} из{" "}
            {data?.data.model.iCountGameProfiles || 0} человек
          </p>
          <ul className="interview-list">
            {!!quizData &&
              quizData.map((item: CellForQuiz) => {
                return (
                  <li className="interview-item" key={item.id}>
                    <span className="interview-item__position">
                      {item.sTitle}
                    </span>
                    <ProgressBar
                      progress={String(
                        Number(item.iCountAnswer) *
                          (100 / Number(data?.data.model.iCountGameProfiles))
                      )}
                    />
                    <span className="interview-item__count">
                      {item.iCountAnswer}
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ModalInterviewContent;
