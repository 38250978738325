import React from "react";

import wheel from "../../assets/images/svg/wheel.svg";

import "./Loader.css";

type Props = React.CSSProperties & {
  width?: number | string;
  height?: number | string;
  animationDuration?: string;
  isAbsolute?: boolean;
  absoluteContainerStyle?: React.CSSProperties;
};

function Loader({
  width = 30,
  height = 30,
  animationDuration = "1.5s",
  isAbsolute = false,
  absoluteContainerStyle,
  ...style
}: Props) {
  if (isAbsolute) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...absoluteContainerStyle,
        }}
      >
        <Loader
          width={width}
          height={height}
          animationDuration={animationDuration}
          {...style}
        />
      </div>
    );
  }

  return (
    <div
      className="loading-spinner"
      style={{
        width,
        height,
        animationDuration,
      }}
    >
      <img style={style} src={wheel} className="loading-spinner__wheel" />
    </div>
  );
}

export default Loader;
