import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { ResponseSuccess } from "../../@types/api";
import {
  MapAllCellsResponse,
  MapEvent,
  MapFragmet,
  MapIslandAllCellsResponse,
} from "../../@types/map";
import { baseUrl } from "../../api/instance";
import { updateActiveCellData } from "../slices/adminSlice";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/setting-game/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Получение данных обо всех играх
    all: builder.query({
      query: () => ({
        url: "all",
        method: "POST",
      }),
    }),
    // Получение данных о всех ячейках для определенной карты
    allCells: builder.query<MapAllCellsResponse, { iGameId: number }>({
      query: (body) => ({
        url: "all-cells",
        method: "POST",
        body,
      }),
    }),
    // Получение списка всех событий и фрагментов
    getEvents: builder.query<
      ResponseSuccess<{ events: MapEvent[]; fragments: MapFragmet[] }>,
      { iGameId: number }
    >({
      query: (body) => ({
        url: "get-events",
        method: "POST",
        body,
      }),
    }),
    // Изменение данных в ячейке карты
    changeCell: builder.mutation<
      MapAllCellsResponse,
      {
        type: "island" | "sea";
        data: {
          iGameId: number;
          iIslandId: number;
          iCellType: number;
          iCellId: number;
          iEffectId: number;
        };
      }
    >({
      query: ({ data }) => ({
        url: "change-cell",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(
        { data: { iGameId, iIslandId, iCellId, iCellType, iEffectId }, type },
        { dispatch, queryFulfilled }
      ) {
        try {
          const result = await queryFulfilled;

          dispatch(
            updateActiveCellData({
              iIslandId: iIslandId,
              iCellId: iCellId,
              iCellType: iCellType,
              iEventId: iEffectId,
            })
          );
          if (type === "sea") {
            const patchResult = dispatch(
              adminApi.util.updateQueryData(
                "allCells",
                { iGameId },
                () => result.data
              )
            );
            console.log("patch result: ", patchResult);
          } else if (type === "island") {
            const patchResult = dispatch(
              adminApi.util.updateQueryData(
                "getIslandCell",
                { iGameId, iIslandId },
                //@ts-ignore
                () => result.data
              )
            );
            console.log("patch result: ", patchResult);
          }
        } catch (error) {
          console.log("query error: ", error);
        }
      },
    }),

    // изменение положения острова в ячейке глобальной карты
    changeIslandCell: builder.mutation<
      MapAllCellsResponse,
      {
        iGameId: number;
        iIslandId: number;
        iCellType: number;
        iCellId: number;
      }
    >({
      query: (body) => ({
        url: "change-island-cell",
        method: "POST",
        body,
      }),
      async onQueryStarted({ iGameId, ...body }, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;

          const patchResult = dispatch(
            adminApi.util.updateQueryData(
              "allCells",
              { iGameId },
              () => result.data
            )
          );
          console.log("patch result: ", patchResult);
        } catch (error) {
          console.log("query error: ", error);
        }
      },
    }),

    // получение карты острова
    getIslandCell: builder.query<
      MapIslandAllCellsResponse,
      { iGameId: number; iIslandId: number }
    >({
      query: (body) => ({
        url: "island-cells",
        method: "POST",
        body,
      }),
    }),

    mapRandomGeneration: builder.mutation<any, { iGameId: number }>({
      query: (body) => ({
        url: "generation-random-data",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useAllQuery,
  useAllCellsQuery,
  useGetEventsQuery,
  useChangeCellMutation,
  useChangeIslandCellMutation,
  useGetIslandCellQuery,
  useMapRandomGenerationMutation,
} = adminApi;
