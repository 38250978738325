import React, { CSSProperties, useState } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import { AccessDenisError, ResponseError, StatusCodes } from "../../@types/api";

type Props = {
  error: FetchBaseQueryError | SerializedError | undefined;
  isError: boolean;
  style?: CSSProperties;
  className?: string;
  errorText?: string;
};

const Error = ({
  error,
  isError,
  style,
  className,
  errorText = "Произошла ошибка при загрузке",
}: Props) => {
  if (
    isError &&
    error &&
    "status" in error &&
    error?.status === StatusCodes.accessDenied
  ) {
    return (
      <div className={className} style={style}>
        {(error?.data as AccessDenisError)?.data?.errors || errorText}
      </div>
    );
  }

  if (isError && error && "status" in error) {
    return (
      <div style={style} className={className}>
        {(error?.data as ResponseError)?.errors || errorText}
      </div>
    );
  }

  return (
    <div style={style} className={className}>
      {errorText}
    </div>
  );
};

export default Error;
