import React, { useEffect } from "react";
import { useAppSelector } from "../../store";
import Loader from "../Loader";
import Error from "../Error";
import { StatCardItem, StatSteps, StatSubtitle, StatTitle } from ".";
import { useIslandStatisticQuery } from "../../store/services/teams";

type Props = {};

const ModalStatIsland = (props: Props) => {
  const userProfile = useAppSelector((state) => state.auth.user);
  const { data, isFetching, isError, error, refetch } = useIslandStatisticQuery(
    {
      iGameId: Number(userProfile?.iGameId),
      iTeamId: Number(userProfile?.iTeamId),
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (isError && error) {
    return <Error error={error} isError={isError} />;
  }

  return (
    <div className="statistics">
      <StatTitle title="Статистика открытым городам" />
      <div className="statistics-cards">
        <StatCardItem
          count={data?.data.model.general.iIslandCount || 0}
          title="всего городов"
        />
        <StatCardItem
          count={data?.data.model.general.iOpenIslandCount || 0}
          title="открыто городов"
        />
        <StatCardItem
          count={data?.data.model.general.iPositiveCount || 0}
          title="получено бонусов"
        />
        <StatCardItem
          count={data?.data.model.general.iFragmentCount || 0}
          title="найдено фрагментов"
        />
      </div>
      <StatSubtitle title="Открытые города" />
      <StatSteps history={data?.data.model.history} />
    </div>
  );
};

export default ModalStatIsland;
