import React, {useEffect} from "react";
import {useAppSelector} from "../../store";
import {useTeamObjectsQuery} from "../../store/services/teams";
import Loader from "../Loader";
import Error from "../Error";
import './ModalArtefacts.css';


type Props = {};

const ModalArtefacts = (props: Props) => {
  const userProfile = useAppSelector((state) => state.auth.user);

  const { data, isFetching, isError, error, refetch } = useTeamObjectsQuery({
    iGameId: Number(userProfile?.iGameId),
    iTeamId: Number(userProfile?.iTeamId),
  });

  useEffect(() => {
    refetch();
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (isError && error) {
    return <Error error={error} isError={isError} />;
  }

  return (
    <>
      {/* <h3 className="modal-title">{title}</h3> */}
      <div className="cards">
        {!!data &&
          data.map((item) => {
            return (
              <div
                key={item.id}
                className="card"
              >
                <img
                  className="card-image"
                  src={item.sImage}
                  alt={item.sTitle}
                />
                {!!item.bOpenObject ? (
                  <>
                    <p className="card-job">{item?.sTitle}</p>
                    <div className="card-name__block"></div>
                  </>
                ) : (
                  <>
                    <p className="card-job" style={{ opacity: 0.3 }}>
                      {item.sTitle}
                    </p>
                    <div className="card-name__block">
                      <p className="card-name__404">Не найдено</p>
                    </div>
                  </>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
}

export default ModalArtefacts;
