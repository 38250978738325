import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { wsApi } from "../services/WSservice";
import { store } from "..";
import { updateAndShowNotification } from "./modal/modalSlice";

type WSState = {
  status: "ready" | "pending";
};

const initialState: WSState = {
  status: "pending",
};

const slice = createSlice({
  name: "ws",
  initialState: initialState,
  reducers: {
    startWS: (state) => {
      wsApi.start();
    },
    stopWS: (state) => {
      state.status = "pending";
      wsApi.stop();
    },
    sendWS: (state, { payload }: PayloadAction<any>) => {
      wsApi.send(payload);
    },
    updateWSStatus: (state, { payload }: PayloadAction<WSState["status"]>) => {
      state.status = payload;
    },
  },
});

export const { startWS, stopWS, sendWS, updateWSStatus } = slice.actions;

export const handleSendWS = (data: any) => {
  const status = store.getState().ws.status;

  if (status === "ready") {
    wsApi.send(data);
  } else {
    console.log("sended data on error: ", data);
    store.dispatch(
      updateAndShowNotification("Проблема с подключением к серверу")
    );
  }
};

export default slice.reducer;
