import React from "react";
import { CellTypeCode } from "../../@types/map";
import { useAppSelector } from "../../store";

import "./ModalEvent.css";

type Props = {};

const ModalEvent = (props: Props) => {
  const event = useAppSelector((state) => state.map.lastEvent);

  const getEffectName = (value: Number) => {
    if (value === CellTypeCode.forceMajeure) {
      return "Негативный эффект";
    }

    if (value === CellTypeCode.bonus) {
      return "Положительный эффект";
    }

    if (value === CellTypeCode.rank) {
      return "Звания эффект";
    }
  };

  return (
    <>
      <h3 className="modal-title">{event?.sTitle || ""}</h3>
      <div className="modal-event">
        <figure className="event-figure">
          <img
            className="event-figure__image"
            src={event?.sImage || ""}
            alt={event?.sTitle || ""}
          />
          <figcaption className="event-figure__figcaption">
            {event?.sTitle || ""}
          </figcaption>
        </figure>
        <div>
          <h3 className="modal-event__subtitle">Описание</h3>
          <p className="modal-event__text">{event?.sEventDescription || ""}</p>
          <h3 className="modal-event__subtitle">
            {getEffectName(Number(event?.iEventType))}
          </h3>
          <p className="modal-event__text">{event?.sEffectDescription || ""}</p>
          {!!event?.help && (
            <>
              <h3 className="modal-event__subtitle">Противодействие эффекту</h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 4,
                }}
              >
                <p className="modal-event__text">
                  У вас есть: {event?.help?.sTitle || ""}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalEvent;
