import React, { useState } from "react";

import "./TeamItemContent.css";
import ItemContentMenu from "../ItemContentMenu";
import { TeamUser } from "../../@types/teams";
import stringToColour from "../../helpers/stringToColour";

type Props = {
  user: TeamUser;
  handlePositionModal: (value: number | null) => void;
  openedModal: number | null;
  index: number;
  // setSelectModal: any,
  // setInitialSelect: any
};

const TeamItemContent = ({
  user,
  handlePositionModal,
  openedModal,
  index,
}: // setSelectModal,
// setInitialSelect
Props) => {
  // const handlePressAppoint = () => {
  //   setSelectModal(true);
  // }

  return (
    <tr className="user-item">
      <td className="user-item__block">
        <span
          className="user-item__name-icon"
          style={{
            backgroundColor: stringToColour(
              `${user.sFirstName} ${user.sLastName}`
            ),
          }}
        >
          {user.sLastName[0]}
        </span>
        <div>
          <p className="user-item__name">
            {user.sFirstName} {user.sLastName}
          </p>
          <p className="user-item__job">{user?.sPosition || "нет звания"}</p>
        </div>
      </td>
      <td className="user-item__param">
        <span className="user-item__param-count">{user?.iStepCount || 0}</span>
        <p className="user-item__param-title">шагов</p>
      </td>
      <td className="user-item__param">
        <span className="user-item__param-count">
          {user?.iFragmentCount || 0}
        </span>
        <p className="user-item__param-title">фрагменты</p>
      </td>
      <td className="user-item__param">
        <span className="user-item__param-count">
          {user?.iPositionCount || 0}
        </span>
        <p className="user-item__param-title">карты</p>
      </td>
      <td className="user-item__param">
        <span className="user-item__param-count">
          {user?.iPositiveCount || 0}
        </span>
        <p className="user-item__param-title">баффы</p>
      </td>
      <ItemContentMenu
        user={user}
        handlePositionModal={handlePositionModal}
        openedModal={openedModal}
        index={index}
        // setSelectModal={setSelectModal}
        // setInitialSelect={setInitialSelect}
      />
    </tr>
  );
};

export default TeamItemContent;
