import React, { useEffect, useState } from "react";
import { KpiParameters } from "../../@types/profile";
import { IndividualRating } from "../../@types/teams";
import stringToColour from "../../helpers/stringToColour";
import { useAppDispatch } from "../../store";
import {
  setSelectModal,
  updateSelectedModalData,
} from "../../store/slices/modal/modalSlice";

const CountItem = ({ title, count }: { title: string; count: number }) => {
  return (
    <td className="user-item__param user-item__param_small">
      <span className="user-item__param-count user-item__param-count_small">
        {count}
      </span>
      <p className="user-item__param-title user-item__param-title_small">
        {title}
      </p>
    </td>
  );
};

const RatingStat = ({ kpi }: { kpi?: (KpiParameters | undefined)[] }) => {
  const [kpiSum, setKpiSum] = useState({
    fact: 0,
    plan: 0,
    percent: 0,
    count: 0,
  });

  useEffect(() => {
    if (kpi?.length) {
      const kpiSum = kpi.reduce(
        (prevKpi, currKpi) => {
          if (currKpi) {
            return {
              fact: prevKpi.fact + Number(currKpi.fact),
              plan: prevKpi.plan + Number(currKpi.plan),
              percent:
                (prevKpi.percent + Number(currKpi.percent)) /
                (prevKpi.count + 1),
              count: prevKpi.count + 1,
            };
          }
          return prevKpi;
        },
        { fact: 0, plan: 0, percent: 0, count: 0 }
      );
      setKpiSum(kpiSum);
    }
  }, []);

  return (
    <td className="user-profile__kpi">
      {/* <div className="user-profile__kpi-title"></div> */}

      <div className="user-profile__kpi-row">
        <div className="user-profile__kpi-column user-profile__kpi-column_first">
          <div className="user-profile__kpi-num">{kpiSum?.fact || 0}</div>
          <div className="user-profile__kpi-num-subtitle">факт</div>
        </div>

        <div className="user-profile__kpi-column user-profile__kpi-column_second">
          <div className="user-profile__kpi-row-text">
            <div className="user-profile__kpi-num-subtitle-right">план</div>
            <div className="user-profile__kpi-num-right">
              {kpiSum?.plan || 0}
            </div>
          </div>
          <div className="user-profile__kpi-row-text">
            <div className="user-profile__kpi-num-subtitle-right">
              выполнение
            </div>
            <div className="user-profile__kpi-num-right">
              {kpiSum?.percent.toFixed(1) || 0}%
            </div>
          </div>
        </div>
      </div>
    </td>
  );
};

const RatingUserContent = ({  data }: { data: IndividualRating }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(updateSelectedModalData(data));
    dispatch(setSelectModal("profileIndividual"));
  };

  return (
    <tr className="user-item pointer" onClick={handleClick}>
      <td className="user-item__block">
        <span
          className="user-item__name-icon"
          style={{
            backgroundColor: `${stringToColour(
              `${data?.sFirstName} ${data?.sLastName}`
            )}`,
          }}
        >
          {data.sLastName[0]}
        </span>
        <div>
          <p className="user-item__name">
            {data?.sFirstName} {data?.sLastName}
          </p>
          <p className="user-item__job user-item__job_small">
            <span className="user-item__job-position">
              {data?.sPosition || ""}
            </span>{" "}
            | {data?.team?.sName || ""}
          </p>
        </div>
      </td>
      {/* <td className="user-item__param-crew">
        <p className="user-item__crew">{data.crew}</p>
      </td> */}
      <RatingStat
        kpi={[data?.sParameter1, data?.sParameter2, data?.sParameter3]}
      />
      {/* <div> */}
      <CountItem title="шагов" count={data.iStepCount} />
      <CountItem title="фрагменты" count={data.iFragmentCount} />
      <CountItem title="карты" count={data.iPositionCount} />
      <CountItem title="баффы" count={data.iPositiveCount} />
      {/* </div> */}
    </tr>
  );
};

export default RatingUserContent;
