import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";

import modalReducer from "./slices/modal/modalSlice";
import authReducer, { signOut } from "./slices/modal/authSlice";
import mapReducer from "./slices/mapSlice";
import adminReducer from "./slices/adminSlice";
import WSReducer from "./slices/wsSlice";
import chatReducer from "./slices/chatSlice";

import { authApi } from "./services/auth";
import { adminApi } from "./services/admin";
import { teamsApi } from "./services/teams";

// Объяединяем все редюсеры в одно хранилище(стор)
const combinedReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [teamsApi.reducerPath]: teamsApi.reducer,
  auth: authReducer,
  modal: modalReducer,
  map: mapReducer,
  admin: adminReducer,
  ws: WSReducer,
  chat: chatReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

export const resetStoreActionName = "auth/signOut";

// Функция для сбрасывания всего стора(при логауте, итд)
const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === signOut.type) {
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(adminApi.middleware)
      .concat(teamsApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
