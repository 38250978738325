import "./Stat.css";
import allDireactionArrow from "../../assets/images/svg/allDirectionArrow.svg";
import people from "../../assets/images/svg/people.svg";
import cities from "../../assets/images/svg/cities.svg";
import puzzle from "../../assets/images/svg/puzzle.svg";
import artefacts from "../../assets/images/svg/artefacts.svg";
import { useAppDispatch, useAppSelector } from "../../store";
import { setInitialModal } from "../../store/slices/modal/modalSlice";
import { useHeaderStatisticQuery } from "../../store/services/teams";
import { useEffect } from "react";

type StatItemProps = {
  iconLink: string;
  stat: string;
  statName: string;
  modalName: string;
};

const StatItem = ({ iconLink, stat, statName, modalName }: StatItemProps) => {
  const dispatch = useAppDispatch();

  return (
    <li className="stat-item">
      <img src={iconLink} className="stat-item__icon" />
      <button
        className="stat-item__button"
        onClick={() => dispatch(setInitialModal(modalName))}
      >
        {statName} {stat}
      </button>
    </li>
  );
};

type Props = {};

const Stat = ({}: Props) => {
  const userProfile = useAppSelector((state) => state.auth.user);

  const { data, isLoading, isError, isSuccess, error, refetch } =
    useHeaderStatisticQuery({
      iGameId: Number(userProfile?.iGameId),
      iTeamId: Number(userProfile?.iTeamId),
    });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <ul className="stat-container">
      <StatItem
        iconLink={allDireactionArrow}
        modalName="moves"
        statName="Ходы"
        stat={`: ${data?.iTotalStepsCount || 0} / ${data?.iStepCount || 0}`}
      />
      <StatItem
        iconLink={people}
        modalName="positions"
        statName="Должности"
        stat={`: ${data?.iTotalPositionCount || 0} / ${
          data?.iPositionCount || 0
        }`}
      />
      <StatItem
        iconLink={cities}
        modalName="islands"
        statName="Города"
        stat={`: ${data?.iTotalIslandCount || 0} / ${data?.iIslandCount || 0}`}
      />
      <StatItem
        iconLink={puzzle}
        modalName="fragments"
        statName="Фрагменты"
        stat={`: ${data?.iTotalFragmentCount || 0} / ${
          data?.iFragmentCount || 0
        }`}
      />
      <StatItem
        iconLink={artefacts}
        modalName="artefacts"
        statName="Артефакты"
        stat={``}
      />
    </ul>
  );
};

export default Stat;
