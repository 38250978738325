import React, { ForwardedRef, forwardRef, ReactNode } from "react";

interface Props {
  rowIndex: number;
  children: ReactNode;
}

const AdminRow = forwardRef(
  ({ rowIndex, children }: Props, ref: ForwardedRef<HTMLDivElement | null>) => {
    return (
      <div ref={ref} className={`map-row ${rowIndex % 2 ? "map-row_odd" : ""}`}>
        {children}
      </div>
    );
  }
);

export default AdminRow;
